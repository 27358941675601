import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'
import { Email, EmailTemplate } from '../models/email.model'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { GtrEmailTrigger } from 'gtr-types'

@Service()
export default class EmailService {
  public async deleteEmail (event_uuid: any, filename: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/media/${filename}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getEmails (event_uuid: string, environment: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/email`,
      requireToken: true,
      config: {
        headers: {
          environment
        }
      }
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getScheduledEmailParticipants (event_uuid: string, scheduled_email_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scheduled-email/${scheduled_email_uuid}/participants`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getScheduledEmails (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scheduled-emails?filter[status]=Scheduled&non_uuid_criteria=true`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSentScheduledEmails (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scheduled-emails?filter[status]=Finished&non_uuid_criteria=true`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getEmailByUuid (event_uuid: string, email_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/uuid/${email_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createEmail (emailTemplates: EmailTemplate[]) {
    // Prepare the templates and move the default reg type template to the end of the array so it won't override the others.
    const payload: Email[] = []
    const defaultTemplates: Email[] = []
    for (const emailTemplate of emailTemplates) {
      const thisEmail: Email = {
        email_data: {
          company_uuid: emailTemplate.email_data.company_uuid,
          name: emailTemplate.email_data.name.replace(/ /g, '_'),
          subject: emailTemplate.email_data.subject,
          category: emailTemplate.email_data.category,
          cc: emailTemplate.email_data.cc,
          bcc: emailTemplate.email_data.bcc,
          body: emailTemplate.email_data.body,
          to_field: emailTemplate.email_data.to_field,
          wrapper: emailTemplate.email_data.wrapper
        }
      }
      thisEmail.email_criteria = emailTemplate.email_criteria ?? { type: '*' }
      if (thisEmail.email_criteria.type === '*') {
        defaultTemplates.push(thisEmail)
      } else {
        payload.push(thisEmail)
      }
    }
    payload.push(...defaultTemplates)
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${emailTemplates[0].event_uuid}/content/email/${payload[0].email_data.name}`,
      requireToken: true,
      payload: payload
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateEmail (emailTemplates: EmailTemplate[]) {
    // Prepare the templates and move the default reg type template to the end of the array so it won't override the others.
    const payload: Email[] = []
    const defaultTemplates: Email[] = []
    for (const emailTemplate of emailTemplates) {
      const thisEmail: Email = {
        email_data: {
          company_uuid: emailTemplate.email_data.company_uuid,
          name: emailTemplate.email_data.name.replace(/ /g, '_'),
          subject: emailTemplate.email_data.subject,
          category: emailTemplate.email_data.category,
          cc: emailTemplate.email_data.cc,
          bcc: emailTemplate.email_data.bcc,
          body: emailTemplate.email_data.body,
          to_field: emailTemplate.email_data.to_field,
          wrapper: emailTemplate.email_data.wrapper
        }
      }
      if (emailTemplate.email_data.name === 'invoice') {
        thisEmail.email_data.attachInvoice = true
      }
      thisEmail.email_criteria = emailTemplate.email_criteria ?? { type: '*' }
      if (thisEmail.email_criteria.type === '*') {
        defaultTemplates.push(thisEmail)
      } else {
        payload.push(thisEmail)
      }
    }
    payload.push(...defaultTemplates)
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${emailTemplates[0].event_uuid}/content/email/${payload[0].email_data.name}`,
      requireToken: true,
      payload: payload
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createScheduledEmail (event_uuid: string, data: any) {
    try {
      const httpParams: IHttpClientPostParameters = {
        url: `/event/${event_uuid}/scheduled-email`,
        requireToken: true,
        payload: data
      }
      const response: any = await Container.get(HttpClient).post(httpParams)
      return response
    } catch (err) {
      await Container.get(ErrorHandlerService).error('There was an error scheduling an email.')
    }
  }

  public async updateScheduledEmail (event_uuid: string, schedule_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/scheduled-email/${schedule_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteScheduledEmail (event_uuid: string, schedule_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/scheduled-email/${schedule_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async sendEmailToOneRegistration (event_uuid: string, company_uuid: string, registration_uuid: string, email_name: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${registration_uuid}/email/${email_name}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async sendEmailToAdminUser (event_uuid: string, email_name: string, user_uuids: string[]) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/email/${email_name}`,
      requireToken: true,
      payload: { user_uuids }
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async sendBulkEmail (event_uuid: string, participant_uuids: string[], email_name: string) {
    const group_items: any = []
    for (let i = 0; i < participant_uuids.length; i++) {
      group_items.push({
        type: 'field_criteria',
        field: 'uuid',
        operator: '=',
        value: participant_uuids[i]
      })
    }
    const data: any = {
      criteria: {
        type: 'grouping',
        group_operator: 'OR',
        group_items
      },
      user_type: 'Participant',
      email_name
    }
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/scheduled-email`,
      requireToken: true,
      payload: data
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async emailAllLeads (event_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/leads/email`,
      requireToken: true,
      payload: []
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async emailPreviewRegistration (event_uuid: string, registration_uuid: string, email_name: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${registration_uuid}/email/${email_name}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getChangelog (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type !== null ? sub_type : ''}/all?no_data=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getUUID (event_uuid: any, type: any, sub_type: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getEmailTriggers (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/email-triggers`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async checkIfVerifiedSender (email: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/verified-sender?email=${email}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async submitVerifiedSender (from_email: string, from_name: string) {
    const httpParams: IHttpClientPostParameters = {
      url: '/verified-sender',
      requireToken: true,
      payload: { from_email, from_name }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async verifySender (url: string) {
    const httpParams: IHttpClientPostParameters = {
      url: '/verify-sender',
      requireToken: true,
      payload: { url }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateEmailTrigger (event_uuid: string, trigger_uuid: string, payload: GtrEmailTrigger) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/email-triggers/${trigger_uuid}`,
      requireToken: true,
      payload: payload
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }
}
