import Container, { Service } from 'typedi'
import IHttpClientGetParameters from '../../contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '../../contracts/http-client/http-client-post-parameters.interface'
import IHttpClientPatchParameters from '../../contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientDeleteParameters from '../../contracts/http-client/http-client-delete-parameters.interface'
import HttpClient from '../http-client.service'

@Service()
export default class ScansService {
  public async getScansByFileNameAndDeviceId (file_name: string, device_id: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/microscanner/scan-data?file_name=${file_name}&deviceid=${device_id}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteScanByUUID (event_uuid: string, row_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/scan/${row_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getScansByFileNameAndDeviceIdForEvent (file_name: string, device_id: string, event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/scans/${file_name}/${device_id}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getOriginalScansForEvent (file_name: string, device_id: string, event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/scans/${file_name}/${device_id}/original`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async processScansByFileNameAndDeviceIdForEvent (file_name: string, device_id: string, event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/scans/${file_name}/${device_id}/process?response_type=json`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async changeEvent (file_name: string, device_id: string, event_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/microscanner/change-event?file_name=${file_name}&deviceid=${device_id}`,
      requireToken: true,
      payload: { event_uuid }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getAllEventScanData (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scans`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getScanByUUID (file_name: string, device_id: string, event_uuid: string, row_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scan/${row_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveScanByUUID (file_name: string, device_id: string, event_uuid: string, row_uuid: string, barcode_num: string, line: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/scan/${row_uuid}`,
      requireToken: true,
      payload: { barcode_num, line }
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async saveScan (file_name: string, device_id: string, event_uuid: string, line: string, barcode_num: string, date_time: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/scans/${file_name}/${device_id}/scan`,
      requireToken: true,
      payload: { barcode_num, line, date_time }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }
}
