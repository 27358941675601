import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import OptionService from '../services/option.service'

@Module({
  namespaced: true
})
export default class OptionStore extends VuexModule {
    option_groups: any[] = []
    option_group: any[] = []
    option_group_names: any[] = []

    get registrationTypes () {
      const registrationTypeGroupOptions = (this as any)?.option_groups.filter(group => group.name === 'Registration Types').pop()?.options || null
      if (registrationTypeGroupOptions) {
        const registrationTypes = registrationTypeGroupOptions.map(option => ({ name: option.name, uuid: option.uuid }))
        return [{ name: 'Default', uuid: '_default' }, ...registrationTypes]
      }
      return []
    }

    @Mutation
    SET_OPTION_GROUPS (payload: any[]) {
      this.option_groups = payload
    }

    @Mutation
    SET_OPTION_GROUP (payload: any[]) {
      this.option_group = payload
    }

    @Mutation
    SET_OPTION_GROUP_NAMES (payload: any[]) {
      this.option_group_names = payload
    }

    @Action({ rawError: true })
    async getOptionsGroup (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(OptionService).getOptionGroup(event_uuid)
      this.context.commit('SET_OPTION_GROUPS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getOptionsGroupNames (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(OptionService).getOptionGroupNames(event_uuid)
      this.context.commit('SET_OPTION_GROUP_NAMES', response.data)
      return response
    }

    @Action({ rawError: true })
    async getOptionGroupSingle (payload: any) {
      const { event_uuid, option_group_uuid } = payload
      const response = await Container.get(OptionService).getOptionGroupSingle(event_uuid, option_group_uuid)
      this.context.commit('SET_OPTION_GROUP', response.data)
      return response
    }

    @Action({ rawError: true })
    async exportOptionGroup (payload: any) {
      const { event_uuid, option_group_uuid } = payload
      return await Container.get(OptionService).exportOptionGroup(event_uuid, option_group_uuid)
    }

    @Action({ rawError: true })
    async exportOption (payload: any) {
      const { event_uuid, option_group_uuid, option_uuid } = payload
      return await Container.get(OptionService).exportOption(event_uuid, option_group_uuid, option_uuid)
    }

    @Action({ rawError: true })
    async getOptionGroupByName (payload: any) {
      const { event_uuid, option_group_name } = payload
      const response = await Container.get(OptionService).getOptionGroupByName(event_uuid, option_group_name)
      return response
    }

    @Action({ rawError: true })
    async createOptionGroup (payload: any) {
      const { event_uuid, data } = payload
      const response = await Container.get(OptionService).createOptionGroup(event_uuid, data)
      this.context.dispatch('getOptionsGroup', payload)
      return response
    }

    @Action({ rawError: true })
    async duplicateOptionGroup (payload: any) {
      const { event_uuid, option_group_uuid, data } = payload
      const response = await Container.get(OptionService).duplicateOptionGroup(event_uuid, option_group_uuid, data)
      this.context.dispatch('getOptionsGroup', payload)
      return response
    }

    @Action({ rawError: true })
    async saveOptionGroupSettings (payload: any) {
      const { event_uuid, option_group_uuid, data } = payload
      const response = await Container.get(OptionService).saveOptionGroupSettings(event_uuid, option_group_uuid, data)
      this.context.dispatch('getOptionsGroup', payload)
      return response
    }

    @Action({ rawError: true })
    async deleteOptionGroup (payload: any) {
      const { event_uuid, option_group_uuid } = payload
      const response = await Container.get(OptionService).deleteOptionGroup(event_uuid, option_group_uuid)
      this.context.dispatch('getOptionsGroup', payload)
      return response
    }

    @Action({ rawError: true })
    async createOption (payload: any) {
      const { event_uuid, option_group_uuid, data } = payload
      const response = await Container.get(OptionService).createOption(event_uuid, option_group_uuid, data)
      return response
    }

    @Action({ rawError: true })
    async editOption (payload: any) {
      const { event_uuid, option_group_uuid, option_uuid, data } = payload
      const response = await Container.get(OptionService).editOption(event_uuid, option_group_uuid, option_uuid, data)
      return response
    }

    @Action({ rawError: true })
    async deleteOption (payload: any) {
      const { event_uuid, option_group_uuid, option_uuid } = payload
      const response = await Container.get(OptionService).deleteOption(event_uuid, option_group_uuid, option_uuid)
      return response
    }
}
