import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrChangeLog'
})
export default class GtrChangeLog extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    @Prop({ required: true, type: String })
    version: string| undefined

    @Prop({ required: true, type: String })
    changeLog: string|undefined

    data () {
      return {
        model: false
      }
    }

    @Watch('visible')
    onVisibilityChange (payload: boolean) {
      this.$data.model = payload
    }

    get latestChangelogWithLineBreaks () {
      return this.$props.changeLog.replace(/(?:\r\n|\r|\n)/g, '<br>')
    }
}
