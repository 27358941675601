import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrColorPicker'
})
export default class GtrColorPicker extends Vue {
  @Prop({ required: false, type: String })
  label: string|undefined

  @Prop()
  value: any

  @Prop({ required: false, type: Boolean, default: false })
  closeOnClick: boolean | undefined

  @Prop({ required: false, type: Boolean, default: true })
  flat: boolean|undefined

  @Prop({ required: false, type: Boolean, default: false })
  disabled: boolean|undefined

  @Prop({ required: false, type: Boolean, default: true })
  hideSwitch: boolean|undefined

  data () {
    return {
      menu: false,
      intermediateColorValue: '#FF0000'
    }
  }

  @Watch('intermediateColorValue')
  onIntermediateChange (payload: any) {
    if (payload) {
      this.$data.intermediateColorValue = payload
      this.localValue = payload
    }
  }

  get localValue () {
    return this.value
  }

  set localValue (localValue) {
    this.updateColorPickerTextBox(localValue)
    if (localValue?.hex) {
      this.$emit('input', localValue.hex)
    } else { this.$emit('input', localValue) }
  }

  get swatchStyle () {
    const { menu } = this.$data
    return {
      backgroundColor: this.$data.intermediateColorValue,
      cursor: 'pointer',
      height: '22px',
      width: '22px',
      borderRadius: menu ? '50%' : '4px',
      transition: 'border-radius 200ms ease-in-out',
      marginBottom: '10px',
      border: '1px solid #ddd'
    }
  }

  updateColorPicker () {
    this.localValue = this.$data.intermediateColorValue
  }

  updateColorPickerTextBox (localValue) {
    if (localValue?.hex) {
      this.$data.intermediateColorValue = localValue.hex
    } else { this.$data.intermediateColorValue = localValue }
  }
}
