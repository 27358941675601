import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrChangeLog from '@/modules/common/components/ui-core/gtr-change-log/gtr-change-log.vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import GtrModuleMenuItem from '@/modules/common/components/ui-core/gtr-module-menu-item/gtr-module-menu-item.vue'
import { Container } from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrModuleLayout',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('event', ['event']),
    ...mapState('common', ['latestChangelog', 'version', 'noticeDisplayed']),
    ...mapState('module', ['eventOverview', 'gtrModules', 'activatedEventModules', 'support']),
    ...mapState('navigation', { miniVariant: (state: any) => state.moduleLayoutMiniVariant }),
    ...mapGetters('media', ['defaultFonts', 'fontList'])
  },
  components: {
    'gtr-change-log': GtrChangeLog,
    'gtr-module-menu-item': GtrModuleMenuItem
  }
})
export default class GtrModuleLayout extends GtrSuper {
  activatedEventModules!: Record<string, any>;
  eventOverview!: any;
  gtrModules!: any;
  support!: any;
  noticeDisplayed!: boolean;
  defaultFonts!: Array<Record<string, any>>;
  fontList!: Array<Record<string, any>>;

  get menuItems () {
    return [
      this.eventOverview,
      ...this.gtrModules,
      this.support
    ]
  }

  data () {
    return {
      changeLogModal: false,
      changeLogVersion: null,
      changeLog: null,
      showVersion: false
    }
  }

  created () {
    // this.$bus.$on('update:menu', this.onUpdateMenu)
  }

  async mounted () {
    if (!this.$store.state.security.currentUser) {
      await this.$store.dispatch('security/getCurrentUser')
    }
    await this.loadEventAndCompanyInfo()
    await this.fetchEventModules()
    await this.fetchFontList()
  }

  async activated () {
    await this.fetchEventModules()
  }

  @Watch('latestChangelog', { immediate: true })
  onLatestChangelogChange (payload: any) {
    if (payload) {
      this.$data.changeLog = payload
    }
  }

  @Watch('version', { immediate: true })
  onVersionChange (payload: any) {
    if (payload) {
      this.$data.changeLogVersion = payload
    }
  }

  setMiniVariant (value: boolean) {
    this.$store.dispatch('navigation/setModuleLayoutMiniVariant', value)
  }

  navigateTo (route: any) {
    this.$router.push(route)
  }

  private async loadEventAndCompanyInfo () {
    this.$store.dispatch('common/showLoader', { value: true })
    try {
      await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
      this.$bus.$emit('gtr.event.content_all')
    } catch (error) {
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  private onUpdateMenu (module: any) {
    this.$data.module = module
    let event_uuid: any = null
    if (this.$store.state.event.event) {
      if (this.$store.state.event.event.uuid !== this.$route.params.event_uuid) {
        event_uuid = this.$store.state.event.event.uuid
      } else {
        event_uuid = this.$route.params.event_uuid
      }
    } else {
      event_uuid = this.$route.params.event_uuid
    }
    this.$data.menuItems = this.makeSideBarMenuModule(module, this.$route.params.uuid, event_uuid)
  }

  @Watch('$route', { immediate: true })
  async onRouteChange () {
    if (this.$route.params.uuid && this.$route.params.event_uuid) {
      if (this.$data.module) {
        this.$data.menuItems = this.makeSideBarMenuModule(this.$data.module, this.$route.params.uuid, this.$route.params.event_uuid)
      }
      if (this.$store.state.event.eventAllContent) {
        if (this.$store.state.event.eventAllContent.event.uuid !== this.$route.params.event_uuid) {
          await this.loadEventAndCompanyInfo()
          this.$bus.$emit('gtr-fetch-general-survey')
        }
      }
    }
  }

  private async fetchEventModules () {
    try {
      const { event_uuid } = this.$route.params
      await this.$store.dispatch('module/fetchEventModules', event_uuid)
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  async fetchFontList () {
    const { uuid: companyUuid } = this.$route.params
    await this.$store.dispatch('media/getFontStores', companyUuid)
  }
}
