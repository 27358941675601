import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'

@Service()
export default class PromoteService {
  public async getReferralCodes (event_uuid: string, type: string): Promise<AxiosResponse<any>> {
    const typeFilter = `?filter[type]=${type}`
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/referral-codes${typeFilter}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async addReferralCode (data: any, event_uuid: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/referral-code`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateReferralCode (data: any, event_uuid: string, referral_code_uuid: string): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/referral-code/${referral_code_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteReferralCode (event_uuid: string, referral_code_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/referral-code/${referral_code_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async archiveReferralCode ({
    event_uuid,
    referral_code_uuid,
    active
  }: {
    event_uuid: string;
    referral_code_uuid: string;
    active: number;
  }) {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/referral-code/${referral_code_uuid}`,
      requireToken: true,
      payload: {
        active
      }
    }

    const response: AxiosResponse<any> = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async exportReferralCode ({
    event_uuid,
    referral_code_uuid
  }: {
    event_uuid: string;
    referral_code_uuid: string;
  }) {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/referral-codes/export?uuid=${referral_code_uuid}`,
      requireToken: true
    }

    const response: AxiosResponse<any> = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
