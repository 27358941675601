import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrInstructionModal'
})
export default class GtrInstructionModal extends Vue {
  @Prop({ required: true, type: Array })
  instructions!: Array<Record<string, any>>;

  @Prop({ required: true, type: Boolean, default: false })
  value: boolean | undefined;

  data () {
    return {
      show: false,
      instructionIndex: 0
    }
  }

  get instructionList () {
    return this.instructions
  }

  get currentInstruction () {
    return this.instructionList[this.$data.instructionIndex]
  }

  get totalPages () {
    return this.instructionList?.length ?? 0
  }

  get hasPrevious () {
    return this.$data.instructionIndex > 0
  }

  get hasNext () {
    return this.$data.instructionIndex < this.totalPages - 1
  }

  previous () {
    if (!this.hasPrevious) return
    this.$data.instructionIndex--
  }

  next () {
    if (!this.hasNext) return
    this.$data.instructionIndex++
  }

  emitClose () {
    this.$emit('close')
  }
}
