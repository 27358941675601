import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Layout } from '@/modules/common/enums/layouts.enum'
import GtrChangePasswordForm from '../../../forms/change-password/change-password.form.vue'
import Vue from 'vue'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrGlobalSolutionLayout',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('common', ['noticeDisplayed'])
  },
  components: {
    'gtr-change-password-form': GtrChangePasswordForm
  }
})
export default class GtrGlobalSolutionLayout extends GtrSuper {
  noticeDisplayed!: boolean;

  data () {
    return {
      items: [],
      showChangePasswordForm: false,
      miniVariant: false
    }
  }

  async mounted () {
    try {
      if (!this.$store.state.security.currentUser) {
        await this.$store.dispatch('security/getCurrentUser')
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('$route', { immediate: true })
  async onRouterChange (payload: any) {
    if (payload.params.uuid) {
      this.$data.items = this.makeSideBarMenu(payload.params.uuid, Layout.GLOBAL_SOLUTION)
      this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      this.$store.dispatch('common/hideLoader')
    }
  }

  get oneEventHasBadgesActive () {
    return this.events?.[0]?.events?.some(event => event.modules.some(mod => mod.module === 'BADGES'))
  }

  showMenuItem (menuItem: any) {
    switch (menuItem.text) {
      case 'Company Blacklists':
        return Vue.prototype.$hasPermission('company.blacklist.view')
      case 'A2Z Badges App':
      case 'Fonts':
        return this.oneEventHasBadgesActive
      default:
        return menuItem.show
    }
  }

  navigateTo (route: any) {
    this.$router.push(route)
  }

  onShowChangePassword () {
    this.$data.showChangePasswordForm = true
  }

  onCloseChangePassword () {
    this.$data.showChangePasswordForm = false
  }
}
