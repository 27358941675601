import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PricingService from '../services/pricing.service'

@Module({
  namespaced: true
})
export default class PricingStore extends VuexModule {
    pricing_tiers: any[] = []
    lr_pricing_tiers: any[] = []

    @Mutation
    SET_PRICING_TIERS (payload: any) {
      this.pricing_tiers = payload
    }

    @Mutation
    SET_LR_PRICING_TIERS (payload: any) {
      this.lr_pricing_tiers = payload
    }

    @Action({ rawError: true })
    async getPricingTiers (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(PricingService).getPricingTiers(event_uuid)
      this.context.commit('SET_PRICING_TIERS', response.data)
      return response.data
    }

    @Action({ rawError: true })
    async getLrPricingTiers (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(PricingService).getLrPricingTiers(event_uuid)
      this.context.commit('SET_LR_PRICING_TIERS', response.data)
      return response.data
    }

    @Action({ rawError: true })
    async createPricingTier (payload: any) {
      const { data, event_uuid } = payload
      const response = await Container.get(PricingService).addPricingTier(data, event_uuid)
      this.context.dispatch('getPricingTiers', payload)
      return response.data
    }

    @Action({ rawError: true })
    async editPricingTier (payload: any) {
      const { data, event_uuid, tier_uuid } = payload
      const response = await Container.get(PricingService).updatePricingTier(data, event_uuid, tier_uuid)
      this.context.dispatch('getPricingTiers', payload)
      return response.data
    }

    @Action({ rawError: true })
    async editPricingTierBulk (payload: any) {
      const { data, event_uuid, type = {} } = payload
      const response = await Container.get(PricingService).updatePricingTierBulk(data, event_uuid, type)
      this.context.dispatch('getPricingTiers', payload)
      return response.data
    }

    @Action({ rawError: true })
    async deletePricingTier (payload: any) {
      const { event_uuid, tier_uuid } = payload
      const response: any = await Container.get(PricingService).deletePricingTier(event_uuid, tier_uuid)
      this.context.dispatch('getPricingTiers', payload)
      return response
    }
}
