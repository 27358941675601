import type { Route } from 'vue-router'
import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import SecurityContext from '@/modules/common/services/security-context.service'

type MenuItem = {
  name: string;
  icon: string;
  url: string;
};

type GtrModule = {
  identifier: string;
  name: string;
  submodules: MenuItem[];
  icon: string | null;
  active: boolean;
  activeImg: string;
  inactiveImg: string | null;
  route?: Route;
}

@Component({
  name: 'GtrModuleMenuItem',
  components: {
    'gtr-submodule-list': () => import('@/modules/common/components/ui-core/gtr-submodule-list/gtr-submodule-list.vue'),
    'gtr-module-activator': () => import('@/modules/common/components/ui-core/gtr-module-activator/gtr-module-activator.vue')
  },
  computed: {
    ...mapState('module', ['activatedEventModules'])
  }
})
export default class GtrModuleMenuItem extends Vue {
  activatedEventModules!: Record<string, any>;

  loading = false;

  @Prop({ required: true })
  gtrModule!: GtrModule;

  @Prop({ required: true })
  miniVariant!: boolean

  get identifier () {
    return this.gtrModule.identifier
  }

  get name () {
    return this.gtrModule.name
  }

  get icon () {
    return this.gtrModule.icon
  }

  get img () {
    return this.isActive ? this.gtrModule.activeImg : this.gtrModule.inactiveImg
  }

  get submodules () {
    return this.gtrModule.submodules
  }

  get isActive () {
    const { identifier } = this.gtrModule
    if (identifier === 'OVERVIEW' || identifier === 'SUPPORT') return true
    return (this.activatedEventModules && this.activatedEventModules[identifier] && this.activatedEventModules[identifier].enabled) || false
  }

  get hasPermission (): boolean {
    const routePermissions = this.gtrModule?.route?.meta?.permissions // string or array of strings
    const userGroupPermissions: any [] = Container.get(SecurityContext).permissions()
    let hasPermission = true

    if (routePermissions && routePermissions.length) {
      if (Array.isArray(routePermissions)) {
        hasPermission = routePermissions.some(permission => userGroupPermissions?.[permission]) // true if user has any of the required permissions
      } else if (typeof routePermissions === 'string') {
        hasPermission = userGroupPermissions?.[routePermissions] || false
      }
    }

    return hasPermission
  }

  get isActivatable (): boolean {
    // Until we move sessions to the event level, Evals can only be activated when Track is active.
    if (this.gtrModule.identifier === 'SURVEYS') {
      return this.activatedEventModules?.SESSION_TRACKING?.enabled || false
    }
    return true
  }

  get inModule () {
    let moduleName: string
    switch (this.identifier) {
      case 'OVERVIEW':
        moduleName = 'event'
        break
      case 'SUPPORT':
        moduleName = 'modules.support'
        break
      case 'EXHIBITORS':
        moduleName = 'modules.exhibits'
        break
      case 'CALL_FOR_PAPERS':
        moduleName = 'modules.call_4_papers'
        break
      case 'REGISTRATION':
        moduleName = 'modules.registration'
        break
      case 'SURVEYS':
        moduleName = 'modules.evals'
        break
      case 'SESSION_TRACKING':
        moduleName = 'modules.track'
        break
      case 'LEADS':
        moduleName = 'modules.leads'
        break
      case 'BADGES':
        moduleName = 'modules.badges'
        break
      case 'HOTELS':
        moduleName = 'modules.hotels'
        break
      default:
        moduleName = 'event'
        break
    }
    return this.$route.name?.includes(`level-two.${moduleName}`)
  }

  private async activateModule (event: MouseEvent): Promise<void> {
    try {
      const { event_uuid } = this.$route.params
      event.preventDefault()
      event.stopPropagation()
      this.$store.dispatch('common/showLoader', { value: true })
      this.loading = true
      await this.$store.dispatch('module/activateModule', {
        event_uuid,
        body: {
          module: this.identifier
        }
      })
      Container.get(Notification).success(`${this.name} module successfully activated.`)
    } catch (error) {
      Container.get(Notification).error(`There was an error activating: ${this.gtrModule?.identifier}`)
    } finally {
      this.loading = false
      this.$store.dispatch('common/hideLoader')
    }
  }
}
