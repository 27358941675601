import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container from 'typedi'

export default class SettingService {
  public async getSettings (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/settings/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsLR (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/lr_settings/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsEmail (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/email_settings/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsEvals (event_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/evals_settings/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsTrack (event_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/track_settings/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getProviderFields (event_uuid: string, provider: string, table: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/external-sync/provider/${provider}/fields?table=${table}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsFields () {
    const httpParams: IHttpClientGetParameters = {
      url: '/settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsFieldsLR () {
    const httpParams: IHttpClientGetParameters = {
      url: '/lr_settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsFieldsEmail () {
    const httpParams: IHttpClientGetParameters = {
      url: '/email_settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsFieldsEvals () {
    const httpParams: IHttpClientGetParameters = {
      url: '/evals_settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsFieldsTrack () {
    const httpParams: IHttpClientGetParameters = {
      url: '/track_settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveSettings (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async saveSettingsLR (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/lr_settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async saveSettingsEmail (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/email_settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async saveSettingsEvals (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/evals_settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async saveSettingsTrack (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/track_settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getSettingsByUuid (event_uuid: string, settings_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/uuid/${settings_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
