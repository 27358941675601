enum Operators {
  '=',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  '^=',
  '=null',
  '!=null'
}

enum GroupOperators {
  'AND',
  'OR'
}

type ParentGroupType = {
  group_operator: GroupOperators;
  group_items: SingleShowIfType[];
}

type SingleShowIfType = {
  type: 'field_criteria' | 'option_criteria';
  field: string;
  operator: Operators;
  value: any;
}

export default interface IGtrShowIf extends SingleShowIfType {
  visible: boolean;
  global_type: 'single' | 'multiple' | '*';
  group_operator: GroupOperators;
  group_items: ParentGroupType[];
}
