export enum PlatformReportsChartDataTypesEnum {
  GlobalAdminUsersCreated = 'global_admin_users_created',
  GlobalCompaniesCreated = 'global_companies_created',
  GlobalEventsCreated = 'global_events_created',
  GlobalLrActivations = 'global_lr_activations',
  GlobalSessions = 'global_sessions',
  GlobalLrScans = 'global_lr_scans',
  GlobalTrackScans = 'global_track_scans',
  GlobalBadgesPrinted = 'global_badges_printed',
  GlobalCompletedRegistrations = 'global_completed_registrations',
  GlobalTransactionsRegTotal = 'global_transactions_reg_total',
  GlobalTransactionsLrTotal = 'global_transactions_lr_total',
  GlobalTransactionsTotal = 'global_transactions_total',
  CompanyTransactionTotal = 'company_transaction_total',
  CompanyEventsTotal = 'company_events_total',
  CompanyParticipantsTotal = 'company_participants_total'
}
