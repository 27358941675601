import Vue from 'vue'
import Vuex from 'vuex'
import Container from 'typedi'
import CommonModule from '@/modules/common/index.module'
import LevelTwoModule from '@/modules/level-two/index.module'
import LevelOneModule from '@/modules/level-one/index.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...Container.get(CommonModule).store(),
    ...Container.get(LevelOneModule).store(),
    ...Container.get(LevelTwoModule).store()
  }
})
