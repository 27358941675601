import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'

@Component({
  name: 'GtrChangePasswordForm'
})
export default class GtrChangePasswordForm extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  visible: boolean | undefined;

  @Ref()
  readonly changePasswordForm!: InstanceType<typeof ValidationObserver>

  data () {
    return {
      value: false,
      submitting: false,
      changePassword: {
        email: null,
        password: null,
        password_confirmation: null
      }
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleChange (payload: any) {
    this.$data.value = payload
  }

  mounted () {
    const securityContext = JSON.parse(Container.get(GtrStorage).getItem('security_context'))
    if (securityContext) {
      this.$data.changePassword.email = securityContext.user.email
    }
  }

  async submit () {
    const form = (this.$refs.changePassword as HTMLFormElement)
    if (form.validate()) {
      try {
        // TODO
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  handleClose () {
    this.$data.changePassword.password = null
    this.$data.changePassword.password_confirmation = null
    this.changePasswordForm.reset()
    this.$emit('close')
  }
}
