import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'

@Service()
export default class FormBuilderService {
  public async getEventFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/fields?filtered=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getEmailerFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/emailer/fields?filtered=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getReportableEventFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/reports/fields?filtered=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getImportEventFields (event_uuid: string, table: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/imports/fields/${table}?filtered=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getForm (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/form/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getDefaultForm (event_uuid: any): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/form/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveForm (event_uuid: any, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/form/default`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async compareFormVersionsDuringPolling (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
