import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import { mapState } from 'vuex'

declare let pendo: any // Adjust the type accordingly based on the actual properties and methods of 'pendo'

@Component({
  name: 'GtrPendo',
  computed: {
    ...mapState('security', ['currentUser'])
  }
})
export default class GtrPendo extends Vue {
  data () {
    return {
      value: false,
      plan: null,
      _currentUser: null
    }
  }

  @Watch('currentUser')
  onCurrentUserChange (payload: any) {
    if (payload) {
      this.$data._currentUser = payload
    }
  }

  async mounted () {
    try {
      await this.$store.dispatch('security/getCurrentUser')
      this.initializePendo()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  initializePendo () {
    pendo.initialize({
      visitor: {
        id: this.$data._currentUser.uuid
      }
    })
    const user = {
      ID: this.$data._currentUser.uuid,
      email: this.$data._currentUser.email,
      full_name: this.$data._currentUser.name,
      accessLevel: 'admin',
      creationDate: this.$data._currentUser.created_at
    }

    const account = {
      ID: this.$data._currentUser.company?.uuid ?? 'NO_COMPANY_UUID',
      name: this.$data._currentUser.company?.name ?? 'NO_COMPANY_NAME',
      is_paying: true,
      monthly_value: 99.99,
      subscriptionCost: 'premium'
    }

    // Identify user and account
    pendo.identify({
      visitor: {
        id: user.ID,
        email: user.email,
        full_name: user.full_name,
        role: user.accessLevel,
        creationDate: user.creationDate
      },
      account: {
        id: account.ID,
        name: account.name,
        is_paying: account.is_paying,
        monthly_value: account.monthly_value,
        planLevel: account.subscriptionCost
      }
    })
  }
}
