import Vue from 'vue'
import * as uuid from 'uuid'
import translateError from './translate-error'
import hasRoles from './check-role'
import hasPermission from './check-permission'
import haveLowerAccessLevel from './access-level'
Vue.prototype.$uuid = uuid
Vue.prototype.translateError = translateError
Vue.prototype.$hasRoles = hasRoles
Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$haveLowerAccessLevel = haveLowerAccessLevel
