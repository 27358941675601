import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import EventService from '../../common/services/event/event.service'

@Module({
  namespaced: true
})
export default class EventStore extends VuexModule {
  mcProFields: any = []
  mcTradeFields: any = []
  events: Array<Record<string, any>> = []
  event: Record<string, any> | undefined = undefined
  event_stats: Record<string, any> = {}
  stats_overview: Record<string, any> = {}
  eventAllContent: any = null
  eventModules: any = null
  changelog: any = {}
  eventDupe: any = []
  supportChecks: any = {}
  supportCheck: any = {}
  supportCheckResults: any = {}
  currentlyDeployedLiveUUID: any = {}
  currentDevUUID: any = {}
  latestDevUUID: any = {}
  webhookSent: any = {}
  webhook: any = {}
  webhooks: any = {}
  deletionRequests: any = {}
  deletedEvents: any = []
  users = []
  eventActivityLog: any = {}
  participant_fields: any = []
  session_fields: any = []
  session_attendee_fields: any = []
  registration_stats: Record<string, any> = {}
  badges_stats: Record<string, any> = {}
  leads_stats: Record<string, any> = {}
  track_stats: Record<string, any> = {}
  surveys_stats: Record<string, any> = {}
  eventBookmarks: any = []
  registration_type_counts: any = []

  get getEvents () {
    return this.events
  }

  get getEventModules () {
    return this.eventModules
  }

  get eventTimezone () {
    if (this.event) {
      const date = new Date()
      const options = { timeZone: this.event.timezone, timeZoneName: 'short' }
      const shortTimzeZone = new Intl.DateTimeFormat('en-US', options).formatToParts(date).find((part: any) => part.type === 'timeZoneName')
      if (shortTimzeZone) {
        return `${this.event.timezone} (${shortTimzeZone.value})`
      }
      return `${this.event.timezone}`
    }
  }

  get moduleStats () {
    return {
      REGISTRATION: this.registration_stats,
      SESSION_TRACKING: this.track_stats,
      BADGES: this.badges_stats,
      LEADS: this.leads_stats,
      SURVEYS: this.surveys_stats
    }
  }

  get eventTips () {
    return this.event_stats.tips
  }

  @Mutation
  SET_EVENTS (payload: any) {
    this.events = payload
  }

  @Mutation
  SET_EVENT (payload: any) {
    this.event = payload
  }

  @Mutation
  SET_EVENT_ALL_CONTENT (payload: any) {
    this.eventAllContent = payload
  }

  @Mutation
  SET_EVENT_MODULES (payload: any) {
    this.eventModules = payload
  }

  @Mutation
  SET_STATS_SUMMARY (stats: any) {
    this.stats_overview = stats
    this.registration_stats = {
      complete: stats.counts.REGISTRATION_COMPLETED,
      incomplete: stats.counts.REGISTRATION_INCOMPLETE,
      'gross revenue': stats.counts.REGISTRATION_GROSS_REVENUE
    }
    this.badges_stats = {
      printed: stats.counts.BADGES_PRINTED,
      'to be printed': stats.counts.BADGES_NOT_PRINTED
    }
    this.leads_stats = {
      'total payments': stats.counts.LR_GROSS_REVENUE,
      'total scans': stats.counts.LR_SCANS
    }
    this.track_stats = {
      scans: stats.counts.TRACK_SCANS_TOTAL,
      sessions: stats.counts.TRACK_SESSIONS
    }
    this.surveys_stats = {
      'surveys filled': stats.counts.SURVEYS_FILLED,
      'certificates available for export': stats.counts.SURVEYS_CERTIFICATE_EXPORTS
    }
  }

  @Mutation
  SET_EVENT_STATS (payload: any) {
    this.event_stats = payload
  }

  @Mutation
  SET_CHANGELOG (payload: any) {
    this.changelog = payload
  }

  @Mutation
  SET_EVENT_DUPE (payload: any) {
    this.eventDupe = payload
  }

  @Mutation
  SET_SUPPORT_CHECKS (payload: any) {
    this.supportChecks = payload
  }

  @Mutation
  SET_SUPPORT_CHECK (payload: any) {
    this.supportCheck = payload
  }

  @Mutation
  SET_SUPPORT_CHECK_RESULTS (payload: any) {
    this.supportCheckResults = payload
  }

  @Mutation
  SET_CURRENT_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlyDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_DEV_UUID (payload: any) {
    this.currentDevUUID = payload
  }

  @Mutation
  SET_LATEST_DEV_UUID (payload: any) {
    this.latestDevUUID = payload
  }

  @Mutation
  SET_WEBHOOK_SENT (payload: any) {
    this.webhookSent = payload
  }

  @Mutation
  SET_WEBHOOK (payload: any) {
    this.webhook = payload
  }

  @Mutation
  SET_WEBHOOKS (payload: any) {
    this.webhooks = payload
  }

  @Mutation
  SET_DELETION_REQUESTS (payload: any) {
    this.deletionRequests = payload
  }

  @Mutation
  SET_DELETED_EVENTS (payload: any) {
    this.deletedEvents = payload
  }

  @Mutation
  SET_USERS (payload: any) {
    this.users = payload
  }

  @Mutation
  SET_EVENT_ACTIVITY_LOG (payload: any) {
    this.eventActivityLog = payload
  }

  @Mutation
  SET_PARTICIPANT_FIELDS (payload: any) {
    this.participant_fields = payload
  }

  @Mutation
  SET_SESSION_FIELDS (payload: any) {
    this.session_fields = payload
  }

  @Mutation
  SET_SESSION_ATTENDEE_FIELDS (payload: any) {
    this.session_attendee_fields = payload
  }

  @Mutation
  SET_EVENT_BOOKMARKS (payload: any) {
    this.eventBookmarks = payload
  }

  @Mutation
  SET_REGISTRATION_TYPE_COUNTS (payload: any) {
    this.registration_type_counts = payload
  }

  @Mutation
  SET_MCPRO_FIELDS (payload: any) {
    this.mcProFields = payload
  }

  @Mutation
  SET_MCTRADE_FIELDS (payload: any) {
    this.mcTradeFields = payload
  }

  @Action
  setEvent (payload: Event) {
    this.context.commit('SET_EVENT', payload)
  }

  @Action({ rawError: true })
  async getParticipantFields (event_uuid: string) {
    const response = await Container.get(EventService).getParticipantFields(event_uuid)
    this.context.commit('SET_PARTICIPANT_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getMcProFields (event_uuid: string) {
    const response = await Container.get(EventService).getMcProFields(event_uuid)
    this.context.commit('SET_MCPRO_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getMcTradeFields (event_uuid: string) {
    const response = await Container.get(EventService).getMcTradeFields(event_uuid)
    this.context.commit('SET_MCTRADE_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSessionFields (payload: string) {
    const event_uuid = payload
    const response = await Container.get(EventService).getSessionFields(event_uuid)
    this.context.commit('SET_SESSION_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSessionAttendeeFields (payload: string) {
    const event_uuid = payload
    const response = await Container.get(EventService).getSessionAttendeeFields(event_uuid)
    this.context.commit('SET_SESSION_ATTENDEE_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async createEvent (payload: any) {
    const response = await Container.get(EventService).createEvent(payload)
    return response.data
  }

  @Action({ rawError: true })
  async getEventDupe (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).loadEvent(event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async loadTimezone (payload: any) {
    const response = await Container.get(EventService).getTimezone(payload.latitude, payload.longitude)
    return response
  }

  @Action({ rawError: true })
  async fetchEvent (payload: any) {
    if (payload) {
      const response = await Container.get(EventService).getEventOnly(payload)
      this.context.commit('SET_EVENT', response.data)
      return response.data.event
    }
  }

  @Action({ rawError: true })
  async fetchEventModules (payload: any) {
    const response = await Container.get(EventService).getEventModules(payload)
    this.context.commit('SET_EVENT_MODULES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async activateModule (payload: any) {
    const { event_uuid, body } = payload
    const response = await Container.get(EventService).activateModule(event_uuid, body)
    return response.data
  }

  @Action({ rawError: true })
  async deactivateModule (payload: any) {
    const { event_uuid, body } = payload
    const response = await Container.get(EventService).deactivateModule(event_uuid, body)
    return response.data
  }

  @Action({ rawError: true })
  async getEventAllContent (payload: any) {
    const response = await Container.get(EventService).getEvent(payload)
    this.context.commit('SET_EVENT_ALL_CONTENT', response.data)
  }

  @Action({ rawError: true, commit: 'SET_STATS_SUMMARY' })
  async fetchStatsSummary (event_uuid: any): Promise<void> {
    const response = await Container.get(EventService).fetchStatsSummary(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getEventStats (payload: any) {
    const response = await Container.get(EventService).getEventStats(payload)
    this.context.commit('SET_EVENT_STATS', response.data)
    return response
  }

  @Action({ rawError: true })
  async removeEvent (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).removeEvent(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async changeEventStatus (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).changeEventStatus(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getChangelog (payload: any) {
    const { event_uuid, type, subtype } = payload
    const response = await Container.get(EventService).getChangelog(event_uuid, type, subtype)
    this.context.commit('SET_CHANGELOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadSupportChecks (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).getSupportChecks(event_uuid)
    this.context.commit('SET_SUPPORT_CHECKS', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadSupportCheck (payload: any) {
    const { event_uuid, check_uuid } = payload
    const response = await Container.get(EventService).getSupportCheck(event_uuid, check_uuid)
    this.context.commit('SET_SUPPORT_CHECKS', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadSupportCheckResults (payload: any) {
    const { event_uuid, check_uuid } = payload
    const response = await Container.get(EventService).getSupportCheckResults(event_uuid, check_uuid)
    this.context.commit('SET_SUPPORT_CHECK_RESULTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async createSupportCheck (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).createSupportCheck(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async updateSupportCheck (payload: any) {
    const { event_uuid, check_uuid, data } = payload
    const response = await Container.get(EventService).updateSupportCheck(event_uuid, check_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getCurrentlyDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(EventService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(EventService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getLatestDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(EventService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_LATEST_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async deployLive (payload: any) {
    const { event_uuid, type, sub_type, data } = payload
    const response = await Container.get(EventService).deployLive(event_uuid, type, sub_type, data)
    const _payload = {
      event_uuid,
      type,
      sub_type: sub_type || 'default'
    }
    this.context.dispatch('getCurrentlyDeployedLiveUUID', _payload)
    this.context.dispatch('getCurrentDevUUID', _payload)
    return response
  }

  @Action({ rawError: true })
  async loadWebhookSent (payload: any) {
    const { event_uuid, webhook_uuid } = payload
    const response = await Container.get(EventService).getWebhookSent(event_uuid, webhook_uuid)
    this.context.commit('SET_WEBHOOK_SENT', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadWebhook (payload: any) {
    const { event_uuid, webhook_uuid } = payload
    const response = await Container.get(EventService).getWebhook(event_uuid, webhook_uuid)
    this.context.commit('SET_WEBHOOK', response.data)
    return response
  }

  @Action({ rawError: true })
  async getWebhooks (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).getWebhooks(event_uuid)
    this.context.commit('SET_WEBHOOKS', response.data)
    return response
  }

  @Action({ rawError: true })
  async createWebhook (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).createWebhook(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async deleteWebhook (payload: any) {
    const { event_uuid, webhook_uuid, data } = payload
    const response = await Container.get(EventService).deleteWebhook(event_uuid, webhook_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async resendWebhook (payload: any) {
    const { event_uuid, webhook_uuid, webhook_sent_uuid } = payload
    const response = await Container.get(EventService).resendWebhook(event_uuid, webhook_uuid, webhook_sent_uuid)
    this.context.dispatch('loadWebhookSent', payload)
    return response
  }

  @Action({ rawError: true })
  async updateWebhook (payload: any) {
    const { event_uuid, webhook_uuid, data } = payload
    const response = await Container.get(EventService).updateWebhook(event_uuid, webhook_uuid, data)
    this.context.dispatch('loadWebhook', payload)
    return response
  }

  @Action({ rawError: true })
  async eventIdExists (event_identifier: string) {
    return await Container.get(EventService).eventIdExists(event_identifier)
  }

  @Action({ rawError: true })
  async dupeEvent (payload) {
    const { event_uuid, event } = payload
    const response = await Container.get(EventService).dupeEvent(event_uuid, event)
    return response
  }

  @Action({ rawError: true })
  async updateDeletionRequest (payload: any) {
    const { deletion_request_uuid, data } = payload
    const response = await Container.get(EventService).updateDeletionRequest(deletion_request_uuid, data)
    this.context.dispatch('loadDeletionRequests')
    return response
  }

  @Action({ rawError: true })
  async undeleteEvent (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).undeleteEvent(event_uuid)
    this.context.dispatch('loadEvents')
    this.context.dispatch('loadDeletedEvents')
    return response
  }

  @Action({ rawError: true })
  async loadDeletionRequests () {
    const response = await Container.get(EventService).getDeletionRequests()
    this.context.commit('SET_DELETION_REQUESTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadEvents () {
    let events = []
    const response = await Container.get(EventService).getEvents()
    if (response.data._debugbar) {
      delete response.data._debugbar
    }
    events = Object.values(response.data)

    this.context.commit('SET_EVENTS', events)
    return response
  }

  @Action({ rawError: true })
  async loadEventsForCompany (payload: any) {
    const { company_uuid } = payload
    let events = []
    const response = await Container.get(EventService).getEventsForCompany(company_uuid)
    if (response.data._debugbar) {
      delete response.data._debugbar
    }
    events = Object.values(response.data)

    this.context.commit('SET_EVENTS', events)
    return response
  }

  @Action({ rawError: true })
  async loadDeletedEvents () {
    const response = await Container.get(EventService).getDeletedEvents()
    this.context.commit('SET_DELETED_EVENTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async loadUsers () {
    const response = await Container.get(EventService).loadUsers()
    this.context.commit('SET_USERS', response.data)
    return response
  }

  @Action({ rawError: true })
  async editEvent (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).updateEvent(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async deleteUser (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).deleteUser(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getEventActivityLog (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).getEventActivityLog(event_uuid)
    this.context.commit('SET_EVENT_ACTIVITY_LOG', response.data.data)
    return response
  }

  @Action({ rawError: true })
  async addScanToParticipant (payload: any) {
    const { event_uuid, participant_uuid, session_uuid, data } = payload
    const response = await Container.get(EventService).addScanToParticipant(event_uuid, participant_uuid, session_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async loadEventCode (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).loadEventCode(event_uuid)
    return response.data[0].code
  }

  @Action({ rawError: true })
  async loadPrinterCode (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).loadPrinterCode(event_uuid)
    return response.data[0]
  }

  @Action({ rawError: true })
  async deletePrinterCodeUsage (payload: any) {
    const { event_uuid, printer_code_usage_uuid } = payload
    return await Container.get(EventService).deletePrinterCodeUsage(event_uuid, printer_code_usage_uuid)
  }

  @Action({ rawError: true })
  async loadCustomFields (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).loadCustomFields(event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async addCustomField (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).addCustomField(event_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async updateCustomField ({ event_uuid, data }: any): Promise<void> {
    const response = await Container.get(EventService).updateCustomField(event_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async deleteCustomField (payload: any) {
    const { event_uuid, field_uuid } = payload
    return await Container.get(EventService).deleteCustomField(event_uuid, field_uuid)
  }

  @Action({ rawError: true })
  async fetchBookmarks (event_uuid: string) {
    const response = await Container.get(EventService).fetchBookmarks(event_uuid)
    this.context.commit('SET_EVENT_BOOKMARKS', response.data)
    return response
  }

  @Action({ rawError: true })
  async addBookmark (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(EventService).addBookmark(event_uuid, data)
    await this.context.dispatch('fetchBookmarks', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateBookmark (payload: any) {
    const { event_uuid, bookmark_uuid, data } = payload
    const response = await Container.get(EventService).updateBookmark(event_uuid, bookmark_uuid, data)
    await this.context.dispatch('fetchBookmarks', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationTypeCounts (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(EventService).getRegistrationTypeCounts(event_uuid)
    this.context.commit('SET_REGISTRATION_TYPE_COUNTS', response.data)
    return response
  }
}
