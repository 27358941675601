import { render, staticRenderFns } from "./admin.layout.vue?vue&type=template&id=4f90dc28&scoped=true&"
import script from "./admin.layout.ts?vue&type=script&lang=ts&"
export * from "./admin.layout.ts?vue&type=script&lang=ts&"
import style0 from "./admin.layout.vue?vue&type=style&index=0&id=4f90dc28&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f90dc28",
  null
  
)

export default component.exports