import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PromoteService from '../services/promote.service'

interface ReferralCode {
  event_uuid: string;
  referral_code_uuid: string;
}
interface ArchivePayload extends ReferralCode {
  active: number;
}

@Module({
  namespaced: true
})
export default class PromoteStore extends VuexModule {
  referralCodes: any[] = []

  @Mutation
  SET_REFERRAL_CODES (payload: any) {
    this.referralCodes = payload
  }

  @Action({ rawError: true })
  async archiveReferralCode (payload: ArchivePayload): Promise<void> {
    await Container.get(PromoteService).archiveReferralCode(payload)
    this.context.dispatch('getReferralCodes', payload)
  }

  @Action({ rawError: true })
  async exportReferralCode (payload: ReferralCode): Promise<any> {
    const response = await Container.get(PromoteService).exportReferralCode(payload)
    return response
  }

  @Action({ rawError: true })
  async getReferralCodes (payload: any) {
    const { event_uuid, type } = payload
    const response = await Container.get(PromoteService).getReferralCodes(event_uuid, type)
    this.context.commit('SET_REFERRAL_CODES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async createReferralCode (payload: any) {
    const { data, event_uuid } = payload
    const response = await Container.get(PromoteService).addReferralCode(data, event_uuid)
    this.context.dispatch('getReferralCodes', payload)
    return response.data
  }

  @Action({ rawError: true })
  async editReferralCode (payload: any) {
    const { data, event_uuid, referral_code_uuid } = payload
    const response = await Container.get(PromoteService).updateReferralCode(data, event_uuid, referral_code_uuid)
    this.context.dispatch('getReferralCodes', payload)
    return response.data
  }

  @Action({ rawError: true })
  async deleteReferralCode (payload: any) {
    const { event_uuid, referral_code_uuid } = payload
    const response: any = await Container.get(PromoteService).deleteReferralCode(event_uuid, referral_code_uuid)
    this.context.dispatch('getReferralCodes', payload)
    return response
  }
}
