import Vue from 'vue'
import GtrApplication from '@/bootstrap/application/application.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/bootstrap/router/router'
import store from '@/bootstrap/store/store'
import bus from '@/plugins/vue-eventbus'
import '@/assets/styles/global.css'

Vue.config.productionTip = false

new Vue({
  bus,
  vuetify,
  router,
  store,
  render: h => h(GtrApplication)
}).$mount('#app')
