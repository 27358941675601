import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import LanguageService from '../services/language.service'

@Module({
  namespaced: true
})
export default class LanguageStore extends VuexModule {
  languages: any[] = []

  @Mutation
  SET_LANGUAGES (payload: any) {
    this.languages = payload
  }

  @Action({ rawError: true })
  async fetchLanguages (payload: any) {
    const response: any = await Container.get(LanguageService).fetchLanguages(payload)
    this.context.commit('SET_LANGUAGES', response.data)
    return response
  }

  @Action({ rawError: true })
  async createLanguage (payload: any) {
    const { event_uuid, data } = payload
    const response: any = await Container.get(LanguageService).addLanguage(event_uuid, data)
    this.context.dispatch('fetchLanguages', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateLanguage (payload: any) {
    const { event_uuid, language_uuid, data } = payload
    const response: any = await Container.get(LanguageService).updateLanguage(event_uuid, language_uuid, data)
    this.context.dispatch('fetchLanguages', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async deleteLanguage (payload: any) {
    const { event_uuid, language_uuid } = payload
    const response: any = await Container.get(LanguageService).deleteLanguage(event_uuid, language_uuid)
    this.context.dispatch('fetchLanguages', event_uuid)
    return response
  }
}
