import { render, staticRenderFns } from "./gtr-pendo.vue?vue&type=template&id=f6a14128&"
import script from "./gtr-pendo.ts?vue&type=script&lang=ts&"
export * from "./gtr-pendo.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports