import Container, { Service } from 'typedi'
import Vue from 'vue'
import Notification from './notification.service'

@Service()
export default class ErrorHandlerService {
  error (error: any, options?: any) {
    const message = Vue.prototype.translateError(error)
    if (message) {
      Container.get(Notification).error(message, options)
    }
  }
}
