const apiUrl = process.env.VUE_APP_API_URL ?? 'http://localhost:8000/'
const registerUrl = process.env.VUE_APP_REG_URL ?? 'http://localhost:8081'
const api = new URL(apiUrl).origin
const register = new URL(registerUrl).origin

// make the allowList immutable to prevent it being modified.
const allowList = Object.freeze([
  api,
  register
])

export default allowList
