import { RouteConfig } from 'vue-router'
import IBaseModule from '../common/contracts/base-module.interface'
import levelTwoRoutes from './routes/level-two.router'
import user from './store/user.store'
import email from './store/emails.store'
import option from './store/option.store'
import languages from './store/languages.store'
import formbuilder from './store/formbuilder.store'
import attendee from './store/attendee.store'
import registration from './store/registration.store'
import contentpages from './store/contentpages.store'
import pricing from './store/pricing.store'
import promocodes from './store/promocodes.store'
import report from './store/report.store'
import design from './store/design.store'
import setting from './store/setting.store'
import payment from './store/payment.store'
import event from './store/event.store'
import media from './store/media.store'
import sessions from './store/sessions.store'
import certificates from './store/certificates.store'
import surveys from './store/surveys.store'
import invoice from './store/invoice.store'
import leads from './store/leads.store'
import speaker from './store/speaker.store'
import syncs from './store/syncs.store'
import promote from './store/promote.store'
import badges from './store/badges.store'
import navigation from './store/navigation.store'

export default class LevelTwoModule implements IBaseModule {
  store (): object {
    return {
      user,
      email,
      option,
      formbuilder,
      languages,
      attendee,
      registration,
      contentpages,
      pricing,
      promocodes,
      report,
      design,
      setting,
      payment,
      event,
      media,
      sessions,
      certificates,
      surveys,
      invoice,
      leads,
      speaker,
      syncs,
      promote,
      badges,
      navigation
    }
  }

  routes (): RouteConfig[] {
    return [
      ...levelTwoRoutes
    ]
  }
}
