import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const STATUS_FAILED = 3

@Component({
  name: 'GtrFileUpload'
})
export default class GtrFileUpload extends Vue {
	@Prop({ required: false, type: String, default: '' })
	label: string | undefined

	@Prop({ required: false, type: String, default: 'Drag your file(s) here to begin or click to browse' })
	message: string | undefined

  /**
   * file constraints for upload component.
   */
  @Prop({ required: false, type: String, default: '*/*' })
  fileTypes?: string

  data () {
	  return {
	    uploadedFiles: [],
	    uploadError: null,
	    currentStatus: null,
	    uploadFieldName: 'photos'
	  }
  }

  mounted () {
	  this.reset()
  }

  get isInitial () {
    return this.$data.currentStatus === STATUS_INITIAL
  }

  get isSaving () {
	  return this.$data.currentStatus === STATUS_SAVING
  }

  get isSuccess () {
	  return this.$data.currentStatus === STATUS_SUCCESS
  }

  get isFailed () {
	  return this.$data.currentStatus === STATUS_FAILED
  }

  reset () {
	  this.$data.currentStatus = STATUS_INITIAL
	  this.$data.uploadedFiles = []
	  this.$data.uploadError = null
  }

  handleClick () {
	  // TODO
  }

  async save (formData: any) {
	  this.$data.currentStatus = STATUS_SAVING
	  try {
	    this.$emit('file', formData)
	  } catch (error) {
	    this.$data.currentStatus = STATUS_FAILED
	  }
  }

  filesChange (fileList: any[]) {
    if (fileList[0].size > 2 * 1024 * 1024) {
      // File size exceeds 2MB, you can handle this case as needed
      Container.get(Notification).error('This file is too large, please make it less than 2MB')
      return false
    }
	  this.$emit('change', fileList)
  }
}
