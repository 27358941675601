import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import { ToggleModulePayload } from 'gtr-types'

@Component({
  name: 'GtrModuleWidget'
})
export default class GtrModuleWidget extends Vue {
    @Prop({ required: false, type: Number, default: 2 })
    elevation: number | undefined

    @Prop({ required: true, type: String, default: '' })
    name: string | undefined

    @Prop({ required: true, type: Boolean, default: false })
    active: boolean | undefined

    @Prop({ required: true, type: Boolean, default: false })
    activatable: boolean | undefined

    @Prop({ required: false, type: String, default: '' })
    identifier: string | undefined

    @Prop({ required: false, type: String, default: '' })
    activeImg: string | undefined

    @Prop({ required: false, type: Object, default: {} })
    route: object | undefined

    @Prop({ required: false, type: Boolean, default: false })
    info: boolean | undefined

    toggling = false;

    /**
     * toggles a given module on or off.
     */
    async toggleModule () {
      if (this.identifier) {
        const modulePayload: ToggleModulePayload = { event_uuid: this.$route.params.event_uuid, body: { module: this.identifier } }
        try {
          this.toggling = true
          if (this.active) {
            const response = await this.$store.dispatch('module/deactivateModule', modulePayload)
            if (/2\d\d/.test(response.status)) {
              Container.get(Notification).success(`Module ${this.name} deactivated`)
              this.$emit('gtr-module-deactivated', this.identifier)
            } else {
              Container.get(Notification).error(`Module ${this.name} failed to deactivate`)
            }
          } else {
            const response = await this.$store.dispatch('module/activateModule', modulePayload)
            if (/2\d\d/.test(response.status)) {
              Container.get(Notification).success(`Module ${this.name} activated`)
              this.$emit('gtr-module-activated', this.identifier)
            } else {
              Container.get(Notification).error(`Module ${this.name} failed to activate`)
            }
          }
        } catch (e) {
          Container.get(ErrorHandlerService).error(e)
        } finally {
          this.toggling = false
        }
      }
    }

    private openModule (): void {
      this.$emit('open-module')
    }
}
