import { Service } from 'typedi'
import Vue from 'vue'

@Service()
export default class Notification {
  public info (message: string, options?: any) {
    if (options) {
      options.message = message
    }
    Vue.$toast.info(message, options)
  }

  public success (message: string, options?: any) {
    if (options) {
      options.message = message
    }
    Vue.$toast.success(message, options)
  }

  public warning (message: string, options?: any) {
    if (options) {
      options.message = message
    }
    Vue.$toast.warning(message, options)
  }

  public error (message: string, options?: any) {
    if (options) {
      options.message = message
    }
    Vue.$toast.error(message, options)
  }

  public default (message: string, options?: any) {
    if (options) {
      options.message = message
    }
    Vue.$toast.default(message, options)
  }
}
