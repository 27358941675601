import { format, parse } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const DEFAULT_DATE = ''
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

@Component({
  name: 'GtrDatePicker'
})

export default class GtrDatePicker extends Vue {
   DEFAULT_DATE = ''
   DEFAULT_DIALOG_WIDTH = 340
   DEFAULT_CLEAR_TEXT = 'CLEAR'
   DEFAULT_OK_TEXT = 'OK'

  @Prop({ type: Boolean })
  loading: false|undefined

  @Prop({ type: Boolean, default: false })
  closeOnDateChange: boolean | undefined

  @Prop({ required: true, type: [Date, String], default: '' })
  label: string|undefined

  @Prop({ type: Number, default: DEFAULT_DIALOG_WIDTH })
  dialogWidth: number|undefined

  @Prop({ type: String, default: DEFAULT_DATE_FORMAT })
  dateFormat: string|any

  @Prop({ type: String, default: DEFAULT_CLEAR_TEXT })
  clearText: string|undefined

  @Prop({ type: String, default: DEFAULT_OK_TEXT })
  okText: string|undefined

  @Prop({ required: true, type: Object })
  textFieldProps: object|undefined

  @Prop({ type: Object })
  datePickerProps: object|undefined

  data () {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE
    }
  }

  get defaultDateFormat () {
    return DEFAULT_DATE_FORMAT
  }

  get formattedDate () {
    return this.selectedDate ? this.dateFormatToMMDDYYYY(this.selectedDate) : ''
  }

  get selectedDate () {
    if (this.$data.date) {
      return (this.$data.date)
    } else {
      return null
    }
  }

  get dateSelected () {
    return !this.$data.date
  }

  async mounted () {
    await this.init()
  }

  // METHODS
  init () {
    if (!this.selectedDate) {
      return
    }
    let initDate
    if (this.$data.date instanceof Date) {
      initDate = this.$data.date
    } else if (typeof this.$data.date === 'string' || this.$data.date instanceof String) {
      initDate = parse(this.selectedDate, this.dateFormat, new Date())
    }
    this.$data.date = format(initDate, DEFAULT_DATE_FORMAT)
  }

  dateChangeHandler () {
    if (this.closeOnDateChange) {
      this.okHandler()
    }
  }

  okHandler () {
    this.resetPicker()
    this.$emit('input', this.selectedDate)
  }

  clearHandler () {
    this.resetPicker()
    this.$data.date = DEFAULT_DATE
    this.$emit('input', null)
  }

  resetPicker () {
    this.$data.display = false
    this.$data.activeTab = 0
  }

  onClickOutside (event) {
    if (event.target.className === 'v-overlay__scrim') {
      this.clearHandler()
    }
  }

  dateFormatToMMDDYYYY (date) {
    const splitedDate = date.split('-')
    const formatedDate = `${splitedDate[1]}-${splitedDate[2]}-${splitedDate[0]}`
    return formatedDate
  }

  // WATCHERS
  @Watch('date')
  refreshDate () {
    this.init()
  }
}
