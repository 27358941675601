import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import CertificatesService from '../services/certificates.service'

@Module({
  namespaced: true
})
export default class CertificatesStore extends VuexModule {
    certificates: any[] = []
    certificate_templates: any[] = []

    @Mutation
    SET_CERTIFICATES (payload: any) {
      this.certificates = payload
    }

    @Mutation
    SET_CERTIFICATE_TEMPLATES (payload: any) {
      this.certificate_templates = payload
    }

    @Action({ rawError: true })
    async fetchCertificates (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(CertificatesService).fetchCertificates(event_uuid)
      this.context.commit('SET_CERTIFICATES', response.data)
      return response
    }

    @Action({ rawError: true })
    async createCertificate (payload: any) {
      const { event_uuid, data } = payload
      const response = await Container.get(CertificatesService).createCertificate(event_uuid, data)
      this.context.dispatch('fetchCertificates', payload)
      return response
    }

    @Action({ rawError: true })
    async updateCertificate (payload: any) {
      const { event_uuid, certificate_uuid, data } = payload
      const response = await Container.get(CertificatesService).updateCertificate(event_uuid, certificate_uuid, data)
      this.context.dispatch('fetchCertificates', payload)
      return response
    }

    @Action({ rawError: true })
    async deleteCertificate (payload: any) {
      const { event_uuid, certificate_uuid } = payload
      const response = await Container.get(CertificatesService).deleteCertificate(event_uuid, certificate_uuid)
      this.context.dispatch('fetchCertificates', payload)
      return response
    }

    @Action({ rawError: true })
    async fetchCertificateTemplates () {
      const response = await Container.get(CertificatesService).fetchCertificateTemplates()
      this.context.commit('SET_CERTIFICATE_TEMPLATES', response.data)
      return response
    }
}
