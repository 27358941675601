import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrAceEditor'
})
export default class GtrAceEditor extends Vue {
    @Prop({ required: false, type: String })
    label: string|undefined

    @Prop({ required: false, type: String, default: '' })
    value: string|undefined

    @Prop({ required: false, type: String, default: '250' })
    height: string|undefined

    @Prop({ required: false, type: String, default: 'css' })
    lang: string|undefined

    @Prop({ required: false, type: String, default: '100%' })
    width: string|undefined

    @Prop({ required: false, type: Object, default: {} })
    options: any|undefined

    get localValue () {
      return this.value
    }

    set localValue (localValue) {
      if (localValue) {
        this.$emit('input', localValue)
      }
    }

    aceEditorInit () {
      require('brace/ext/language_tools')
      require('brace/mode/html')
      require('brace/mode/javascript')
      require('brace/mode/less')
      require('brace/theme/chrome')
      require('brace/snippets/javascript')
    }
}
