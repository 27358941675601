import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'gtr-loader'
})
export default class GtrLoader extends Vue {
  @Prop({ required: true, type: Number, default: 64 })
  size: number | undefined;

  @Prop({ required: true, type: Boolean, default: false })
  value: boolean | undefined;

  @Prop({ required: false, type: Number, default: 10 })
  index: number | undefined;
}
