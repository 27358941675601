import HttpClient from '@/modules/common/services/http-client.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import router from '../router/router'
import store from '../store/store'

Container.get(HttpClient).getInstance().interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    if (!error.response) {
      return Promise.reject(error)
    } else if (error.response.status) {
      switch (error.response.status) {
        case 400:
          break
        case 401:
          Container.get(GtrStorage).removeItem('security_context')
          if (router.currentRoute.name !== 'security.login') {
            sessionStorage.setItem('last_url', location.href)
            router.push({ name: 'security.login' }, () => {
              store.dispatch('common/hideLoader')
            })
          }
          break
      }
      return Promise.reject(error.response)
    }
  }
)
