import Container, { Service } from 'typedi'
import IHttpClientGetParameters from '../../contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '../../contracts/http-client/http-client-post-parameters.interface'
import Company from '../../models/company.model'
import HttpClient from '../http-client.service'
import IHttpClientPatchParameters from '../../contracts/http-client/http-client-patch-parameters.interface'
import FileUploadService from '../file-upload.service'
import IHttpClientDeleteParameters from '../../contracts/http-client/http-client-delete-parameters.interface'
import { CompanyReportsChartDataTypesEnum } from '@/modules/level-one/enums/company-reports-chart-data-types.enum'

@Service()
export default class CompanyService {
  public async register (company: Company): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: '/company',
      requireToken: true,
      payload: company
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateCompany (uuid: string, data: any) {
    if (data.uploadNewLogo) {
      const vaporResponse = await Container.get(FileUploadService).uploadFile(data.logo)
      data.logo = vaporResponse
    }
    const httpParams: IHttpClientPatchParameters = {
      url: `/company/${uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getCompanies () {
    const httpParams: IHttpClientGetParameters = {
      url: '/companies',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getCompanySyncs (company_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/company/${company_uuid}/syncs`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getUserGroups () {
    const httpParams: IHttpClientGetParameters = {
      url: '/user-groups',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getUserGroup (user_group_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/user-groups/${user_group_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateUserGroup (user_group_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user-groups/${user_group_uuid}`,
      requireToken: true,
      payload: data
    }
    return await Container.get(HttpClient).patch(httpParams)
  }

  public async getUserGroupMembers (user_group_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/user-groups/${user_group_uuid}/members`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPermissions () {
    const httpParams: IHttpClientGetParameters = {
      url: '/permissions/structured',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveUserGroupPermissions (user_group_uuid: string, permissions: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/user-groups/${user_group_uuid}/permissions`,
      requireToken: true,
      payload: permissions
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getPrinters () {
    const httpParams: IHttpClientGetParameters = {
      url: '/printer-machines',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getCompanyPrinters (company_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `company/${company_uuid}/printer-machines`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPrinter (printer_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/printer-machines/${printer_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deletePrinter (printer_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/printer-machines/${printer_uuid}`,
      requireToken: true
    }
    return Container.get(HttpClient).delete(httpParams)
  }

  public async getCompany (company_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/company/${company_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getCompanyStats (payload: any) {
    const uuid = payload.company_uuid
    const url = payload.stat_param ? `/company/${uuid}/overview-stats?days_back=${payload.stat_param}` : `/company/${uuid}/overview-stats`
    const httpParams: IHttpClientGetParameters = {
      url: url,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteCompany (company_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/company/${company_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getCompanyBlacklists (company_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/company/${company_uuid}/blacklists`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getCompanyBlacklist (company_uuid: string, blacklist_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/company/${company_uuid}/blacklist/${blacklist_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateBlacklist (company_uuid: string, blacklist_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/company/${company_uuid}/blacklist/${blacklist_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async saveBlacklist (company_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/company/${company_uuid}/blacklist/`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createUserGroup (data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: '/user-groups',
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async editUserGroup (user_group_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user-groups/${user_group_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async saveMachine (uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/printer-machines/${uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async saveAttendeeGlobalSearch (email: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: '/global-participant-search',
      requireToken: true,
      payload: email
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getAttendeeGlobalSearch (uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/global-participant-search/${uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveEventGlobalSearch (searchTerm: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: '/event-search',
      requireToken: true,
      payload: { search_term: searchTerm }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getCompanyLeaderboards (uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: '/platform-reporting/company-leaderboard/' + uuid,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getCompanyPerformanceStats (uuid: string, daysBack: number) {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/company-stats/${uuid}?days_back=${daysBack}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getCompanyPerformanceChartData (uuid: string, daysBack: number, itemsArray: CompanyReportsChartDataTypesEnum[]) {
    const items = itemsArray?.join('&items[]=')
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/company-chart-data/${uuid}?days_back=${daysBack}&items[]=${items}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportCompanyPerformanceLeaderboard (uuid: string, type: string, limit: number|string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/company-leaderboard/${uuid}/${type}/export?file_type=xlsx&limit=${limit}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async saveCompanyAttendeeSearch (companyUuid: string, criteria: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `company/${companyUuid}/participant-search`,
      requireToken: true,
      payload: criteria
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async getCompanyAttendeeSearch (companyUuid: string, uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `company/${companyUuid}/participant-search/${uuid}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }
}
