export enum Module {
    REGISTRATION = 'REGISTRATION',
    LEADS = 'LEADS',
    SESSION_TRACKING = 'SESSION_TRACKING',
    BADGES = 'BADGES',
    SURVEYS = 'SURVEYS',
    CALL_FOR_PAPERS = 'CALL_FOR_PAPERS',
    EXHIBITORS = 'EXHIBITORS',
    HOTELS = 'HOTELS',
    PROMOTE = 'PROMOTE'
}
