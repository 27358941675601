import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'page'
})
export default class GtrPage extends Vue {
  @Prop({ required: false, default: true, type: Boolean })
  header: boolean | undefined;

  @Prop({ required: true, default: 'Title', type: String })
  title: string | undefined;

  @Prop({ required: false, default: '', type: String })
  subTitle: string | undefined;

  @Prop({ required: false, default: 'mdi-apps', type: String })
  icon: string | undefined;

  @Prop({ required: false, default: true, type: Boolean })
  showIcon: boolean | undefined;
}
