import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import { RouteConfig } from 'vue-router'

const levelTwoRoutes: Array<RouteConfig> = [
  {
    path: '/company/register',
    name: 'level-two.company.register',
    meta: {
      title: 'Register Company',
      layout: 'security',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN]
    },
    component: () => import('@/modules/level-two/views/register-company/register-company.view.vue')
  },
  {
    path: '/company/:uuid/dashboard',
    name: 'level-two.company.dashboard',
    meta: {
      title: 'Dashboard',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.dashboard.view'
    },
    component: () => import('@/modules/level-two/views/company-dashboard/company-dashboard.view.vue')
  },
  {
    path: '/company/:uuid/blacklists',
    name: 'level-two.companies.blacklist',
    meta: {
      title: 'Company BlackLists',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.blacklist.view'
    },
    component: () => import('@/modules/level-one/views/companies/blacklist/blacklist.view.vue')
  },
  {
    path: '/company/:uuid/view',
    name: 'level-two.company.info',
    meta: {
      title: 'Manage Account',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.info.view'
    },
    component: () => import('@/modules/level-two/views/company-info/company-info.view.vue')
  }, {
    path: '/company/:uuid/fonts',
    name: 'level-two.company.fonts',
    meta: {
      title: 'Fonts',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.info.view'
    },
    component: () => import('@/modules/level-two/views/company-fonts/company-fonts.view.vue')
  }, {
    path: '/company/:uuid/plans',
    name: 'level-two.company.plans',
    meta: {
      title: 'Plans and Pricing',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.info.view'
    },
    component: () => import('@/modules/level-two/views/company-plans/company-plans.view.vue')
  },
  {
    path: '/company/:uuid/printers',
    name: 'level-two.printers',
    meta: {
      title: 'Printers',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.printers.view'
    },
    component: () => import('@/modules/level-two/views/company-printers/company-printers.view.vue')
  },
  {
    path: '/company/:uuid/edit',
    name: 'level-two.company.edit',
    meta: {
      title: 'Edit Company',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.info.edit'
    },
    component: () => import('@/modules/level-two/views/company-info/edit/edit.view.vue')
  },
  {
    path: '/company/:uuid/performance',
    name: 'level-two.company.performance',
    meta: {
      title: 'Company Performance',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.info.view'
    },
    component: () => import('@/modules/level-two/views/company-performance/company-performance.view.vue')
  },
  {
    path: '/company/:uuid/users/view',
    name: 'level-two.company.users',
    meta: {
      title: 'Company Users',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.users.view'
    },
    component: () => import('@/modules/level-two/views/company-users/company-users.view.vue')
  },
  {
    path: '/company/:uuid/events/view',
    name: 'level-two.company.events',
    meta: {
      title: 'Company Events',
      layout: 'global-solution',
      requireAuth: true,
      permissions: 'company.dashboard.view'
    },
    component: () => import('@/modules/level-two/views/company-events/company-events.view.vue')
  },
  {
    path: '/company/:uuid/user/:user_uuid/view',
    name: 'level-two.company.users.details',
    meta: {
      title: 'User Details',
      requireAuth: true,
      layout: 'global-solution',
      permissions: 'company.users.view'
    },
    component: () => import('@/modules/level-two/views/company-users/company-user-details.view.vue')
  },
  {
    path: '/company/:uuid/event',
    meta: {
      requireAuth: true
      // authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
    },
    component: () => import('@/modules/level-two/views/event/index.view.vue'),
    children: [
      {
        path: ':event_uuid/support',
        name: 'level-two.modules.support',
        meta: {
          title: 'Support',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.support.view'
        },
        component: () => import('@/modules/level-two/views/support/support.view.vue')
      },
      {
        path: ':event_uuid/view',
        name: 'level-two.event.event-info',
        meta: {
          title: 'Event Info',
          layout: 'event',
          requireAuth: true,
          permissions: ['general.dashboard.view'],
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/event-info/event-dashboard.view.vue')
      },
      {
        path: ':event_uuid/scans',
        name: 'level-two.event.scans',
        meta: {
          title: 'Scans',
          layout: 'event',
          requireAuth: true,
          permissions: ['general.microscanners.view'],
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/scans/scans.view.vue')
      },
      {
        path: ':event_uuid/scans/:file_name/:deviceid',
        name: 'level-two.event.scans.fileview',
        meta: {
          title: 'Scans',
          layout: 'event',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/scans/fileview.view.vue')
      },
      {
        path: ':event_uuid/scans/:file_name/:deviceid/edit/:row_uuid',
        name: 'level-two.event.scans.fileview.edit',
        meta: {
          title: 'Scans',
          layout: 'event',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/scans/fileview-edit.view.vue')
      },
      {
        path: ':event_uuid/modules',
        name: 'level-two.event.modules',
        meta: {
          title: 'Modules',
          layout: 'event',
          requireAuth: true,
          permissions: 'events.enable_modules.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/modules.view.vue')
      },
      {
        path: ':event_uuid/attendees',
        name: 'level-two.event.attendees',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees-pending',
        name: 'level-two.event.attendees-pending',
        meta: {
          title: 'Participants Pending',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/scheduled-email/:scheduled_email_uuid',
        name: 'level-two.event.attendees.scheduled-email.search',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/referral-code/:referral_module/:referral_value/:participant_status',
        name: 'level-two.event.attendees.referral.search.status',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/option-group/:option_group_uuid/option/:option_uuid',
        name: 'level-two.event.attendees.option.search',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/option-group/:option_group_uuid/option/:option_uuid/:option_status_filter',
        name: 'level-two.event.attendees.option.search',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/:search_field/:search_value',
        name: 'level-two.event.attendees.search',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/search/:search_field/:search_value/:participant_status',
        name: 'level-two.event.attendees.search.status',
        meta: {
          title: 'Participants',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/attendees.view.vue')
      },
      {
        path: ':event_uuid/attendees/:attendee_uuid/edit',
        name: 'level-two.event.attendees.edit',
        meta: {
          title: 'Edit Attendee',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/edit-attendee/attendee-edit.vue')
      },
      {
        path: ':event_uuid/attendees/:attendee_uuid/email',
        name: 'level-two.event.attendees.email',
        meta: {
          title: 'Send Email',
          layout: 'event',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER],
          permissions: 'participants.email_options.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/edit-attendee/send-email.view.vue')
      },
      {
        path: ':event_uuid/attendees/import',
        name: 'level-two.event.attendees.import',
        meta: {
          title: 'Import Attendees',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.participants.create'
        },
        component: () => import('@/modules/level-two/views/event/attendees/import/import.view.vue')
      },
      {
        path: ':event_uuid/attendees/import/:import_uuid/edit',
        name: 'level-two.event.attendees.import.edit',
        meta: {
          title: 'Edit Import Attendees',
          requireAuth: true,
          layout: 'event',
          permissions: 'general.participants.edit'
        },
        component: () => import('@/modules/level-two/views/event/attendees/import/edit.view.vue')
      },
      {
        path: ':event_uuid/attendees/groups',
        name: 'level-two.event.attendees.groups',
        meta: {
          title: 'Groups',
          requireAuth: true,
          layout: 'event',
          permissions: 'participants.group.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/groups/groups.view.vue')
      },
      {
        path: ':event_uuid/attendees/group/:group_uuid',
        name: 'level-two.event.attendees.group',
        meta: {
          title: 'Group',
          requireAuth: true,
          layout: 'event',
          permissions: 'participants.group.view'
        },
        component: () => import('@/modules/level-two/views/event/attendees/groups/group/group.view.vue')
      },
      {
        path: ':event_uuid/emails',
        name: 'level-two.event.emails',
        meta: {
          title: 'Emails',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.emails.view'
        },
        component: () => import('@/modules/level-two/views/event/emails/emails.view.vue')
      },
      {
        path: ':event_uuid/emails/create',
        name: 'level-two.event.emails.create',
        meta: {
          title: 'Create Email',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.emails.create'
        },
        component: () => import('@/modules/level-two/views/event/emails/email/new/new-email.view.vue')
      },
      {
        path: ':event_uuid/emails/:email_uuid/edit',
        name: 'level-two.event.emails.edit',
        meta: {
          title: 'Edit Email',
          layout: 'event',
          requireAuth: true,
          permission: 'general.emails.edit'
        },
        component: () => import('@/modules/level-two/views/event/emails/email/edit/edit-email.view.vue')
      },
      {
        path: ':event_uuid/emails/create/scheduled',
        name: 'level-two.event.emails.create-scheduled',
        meta: {
          title: 'Create Scheduled Email',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.emails.create'
        },
        component: () => import('@/modules/level-two/views/event/emails/scheduled/new/new-scheduled-email.view.vue')
      },
      {
        path: ':event_uuid/emails/:email_uuid/scheduled/edit',
        name: 'level-two.event.emails.edit.scheduled',
        meta: {
          title: 'Edit Scheduled Email',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.emails.edit'
        },
        component: () => import('@/modules/level-two/views/event/emails/scheduled/edit/edit-scheduled-email.view.vue')
      },
      {
        path: ':event_uuid/reports',
        name: 'level-two.event.reports',
        meta: {
          title: 'Reports',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.reports.view'
        },
        component: () => import('@/modules/level-two/views/event/reports/reports.view.vue')
      },
      {
        path: ':event_uuid/reports/new',
        name: 'level-two.event.reports.create',
        meta: {
          title: 'Create Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.reports.create'
        },
        component: () => import('@/modules/level-two/views/event/reports/edit/edit.view.vue')
      },
      {
        path: ':event_uuid/reports/:report_uuid/view',
        name: 'level-two.event.reports.view',
        meta: {
          title: 'View Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.reports.view'
        },
        component: () => import('@/modules/level-two/views/event/reports/view/view.view.vue')
      },
      {
        path: ':event_uuid/reports/:report_uuid/edit',
        name: 'level-two.event.reports.edit',
        meta: {
          title: 'Edit Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.reports.edit'
        },
        component: () => import('@/modules/level-two/views/event/reports/edit/edit.view.vue')
      },
      {
        path: ':event_uuid/payments',
        name: 'level-two.event.payments',
        meta: {
          title: 'Payments',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.payments.view'
        },
        component: () => import('@/modules/level-two/views/event/payments/payments.view.vue')
      },
      {
        path: ':event_uuid/files',
        name: 'level-two.event.files',
        meta: {
          title: 'Files',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.files.view'
        },
        component: () => import('@/modules/level-two/views/event/files/files.view.vue')
      },
      {
        path: ':event_uuid/languages',
        name: 'level-two.event.languages',
        meta: {
          title: 'Languages',
          layout: 'event',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/languages/languages.view.vue')
      },
      {
        path: ':event_uuid/webhooks',
        name: 'level-two.event.webhooks',
        meta: {
          title: 'WebHooks',
          layout: 'event',
          requireAuth: true,
          permissions: ['general.webhooks.view'],
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/webhooks/webhooks.view.vue')
      },
      {
        path: ':event_uuid/activity-log',
        name: 'level-two.event.activity-log',
        meta: {
          title: 'Activity Log',
          layout: 'event',
          requireAuth: true,
          permissions: ['general.activity_log.view'],
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/activity-log/activity-log.view.vue')
      },
      {
        path: ':event_uuid/support-check',
        name: 'level-two.event.support-check',
        meta: {
          title: 'Payment Settings',
          layout: 'event',
          requireAuth: true,
          permissions: ['general.support_checks.view'],
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
        },
        component: () => import('@/modules/level-two/views/event/support-check/support-check.view.vue')
      },
      {
        path: ':event_uuid/settings',
        name: 'level-two.event.setting',
        meta: {
          title: 'Settings',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.settings.view'
        },
        component: () => import('@/modules/level-two/views/event/settings/settings.view.vue')
      },
      {
        path: ':event_uuid/syncs',
        name: 'level-two.event.syncs',
        meta: {
          title: 'Syncs',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.integrations.view'
        },
        component: () => import('@/modules/level-two/views/event/syncs/syncs.view.vue')
      },
      {
        path: ':event_uuid/syncs/create',
        name: 'level-two.event.syncs.create',
        meta: {
          title: 'Create Sync',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.integrations.create'
        },
        component: () => import('@/modules/level-two/views/event/syncs/new/new-integration.vue')
      },
      {
        path: ':event_uuid/syncs/:sync_uuid/edit',
        name: 'level-two.event.syncs.edit',
        meta: {
          title: 'Edit Sync',
          layout: 'event',
          requireAuth: true,
          permissions: 'general.integrations.edit'
        },
        component: () => import('@/modules/level-two/views/event/syncs/edit/edit-integration.vue')
      },
      {
        path: ':event_uuid/track/orders',
        name: 'level-two.modules.track.orders',
        meta: {
          title: 'Track Orders',
          layout: 'event',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/modules/track/orders/orders.view.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/registration',
    meta: {
      requireAuth: true,
      permissions: 'register.access.view'
    },
    component: () => import('@/modules/level-two/views/event/modules/registration/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'level-two.modules.registration.dashboard',
        meta: {
          title: 'Dashboard',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/dashboard/dashboard.view.vue')
      },
      {
        path: 'form',
        name: 'level-two.modules.registration.form',
        meta: {
          title: 'Form',
          requireAuth: true,
          layout: 'module',
          permissions: 'register.forms.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/form/form.view.vue')
      },
      {
        path: 'options-groups',
        name: 'level-two.modules.registration.options',
        meta: {
          title: 'Options Groups',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.options.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/options.view.vue')
      },
      {
        path: 'options-groups/new',
        name: 'level-two.modules.registration.options.new',
        meta: {
          title: 'New Option Group',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.options.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/new/new.view.vue')
      },
      {
        path: 'options-groups/:option_uuid/edit',
        name: 'level-two.modules.registration.options.edit',
        meta: {
          title: 'Edit Option Group',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.options.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/edit/edit.view.vue')
      },
      {
        path: 'options-groups/:option_uuid/import',
        name: 'level-two.modules.registration.options.import.import',
        meta: {
          title: 'Import Options',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.options.import'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/import/import.view.vue')
      },
      {
        path: 'options-groups/:option_uuid/import/:import_uuid/edit',
        name: 'level-two.modules.registration.options.import.edit',
        meta: {
          title: 'Edit Import Options',
          requireAuth: true,
          layout: 'event',
          permissions: 'register.options.import'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/import/edit.view.vue')
      },
      {
        path: 'options-groups/:option_group_uuid/:option_uuid/edit',
        name: 'level-two.modules.registration.option.edit',
        meta: {
          title: 'Edit Option',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.options.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/options/edit-option/edit-option.view.vue')
      },
      {
        path: 'design',
        name: 'level-two.modules.registration.design',
        meta: {
          title: 'Design',
          layout: 'event',
          requireAuth: true,
          permissions: 'register.design.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/design/design.view.vue')
      },
      {
        path: 'promote',
        name: 'level-two.modules.registration.promote',
        meta: {
          title: 'Promote',
          layout: 'event',
          requireAuth: true,
          permissions: 'register.promote.view',
          module: 'register'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/promote.view.vue')
      },
      {
        path: 'promote/create',
        name: 'level-two.modules.registration.promote.create',
        meta: {
          title: 'Create Referral Code',
          layout: 'event',
          requireAuth: true,
          permissions: 'register.promote.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/new/new.view.vue')
      },
      {
        path: 'promote/edit',
        name: 'level-two.modules.registration.promote.edit',
        meta: {
          title: 'Edit Referral Code',
          layout: 'event',
          requireAuth: true,
          permissions: 'register.promote.edit'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/edit/edit.view.vue')
      },
      {
        path: 'promo-codes',
        name: 'level-two.modules.registration.promo-codes',
        meta: {
          title: 'Promo Codes',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.promo_codes.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/promo-codes/promo-code.view.vue')
      },
      {
        path: 'promo-codes/new',
        name: 'level-two.modules.registration.promo-codes.new',
        meta: {
          title: 'New Promo Code',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.promo_codes.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/promo-codes/new/new.view.vue')
      },
      {
        path: 'promo-codes/import',
        name: 'level-two.modules.registration.promo-codes.import.import',
        meta: {
          title: 'Import Promo Codes',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.promo_codes.import'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/promo-codes/import/import.view.vue')
      },
      {
        path: 'promo-codes/import/:import_uuid/edit',
        name: 'level-two.modules.registration.promo-codes.import.edit',
        meta: {
          title: 'Edit Import Promo Codes',
          requireAuth: true,
          layout: 'event',
          permissions: 'register.promo_codes.import'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/promo-codes/import/edit.view.vue')
      },
      {
        path: 'promo-codes/:promo_code_uuid/edit',
        name: 'level-two.modules.registration.promo-codes.edit',
        meta: {
          title: 'Edit Promo Code',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.promo_codes.edit'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/promo-codes/edit/edit.view.vue')
      },
      {
        path: 'payment',
        name: 'level-two.modules.registration.payment',
        meta: {
          title: 'Payment',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.payments.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/payment/payment.view.vue')
      },
      {
        path: 'leads_payment',
        name: 'level-two.modules.leads.payment',
        meta: {
          title: 'Payment',
          layout: 'module',
          requireAuth: true,
          permissions: 'leads.payment.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/payment/payment.view.vue')
      },
      {
        path: 'pricing-tiers',
        name: 'level-two.modules.registration.pricing-tiers',
        meta: {
          title: 'Pricing Tiers',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.pricing_tiers.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/pricing-tiers/pricing-tiers.view.vue')
      },
      {
        path: 'pricing-tiers/new',
        name: 'level-two.modules.registration.pricing-tiers.new',
        meta: {
          title: 'New Pricing Tier',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.pricing_tiers.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/pricing-tiers/new/new.view.vue')
      },
      {
        path: 'pricing-tiers/:pricing_tier_uuid/edit',
        name: 'level-two.modules.registration.pricing-tiers.edit',
        meta: {
          title: 'Edit Pricing Tier',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.pricing_tiers.edit'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/pricing-tiers/edit/edit.view.vue')
      },
      {
        path: 'content-pages',
        name: 'level-two.modules.registration.content-pages',
        meta: {
          title: 'Content Pages',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.content_pages.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/content-pages/content-pages.view.vue')
      },
      {
        path: 'content-pages/new',
        name: 'level-two.modules.registration.content-pages.new',
        meta: {
          title: 'New Content Page',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.content_pages.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/content-pages/new/new.view.vue')
      },
      {
        path: 'content-pages/:content_page_uuid/edit',
        name: 'level-two.modules.registration.content-pages.edit',
        meta: {
          title: 'Edit Content Page',
          layout: 'module',
          requireAuth: true,
          permissions: 'register.content_pages.edit'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/content-pages/edit/edit.view.vue')
      },
      {
        path: 'settings',
        name: 'level-two.modules.registration.settings',
        meta: {
          title: 'Settings',
          layout: 'event',
          requireAuth: true,
          permissions: 'register.registration_settings.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/registration/settings/index.view.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/evals',
    meta: {
      requireAuth: true,
      permissions: 'evals.access.view'
    },
    component: () => import('@/modules/level-two/views/event/modules/evals/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'level-two.modules.evals.dashboard',
        meta: {
          title: 'Certificates & Assessments Dashboard',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/index.vue')
        // component: () => import('@/modules/level-two/views/event/modules/evals/dashboard/dashboard.view.vue')
      },
      {
        path: 'settings',
        name: 'level-two.modules.evals.settings.index',
        meta: {
          title: 'Certificates & Assessments Settings',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.settings.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/settings/index.vue')
      },
      {
        path: 'design',
        name: 'level-two.modules.evals.design.index',
        meta: {
          title: 'Certificates & Assessments Design',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.design.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/design/index.vue')
      }, {
        path: 'surveys/general/questions',
        name: 'level-two.modules.evals.surveys.general.questions',
        meta: {
          title: 'General Survey Questions',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/session/questions/index.vue')
      }, {
        path: 'surveys/session',
        name: 'level-two.modules.evals.surveys.session.index',
        meta: {
          title: 'Session Surveys',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/session/index.vue')
      }, {
        path: 'surveys/session/questions',
        name: 'level-two.modules.evals.surveys.session.questions',
        meta: {
          title: 'Session Survey Questions',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/session/questions/index.vue')
      }, {
        path: 'surveys/keynote/questions',
        name: 'level-two.modules.evals.surveys.keynote.questions',
        meta: {
          title: 'Keynote Survey Questions',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/session/questions/index.vue')
      }, {
        path: 'surveys/:session_uuid/results',
        name: 'level-two.modules.evals.surveys.survey.results',
        meta: {
          title: 'Survey Results',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/surveys/results/survey-results.view.vue')
      },
      {
        path: 'certificates',
        name: 'level-two.modules.evals.certificates.index',
        meta: {
          title: 'Certificates',
          layout: 'module',
          requireAuth: true,
          permissions: 'evals.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/evals/certificates/index.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/leads',
    meta: {
      requireAuth: true,
      permissions: 'leads.access.view'
    },
    component: () => import('@/modules/level-two/views/event/modules/leads/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'level-two.modules.leads.index',
        meta: {
          title: 'Lead Retrieval Dashboard',
          layout: 'event',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/dashboard/index.vue')
      },
      {
        path: 'by_exhibitor/:participant_uuid/scans',
        name: 'level-two.modules.leads.scans.index',
        meta: {
          title: 'Scan by Exhibitor',
          layout: 'module',
          from: 'Leads by Exhibitor',
          from_route: 'level-two.modules.leads.index',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/scans/index.vue')
      },
      {
        path: 'promote',
        name: 'level-two.modules.leads.promote',
        meta: {
          title: 'Promote',
          layout: 'event',
          requireAuth: true,
          module: 'leads'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/promote.view.vue')
      },
      {
        path: 'promote/create',
        name: 'level-two.modules.leads.promote.create',
        meta: {
          title: 'Create Referral Code',
          layout: 'event',
          requireAuth: true,
          module: 'leads'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/new/new.view.vue')
      },
      {
        path: 'promote/edit',
        name: 'level-two.modules.leads.promote.edit',
        meta: {
          title: 'Edit Referral Code',
          layout: 'event',
          requireAuth: true,
          module: 'leads'
        },
        component: () => import('@/modules/level-two/views/event/modules/promote/edit/edit.view.vue')
      },
      {
        path: 'by-attendee',
        name: 'level-two.modules.leads.by-attendee',
        meta: {
          title: 'Leads by Attendee',
          layout: 'module',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/by_attendee/index.vue')
      },
      {
        path: 'by-attendee/:participant_uuid/scans',
        name: 'level-two.modules.leads-by-attendee.scans.index',
        meta: {
          title: 'Scans by Attendee',
          layout: 'module',
          from: 'Leads by Attendee',
          from_route: 'level-two.modules.leads.by-attendee',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/scans/index.vue')
      },
      {
        path: 'date-breakdown',
        name: 'level-two.modules.leads.date-breakdown',
        meta: {
          title: 'Leads Date Breakdown',
          layout: 'module',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/date_breakdown/index.vue')
      },
      {
        path: 'date-breakdown/:participant_uuid/scans',
        name: 'level-two.modules.leads.date-breakdown.scans',
        meta: {
          title: 'Scan by Participant',
          layout: 'module',
          from: 'Leads Date Breakdown',
          from_route: 'level-two.modules.leads.date-breakdown',
          requireAuth: true,
          permissions: 'leads.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/scans/index.vue')
      },
      {
        path: 'device-check-in-out',
        name: 'level-two.modules.leads.device-check-in-out',
        meta: {
          title: 'Device Check-in/Out',
          layout: 'event',
          requireAuth: true,
          permissions: 'leads.device_check_in_out.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/device-check-in-out/device-check-in-out.vue')
      },
      {
        path: 'setup',
        name: 'level-two.modules.leads.setup',
        meta: {
          title: 'Lead Retrieval Setup',
          layout: 'event',
          requireAuth: true,
          permissions: 'leads.setup.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/setup/index.vue')
      },
      // {
      //   path: 'style',
      //   name: 'level-two.modules.leads.style',
      //   meta: {
      //     title: 'Lead Retrieval Style',
      //     layout: 'event',
      //     requireAuth: true,
      //     permissions: ['leads.style.view', 'leads.style.edit']
      //   },
      //   component: () => import('@/modules/level-two/views/event/modules/leads/style/index.vue')
      // },
      {
        path: 'design',
        name: 'level-two.modules.leads.design',
        meta: {
          title: 'Lead Retrieval Design',
          layout: 'event',
          requireAuth: true,
          permissions: ['leads.design.view']
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/design/design.view.vue')
      },
      {
        path: 'reports',
        name: 'level-two.modules.leads.reports',
        meta: {
          title: 'Lead Retrieval Reports',
          layout: 'event',
          requireAuth: true,
          permissions: 'leads.lead_retrieval_reports.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/reports/reports.view.vue')
      },
      {
        path: 'settings',
        name: 'level-two.modules.leads.settings',
        meta: {
          title: 'Lead Retrieval Settings',
          layout: 'event',
          requireAuth: true,
          permissions: ['leads.settings.view']
        },
        component: () => import('@/modules/level-two/views/event/modules/leads/settings/settings.view.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/track',
    meta: {
      requireAuth: true,
      permissions: 'track.access.view'
    },
    component: () => import('@/modules/level-two/views/event/modules/track/index.vue'),
    children: [
      {
        path: 'index',
        name: 'level-two.modules.track.index',
        meta: {
          title: 'Track',
          layout: 'event',
          requireAuth: true,
          permissions: 'track.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/track/index.vue')
      },
      {
        path: 'sessions',
        name: 'level-two.modules.track.sessions',
        meta: {
          title: 'Sessions',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.view']
        },
        component: () => import('@/modules/level-two/views/event/sessions/sessions.view.vue')
      },
      {
        path: 'sessions/import',
        name: 'level-two.modules.track.sessions.import',
        meta: {
          title: 'Imports',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.create']
        },
        component: () => import('@/modules/level-two/views/event/sessions/import/import.view.vue')
      },
      {
        path: 'sessions/import/:import_uuid/edit',
        name: 'level-two.modules.track.sessions.import.edit',
        meta: {
          title: 'Edit Import Sessions',
          requireAuth: true,
          layout: 'event',
          permissions: ['track.sessions.create']
        },
        component: () => import('@/modules/level-two/views/event/sessions/import/edit.view.vue')
      },
      {
        path: 'sessions/:session_uuid/import-access',
        name: 'level-two.modules.track.sessions-access.import',
        meta: {
          title: 'Imports',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.create']
        },
        component: () => import('@/modules/level-two/views/event/sessions/import-access/import.view.vue')
      },
      {
        path: 'sessions/:session_uuid/import-access/:import_uuid/edit',
        name: 'level-two.modules.track.sessions-access.import.edit',
        meta: {
          title: 'Edit Import Sessions',
          requireAuth: true,
          layout: 'event',
          permissions: ['track.sessions.create']
        },
        component: () => import('@/modules/level-two/views/event/sessions/import-access/edit.view.vue')
      },
      {
        path: 'sessions/new',
        name: 'level-two.modules.track.sessions.new',
        meta: {
          title: 'New Session',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.create']
        },
        component: () => import('@/modules/level-two/views/event/sessions/details/details.view.vue')
      },
      {
        path: 'sessions/:session_uuid/edit',
        name: 'level-two.modules.track.sessions.edit',
        meta: {
          title: 'Edit Session',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.view']
        },
        component: () => import('@/modules/level-two/views/event/sessions/edit/edit.view.vue')
      },
      {
        path: 'sessions/:session_uuid/details',
        name: 'level-two.modules.track.sessions.details',
        meta: {
          title: 'Session Details',
          layout: 'event',
          requireAuth: true,
          permissions: ['track.sessions.edit']
        },
        component: () => import('@/modules/level-two/views/event/sessions/details/details.view.vue')
      },
      {
        path: 'reports',
        name: 'level-two.modules.track.reports',
        meta: {
          title: 'Reports',
          layout: 'event',
          requireAuth: true,
          permissions: 'track.attendance_tracking_reports.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/reports/reports.view.vue')
      },
      {
        path: 'reports/new',
        name: 'level-two.modules.track.reports.create',
        meta: {
          title: 'Create Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'track.attendance_tracking_reports.create'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/reports/edit/edit.view.vue')
      },
      {
        path: 'reports/:report_uuid/view',
        name: 'level-two.modules.track.reports.view',
        meta: {
          title: 'View Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'track.attendance_tracking_reports.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/reports/view/view.view.vue')
      },
      {
        path: 'reports/:report_uuid/edit',
        name: 'level-two.modules.track.reports.edit',
        meta: {
          title: 'Edit Report',
          layout: 'event',
          requireAuth: true,
          permissions: 'track.attendance_tracking_reports.edit'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/reports/edit/edit.view.vue')
      },
      {
        path: 'settings',
        name: 'level-two.modules.track.settings',
        meta: {
          title: 'Attendance Tracking Settings',
          layout: 'module',
          requireAuth: true,
          permissions: 'track.settings.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/track/settings/settings.view.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/badges',
    meta: {
      requireAuth: true,
      permissions: 'badges.access.view'
    },
    component: () => import('@/modules/level-two/views/event/modules/badges/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'level-two.modules.badges.dashboard',
        meta: {
          title: 'Badges Dashboard',
          layout: 'module',
          requireAuth: true,
          permissions: 'badges.access.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/badges/dashboard/dashboard.view.vue')
      },
      {
        path: 'badges',
        name: 'level-two.modules.badges.badges',
        meta: {
          title: 'Upload & Edit Badges',
          layout: 'module',
          requireAuth: true,
          permissions: 'badges.upload_edit_badges.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/badges/badges/index.vue')
      },
      {
        path: 'check-in-setup',
        name: 'level-two.modules.badges.settings',
        meta: {
          title: 'Check-in Setup',
          layout: 'module',
          requireAuth: true,
          permissions: 'badges.check_in_setup.view'
        },
        component: () => import('@/modules/level-two/views/event/modules/badges/settings/settings.view.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/call-papers',
    meta: {
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
    },
    component: () => import('@/modules/level-two/views/event/modules/call_4_papers/index.vue'),
    children: [
      {
        path: 'index',
        name: 'level-two.modules.call_4_papers.index',
        meta: {
          title: 'Call 4 Papers',
          layout: 'module',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/modules/call_4_papers/call_4_papers/index.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/exhibits',
    meta: {
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
    },
    component: () => import('@/modules/level-two/views/event/modules/exhibits/index.vue'),
    children: [
      {
        path: 'index',
        name: 'level-two.modules.exhibits.index',
        meta: {
          title: 'Exhibits',
          layout: 'module',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/modules/exhibits/exhibits/index.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/event/:event_uuid/hotels',
    meta: {
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
    },
    component: () => import('@/modules/level-two/views/event/modules/hotels/index.vue'),
    children: [
      {
        path: 'index',
        name: 'level-two.modules.hotels.index',
        meta: {
          title: 'Hotels',
          layout: 'module',
          requireAuth: true,
          authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
        },
        component: () => import('@/modules/level-two/views/event/modules/hotels/hotels/index.vue')
      }
    ]
  },
  {
    path: '/company/:uuid/user/me/edit',
    name: 'level-two.profile',
    meta: {
      layout: 'global-solution',
      requireAuth: true
    },
    component: () =>
      import(
        '@/modules/common/views/profile/profile.view.vue'
      )
  }
]

export default levelTwoRoutes
