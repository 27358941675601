import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrModal'
})
export default class GtrModal extends Vue {
  @Prop({ required: false, type: String, default: 'Title' })
  title: string | undefined;

  @Prop({ required: false, type: Number, default: 450 })
  width: number | undefined;

  @Prop({ required: true, type: Boolean, default: false })
  value: boolean | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  closable: boolean | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  scrollable: boolean | undefined;

  @Prop({ required: false, type: Boolean, default: true })
  spaceActions: boolean | undefined;

  data () {
    return {
      show: false
    }
  }

  @Watch('value', { immediate: true })
  onValueChange (newValue: boolean) {
    this.$data.show = newValue
    this.$emit('change', newValue)
  }
}
