import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export default class NavigationStore extends VuexModule {
  moduleLayoutMiniVariant = false

  @Action({ rawError: true })
  async setModuleLayoutMiniVariant (payload: boolean) {
    this.context.commit('SET_MODULE_LAYOUT_MINI_VARIANT', payload)
  }

  @Mutation
  SET_MODULE_LAYOUT_MINI_VARIANT (payload: boolean) {
    this.moduleLayoutMiniVariant = payload
  }
}
