import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Container from 'typedi'
import UserService from '@/modules/common/services/company/user.service'

@Module({
  namespaced: true
})
export default class UserStore extends VuexModule {
    user: any = null

    @Mutation
    SET_USER (payload: any) {
      this.user = payload
    }

    @Action({ rawError: true })
    async fetchUser (payload: any) {
      return payload
    }
}
