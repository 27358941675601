import { RouteConfig } from 'vue-router'
import { AccessLevel } from '../enums/access-levels.enum'

const commonRoutes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'security.login',
    meta: {
      title: 'Login',
      layout: 'security',
      requireAuth: false,
      authorize: [AccessLevel.GUEST]
    },
    component: () => import('@/modules/common/views/security/login/login.view.vue')
  },
  {
    path: '/password/reset',
    name: 'security.reset-password',
    meta: {
      title: 'Reset Password',
      layout: 'security',
      requireAuth: false,
      authorize: [AccessLevel.GUEST]
    },
    component: () => import('@/modules/common/views/security/password/reset/reset-password.view.vue')
  },
  {
    path: '/login/password-token/:token',
    name: 'security.change-password',
    meta: {
      title: 'Change Password',
      layout: 'security',
      requireAuth: false,
      authorize: [AccessLevel.GUEST]
    },
    component: () => import('@/modules/common/views/security/password/change/change-password.view.vue')
  },
  {
    path: '/check-email',
    name: 'security.check-email',
    meta: {
      requireAuth: false,
      layout: 'security',
      authorize: [AccessLevel.GUEST],
      title: 'Check Email'
    },
    component: () =>
      import(
        '@/modules/common/views/security/check-email/check-email.view.vue'
      )
  },
  {
    path: '/login/activation/:token',
    name: 'security.account_activate',
    meta: {
      layout: 'security',
      requireAuth: false,
      title: 'Account Activate',
      authorize: [AccessLevel.GUEST]
    },
    component: () =>
      import(
        '@/modules/common/views/security/activate-account/activate-account.view.vue'
      )
  },
  {
    path: '/setup/password/:token',
    name: 'security.password_setup',
    meta: {
      layout: 'security',
      requireAuth: false,
      title: 'Setup Password',
      authorize: [AccessLevel.GUEST]
    },
    component: () =>
      import(
        '@/modules/common/views/security/password-setup/password-setup.view.vue'
      )
  },
  {
    path: '*',
    redirect: { name: 'error.404' }
  },
  {
    path: '/error/404',
    name: 'error.404',
    meta: {
      layout: 'security',
      requireAuth: false,
      authorize: [AccessLevel.GUEST]
    },
    component: () => import('@/modules/common/views/errors/404/404.view.vue')
  }
]

export default commonRoutes
