import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import { RouteConfig } from 'vue-router'

const levelOneRoutes: Array<RouteConfig> = [
  {
    path: '/admin/dashboard',
    name: 'level-one.dashboard',
    meta: {
      title: 'Dashboard',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/dashboard/dashboard.view.vue')
  },
  {
    path: '/admin/companies',
    name: 'level-one.companies',
    meta: {
      title: 'Companies',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/companies/companies.view.vue')
  },
  {
    path: '/admin/companies/global-event-search',
    name: 'level-one.global-event-search',
    meta: {
      title: 'Global Event Search',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/companies/global-event-search/global-event-search.view.vue')
  },
  {
    path: '/admin/companies/global-attendee-search',
    name: 'level-one.global-attendee-search',
    meta: {
      title: 'Global Attendee Search',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/companies/global-attendee-search/global-attendee-search.view.vue')
  },
  {
    path: '/software',
    name: 'level-one.software',
    meta: {
      title: 'Software',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/software/software.view.vue')
  },
  {
    path: '/scans/set-event/:file_name/:deviceid',
    name: 'level-one.scans',
    meta: {
      title: 'Scans Set Event',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/scans/setevent.view.vue')
  },
  {
    path: '/admin/user-groups',
    name: 'level-one.user_groups',
    meta: {
      title: 'User Groups',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/user_groups/user_groups.view.vue')
  },
  {
    path: '/admin/integrations',
    name: 'level-one.integrations',
    meta: {
      title: 'Integrations',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/integrations/integrations.view.vue')
  },
  {
    path: '/admin/user-groups/:uuid',
    name: 'level-one.user_groups.edit',
    meta: {
      title: 'User Group Edit',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/user_groups/edit.view.vue')
  },
  {
    path: '/admin/printers',
    name: 'level-one.printers',
    meta: {
      title: 'Printers',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/printers/printers.view.vue')
  },
  {
    path: '/admin/printers/:uuid',
    name: 'level-one.printer',
    meta: {
      title: 'Printer',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/printers/printer.view.vue')
  },
  {
    path: '/admin/users',
    name: 'level-one.users',
    meta: {
      title: 'Users',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/users/users.view.vue')
  },
  {
    path: '/admin/platform-reports',
    name: 'level-one.platform-reports',
    meta: {
      title: 'Platform Reports',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/platform-reports/platform-reports.view.vue')
  },
  {
    path: '/admin/users/:user_uuid/details',
    name: 'level-one.users.details',
    meta: {
      title: 'User Details',
      layout: 'admin',
      requireAuth: true,
      authorize: [AccessLevel.SUPER_ADMIN, AccessLevel.RESELLER_ADMIN]
    },
    component: () => import('@/modules/level-one/views/users/details/details.view.vue')
  },
  {
    path: '/admin/profile',
    name: 'level-one.profile',
    meta: {
      title: 'User Profile',
      layout: 'admin',
      requireAuth: true
    },
    component: () => import('@/modules/common/views/profile/profile.view.vue')
  }
]

export default levelOneRoutes
