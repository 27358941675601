import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import SettingService from '../services/setting.service'

@Module({
  namespaced: true
})
export default class SettingStore extends VuexModule {
  settings: any[] = []
  settingsFields: any[] = []
  lr_settings: any[] = []
  email_settings: any[] = []
  email_settingsFields: any[] = []
  lr_settingsFields: any[] = []
  providerFields: any = {}
  evals_settings: any[] = []
  evals_settingsFields: any[] = []
  track_settings: any[] = []
  track_settingsFields: any[] = []

  @Mutation
  SET_SETTINGS (payload: any) {
    this.settings = payload
  }

  @Mutation
  SET_SETTINGS_LR (payload: any) {
    this.lr_settings = payload
  }

  @Mutation
  SET_SETTINGS_EMAIL (payload: any) {
    this.email_settings = payload
  }

  @Mutation
  SET_SETTINGS_EVALS (payload: any) {
    this.evals_settings = payload
  }

  @Mutation
  SET_SETTINGS_TRACK (payload: any) {
    this.track_settings = payload
  }

  @Mutation
  SET_PROVIDER_FIELDS (payload: any) {
    this.providerFields = payload
  }

  @Mutation
  SET_SETTINGS_FIELDS (payload: any) {
    const omitFields = {
      General: [
        'allow_invite_requests',
        'show_content_pages_before_logging_in',
        'invite_request_contact_email',
        'barcode_format'
      ],
      'GTR Register': [
        'registration_page_name',
        'tax_rate',
        'allow_save_and_finish',
        'lock_in_selection_date_mode'
      ],
      'GTR Leads': [
        'auto_send_leads_email',
        'onsite_contact_name',
        'onsite_contact_phone',
        'qualifiers',
        'qualifiers_with_seq',
        'can_cc_on_email_leads',
        'scan_modes',
        'show_access_unknown_as_denied',
        'show_allow_button',
        'barcode_delimiter',
        'barcode_pattern',
        'privacy_optin_verbiage'
      ]
    }

    if (payload['GTR Leads']) {
      delete payload['GTR Leads']
    }
    if (Object.keys(payload).length !== 0) {
      for (const category in omitFields) {
        if (omitFields[category]) {
          const categorySettings = omitFields[category]
          for (const setting of categorySettings) {
            if (payload[category]) {
              if (setting in payload[category]) {
                delete payload[category][setting]
              }
            }
          }
        }
      }
    }

    this.settingsFields = payload
  }

  @Mutation
  SET_SETTINGS_FIELDS_LR (payload: any) {
    const omitFields = {
      General: [
        'allow_invite_requests',
        'show_content_pages_before_logging_in',
        'invite_request_contact_email',
        'barcode_format'
      ],
      'GTR Register': [
        'registration_page_name',
        'tax_rate',
        'allow_save_and_finish',
        'lock_in_selection_date_mode'
      ],
      'GTR Leads': [
        'auto_send_leads_email',
        'onsite_contact_name',
        'onsite_contact_phone',
        'qualifiers',
        'qualifiers_with_seq',
        'can_cc_on_email_leads',
        'scan_modes',
        'show_access_unknown_as_denied',
        'show_allow_button',
        'barcode_delimiter',
        'barcode_pattern',
        'privacy_optin_verbiage'
      ]
    }

    if (Object.keys(payload).length !== 0) {
      for (const category in omitFields) {
        if (omitFields[category]) {
          const categorySettings = omitFields[category]
          for (const setting of categorySettings) {
            if (payload[category]) {
              if (setting in payload[category]) {
                delete payload[category][setting]
              }
            }
          }
        }
      }
    }

    this.lr_settingsFields = payload
  }

  @Mutation
  SET_SETTINGS_FIELDS_EMAIL (payload: any) {
    this.email_settingsFields = payload
  }

  @Mutation
  SET_SETTINGS_FIELDS_EVALS (payload: any) {
    const omitFields = [
      'general_survey_header',
      'general_survey_title',
      'sidebar_welcome_verbiage',
      'generic_login_verbiage',
      'generic_login_bottom_verbiage',
      'portal_fields',
      'auto_logout_milliseconds',
      'email_offset',
      'general_instructions',
      'keynote_instructions',
      'portal_verbiage',
      'show_general_after',
      'survey_login_fields'
    ]
    if (Object.keys(payload).length !== 0) {
      const category = 'Assessments and Certifications'
      if (payload[category]) {
        for (const setting of omitFields) {
          if (setting in payload[category]) {
            delete payload[category][setting]
          }
        }
      }
    }

    this.evals_settingsFields = payload
  }

  @Mutation
  SET_SETTINGS_FIELDS_TRACK (payload: any) {
    this.track_settingsFields = payload
  }

  @Action({ rawError: true })
  async getSettings (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SettingService).getSettings(event_uuid)
    this.context.commit('SET_SETTINGS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsLR (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SettingService).getSettingsLR(event_uuid)
    this.context.commit('SET_SETTINGS_LR', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsEmail (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SettingService).getSettingsEmail(event_uuid)
    this.context.commit('SET_SETTINGS_EMAIL', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsEvals (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SettingService).getSettingsEvals(event_uuid)
    this.context.commit('SET_SETTINGS_EVALS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsTrack (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(SettingService).getSettingsTrack(event_uuid)
    this.context.commit('SET_SETTINGS_TRACK', response.data)
    return response
  }

  @Action({ rawError: true })
  async getProviderFields (payload: any) {
    const { event_uuid, provider, table } = payload
    const response = await Container.get(SettingService).getProviderFields(event_uuid, provider, table)
    this.context.commit('SET_PROVIDER_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsFields () {
    const response = await Container.get(SettingService).getSettingsFields()
    this.context.commit('SET_SETTINGS_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsByUuid (payload: any) {
    const { event_uuid, settings_uuid } = payload
    const response = await Container.get(SettingService).getSettingsByUuid(event_uuid, settings_uuid)
    this.context.commit('SET_SETTINGS_FIELDS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsFieldsEvals () {
    const response = await Container.get(SettingService).getSettingsFieldsEvals()
    this.context.commit('SET_SETTINGS_FIELDS_EVALS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsFieldsLR () {
    const response = await Container.get(SettingService).getSettingsFieldsLR()
    this.context.commit('SET_SETTINGS_FIELDS_LR', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsFieldsEmail () {
    const response = await Container.get(SettingService).getSettingsFieldsEmail()
    this.context.commit('SET_SETTINGS_FIELDS_EMAIL', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSettingsFieldsTrack () {
    const response = await Container.get(SettingService).getSettingsFieldsTrack()
    this.context.commit('SET_SETTINGS_FIELDS_TRACK', response.data)
    return response
  }

  @Action({ rawError: true })
  async saveSettings (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SettingService).saveSettings(event_uuid, data)
    this.context.dispatch('getSettings', { event_uuid })
    const changelogData = {
      event_uuid,
      type: 'settings',
      subtype: 'default/all?no_data=true'
    }
    this.context.dispatch('event/getChangelog', changelogData, { root: true })
    return response
  }

  @Action({ rawError: true })
  async saveSettingsLR (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SettingService).saveSettingsLR(event_uuid, data)
    this.context.dispatch('getSettingsLR', { event_uuid })
    const changelogData = {
      event_uuid,
      type: 'lr_settings',
      subtype: 'default/all?no_data=true'
    }
    this.context.dispatch('event/getChangelog', changelogData, { root: true })
    return response
  }

  @Action({ rawError: true })
  async saveSettingsEmail (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SettingService).saveSettingsEmail(event_uuid, data)
    this.context.dispatch('getSettingsEmail', { event_uuid })
    const changelogData = {
      event_uuid,
      type: 'email_settings',
      subtype: 'default/all?no_data=true'
    }
    this.context.dispatch('event/getChangelog', changelogData, { root: true })
    return response
  }

  @Action({ rawError: true })
  async saveSettingsEvals (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SettingService).saveSettingsEvals(event_uuid, data)
    this.context.dispatch('getSettingsEvals', { event_uuid })
    const changelogData = {
      event_uuid,
      type: 'evals_settings',
      subtype: 'default/all?no_data=true'
    }
    this.context.dispatch('event/getChangelog', changelogData, { root: true })
    return response
  }

  @Action({ rawError: true })
  async saveSettingsTrack (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(SettingService).saveSettingsTrack(event_uuid, data)
    this.context.dispatch('getSettingsTrack', { event_uuid })
    const changelogData = {
      event_uuid,
      type: 'track_settings',
      subtype: 'default/all?no_data=true'
    }
    this.context.dispatch('event/getChangelog', changelogData, { root: true })
    return response
  }
}
