import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'

@Service()
export default class CertificatesService {
  public async fetchCertificates (event_uuid: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/certificates`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchCertificateTemplates () {
    const httpParams: IHttpClientGetParameters = {
      url: '/certificate-templates',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createCertificate (event_uuid: any, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/surveys/certificates`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateCertificate (event_uuid: any, certificate_uuid: any, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/surveys/certificates/${certificate_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteCertificate (event_uuid: any, certificate_uuid: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/surveys/certificates/${certificate_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
