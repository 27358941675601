import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import DesignService from '../services/design.service'

@Module({
  namespaced: true
})
export default class DesignStore extends VuexModule {
  design: any[] = []
  designFields: any = {}
  design_templates: any[] = []

    @Mutation
  SET_DESIGN_TEMPLATES (payload: any) {
    this.design_templates = payload
  }

    @Mutation
    SET_DESIGN (payload: any) {
      this.design = payload
    }

    @Mutation
    SET_DESIGN_FIELDS (payload: any) {
      this.designFields = payload
    }

    @Action({ rawError: true })
    async getDesignTemplates () {
      const response = await Container.get(DesignService).getDesignTemplates()
      this.context.commit('SET_DESIGN_TEMPLATES', response.data.ta)
      return response
    }

    @Action({ rawError: true })
    async getDesign (payload: any) {
      const { event_uuid } = payload
      let response: any = null
      let design = {
        page_data: {}
      }
      try {
        response = await Container.get(DesignService).getDesign(event_uuid)
        if (response.data.page_data.length === 0) {
          // TODO
        } else {
          design = response.data
        }
      } catch (error) {
        // Container.get(ErrorHandlerService).error(error)
      }
      this.context.commit('SET_DESIGN', design)
      return response
    }

    @Action({ rawError: true })
    async getLRDesign (payload: any) {
      const { event_uuid } = payload
      let response: any = null
      let design = {
        page_data: {}
      }
      try {
        response = await Container.get(DesignService).getLRDesign(event_uuid)
        if (response.data.page_data.length === 0) {
          // TODO
        } else {
          design = response.data
        }
      } catch (error) {
        // Container.get(ErrorHandlerService).error(error)
      }
      this.context.commit('SET_DESIGN', design)
      return response
    }

    @Action({ rawError: true })
    async getEvalsDesign (payload: any) {
      const { event_uuid } = payload
      let response: any = null
      let design = {
        page_data: {}
      }
      try {
        response = await Container.get(DesignService).getEvalsDesign(event_uuid)
        if (response.data.page_data.length === 0) {
          // TODO
        } else {
          design = response.data
        }
      } catch (error) {
        // Container.get(ErrorHandlerService).error(error)
      }
      this.context.commit('SET_DESIGN', design)
      return response
    }

    @Action({ rawError: true })
    async getSettingsByRevisionUuid (payload: any) {
      const response: any = await Container.get(DesignService).getSettingsByRevisionUuid(payload.event_uuid, payload.revision_uuid)
      const settings = response.data
      this.context.commit('SET_DESIGN', settings)
      return response
    }

    @Action({ rawError: true })
    async getDesignFields () {
      const response = await Container.get(DesignService).getDesignFields()
      this.context.commit('SET_DESIGN_FIELDS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getLRDesignFields () {
      const response = await Container.get(DesignService).getLRDesignFields()
      this.context.commit('SET_DESIGN_FIELDS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getEvalsDesignFields () {
      const response = await Container.get(DesignService).getEvalsDesignFields()
      this.context.commit('SET_DESIGN_FIELDS', response.data)
      return response
    }

    @Action({ rawError: true })
    async saveDesign (payload: any) {
      const { event_uuid, data } = payload
      const response = await Container.get(DesignService).saveDesign(event_uuid, data)
      this.context.dispatch('getDesign', payload)
      const changelogData = {
        event_uuid,
        type: 'design',
        subtype: 'default/all?no_data=true'
      }
      this.context.dispatch('event/getChangelog', changelogData, { root: true })
      return response
    }

    @Action({ rawError: true })
    async saveLRDesign (payload: any) {
      const { event_uuid, data } = payload
      const response = await Container.get(DesignService).saveLRDesign(event_uuid, data)
      this.context.dispatch('getLRDesign', payload)
      const changelogData = {
        event_uuid,
        type: 'lr_design',
        subtype: 'default/all?no_data=true'
      }
      this.context.dispatch('event/getChangelog', changelogData, { root: true })
      return response
    }

    @Action({ rawError: true })
    async saveEvalsDesign (payload: any) {
      const { event_uuid, data } = payload
      const response = await Container.get(DesignService).saveEvalsDesign(event_uuid, data)
      this.context.dispatch('getEvalsDesign', payload)
      const changelogData = {
        event_uuid,
        type: 'evals_design',
        subtype: 'default/all?no_data=true'
      }
      this.context.dispatch('event/getChangelog', changelogData, { root: true })
      return response
    }
}
