import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import CompanyUser from '../models/company-user.model'
import CompanyService from '../services/company/company.service'
import UserService from '../services/company/user.service'
import FileUploadService from '../services/file-upload.service'
import ReportService from '@/modules/level-two/services/report.service'
import { CompanyReportsChartDataTypesEnum } from '@/modules/level-one/enums/company-reports-chart-data-types.enum'

@Module({
  namespaced: true
})
export default class CompanyStore extends VuexModule {
  company: any = null
  company_stats: any = null
  syncs: any = []
  companies: any [] = []
  companyBlacklists: any = []
	companyBlacklist: any = []
  printers: any [] = []
  printer: any [] = []
  user_groups: any [] = []
  user_group: any = null
  permissions: any = null
  user_group_members: any = null
  attendee_global_search: any [] = []
  event_global_search: any [] = []
  companyLeaderboards: any = null
  companyPerformanceStats: any = null
  companyPerformanceChartData: any = null
  companyAttendeeSearch: string | null = null

  @Mutation
  SET_COMPANY (payload: any) {
	  this.company = payload
  }

  @Mutation
  SET_COMPANY_STATS (payload: any) {
	  this.company_stats = payload
  }

  @Mutation
  SET_COMPANY_LEADERBOARDS (payload: any) {
    this.companyLeaderboards = payload
  }

  @Mutation
  SET_COMPANY_PERFORMANCE_STATS (payload: any) {
    this.companyPerformanceStats = payload
  }

  @Mutation
  SET_COMPANY_PERFORMANCE_CHART_DATA (payload: any) {
    this.companyPerformanceChartData = payload
  }

  @Mutation
  SET_COMPANIES (payload: any) {
    this.companies = payload
  }

  @Mutation
  SET_SYNCS (payload: any) {
    this.syncs = payload
  }

  @Mutation
  SET_PRINTERS (payload: any) {
    this.printers = payload
  }

  @Mutation
  SET_PRINTER (payload: any) {
    this.printer = payload
  }

  @Mutation
  SET_USER_GROUPS (payload: any) {
    this.user_groups = payload
  }

  @Mutation
  SET_USER_GROUP (payload: any) {
    this.user_group = payload
  }

  @Mutation
  SET_USER_GROUP_MEMBERS (payload: any) {
    this.user_group_members = payload
  }

  @Mutation
  SET_COMPANY_BLACKLISTS (payload: any) {
    this.companyBlacklists = payload
  }

  @Mutation
  SET_COMPANY_BLACKLIST (payload: any) {
    this.companyBlacklist = payload
  }

  @Mutation
  SET_PERMISSIONS (payload: any) {
    this.permissions = payload
  }

  @Mutation
  SET_COMPANY_ATTENDEE_SEARCH (payload: any) {
    this.companyAttendeeSearch = payload
  }

  @Mutation
  SET_ATTENDEE_GLOBAL_SEARCH (payload: any) {
    this.attendee_global_search = payload
  }

  @Mutation
  SET_EVENT_GLOBAL_SEARCH (payload: any) {
    this.event_global_search = payload
  }

  @Action({ rawError: true })
  async fetchCompanies () {
    const response: any = await Container.get(CompanyService).getCompanies()
    this.context.commit('SET_COMPANIES', response.data.data)
    return response
  }

  @Action({ rawError: true })
  async fetchCompanySyncs (company_uuid: string) {
    const response: any = await Container.get(CompanyService).getCompanySyncs(company_uuid)
    this.context.commit('SET_SYNCS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchUserGroups () {
    const response: any = await Container.get(CompanyService).getUserGroups()
    this.context.commit('SET_USER_GROUPS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchPermissions () {
    const response: any = await Container.get(CompanyService).getPermissions()
    this.context.commit('SET_PERMISSIONS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchUserGroup (payload: string) {
    const response: any = await Container.get(CompanyService).getUserGroup(payload)
    this.context.commit('SET_USER_GROUP', response.data)
    return response
  }

  @Action({ rawError: true })
  async updateUserGroup (payload: any) {
    const { user_group_uuid, data } = payload
    return await Container.get(CompanyService).updateUserGroup(user_group_uuid, data)
  }

  @Action({ rawError: true })
  async fetchUserGroupMembers (payload: string) {
    const response: any = await Container.get(CompanyService).getUserGroupMembers(payload)
    this.context.commit('SET_USER_GROUP_MEMBERS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchPrinters () {
    const response: any = await Container.get(CompanyService).getPrinters()
    this.context.commit('SET_PRINTERS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchCompanyPrinters (payload: string) {
    const response: any = await Container.get(CompanyService).getCompanyPrinters(payload)
    this.context.commit('SET_PRINTERS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchPrinter (payload: any) {
    const response: any = await Container.get(CompanyService).getPrinter(payload)
    this.context.commit('SET_PRINTER', response.data)
    return response
  }

  @Action({ rawError: true })
  async deletePrinter ({ printer_uuid }: { printer_uuid: string }) {
    const response: any = await Container.get(CompanyService).deletePrinter(printer_uuid)
    await this.context.dispatch('fetchPrinters')
    return response
  }

  @Action({ rawError: true })
  async registerCompany (payload: any) {
    if (payload.logo) {
      const vaporResponse = await Container.get(FileUploadService).uploadFile(payload.logo)
      payload.logo = vaporResponse
    }
    const response = await Container.get(CompanyService).register(payload)
    return response?.data
  }

  @Action({ rawError: true })
  async fetchCompany (payload: any) {
    const response = await Container.get(CompanyService).getCompany(payload)
    this.context.commit('SET_COMPANY', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchCompanyStats (payload: any) {
    const response = await Container.get(CompanyService).getCompanyStats(payload)
    this.context.commit('SET_COMPANY_STATS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async saveMachine (payload: any) {
    const { uuid, data } = payload
    const response = await Container.get(CompanyService).saveMachine(uuid, data)
    await this.context.dispatch('fetchPrinter', uuid)
    return response
  }

  @Action({ rawError: true })
  async editCompany (payload: any) {
    const { uuid, data } = payload
    const response = await Container.get(CompanyService).updateCompany(uuid, data)
    await this.context.dispatch('fetchCompany', uuid)
    return response
  }

  @Action({ rawError: true })
  async deleteCompany (payload: any) {
    const { uuid } = payload
    const response = await Container.get(CompanyService).deleteCompany(uuid)
    await this.context.dispatch('fetchCompanies')
    return response
  }

  @Action({ rawError: true })
  async addUser (payload: CompanyUser) {
    const response = await Container.get(UserService).addUser(payload)
    return response.data
  }

  @Action({ rawError: true })
  async updateUser (payload: CompanyUser) {
    const response = await Container.get(UserService).updateUser(payload)
    return response.data
  }

  @Action({ rawError: true })
  async removeUser (payload: string) {
    const response = await Container.get(UserService).removeUser(payload)
    return response.data
  }

  @Action({ rawError: true })
  async getCompanyBlacklists (payload: any) {
    const { company_uuid } = payload
    const response = await Container.get(CompanyService).getCompanyBlacklists(company_uuid)
    this.context.commit('SET_COMPANY_BLACKLISTS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getCompanyBlacklist (payload: any) {
    const { company_uuid, blacklist_uuid } = payload
    const response = await Container.get(CompanyService).getCompanyBlacklist(company_uuid, blacklist_uuid)
    this.context.commit('SET_COMPANY_BLACKLIST', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async updateBlacklist (payload: any) {
    const { company_uuid, blacklist_uuid, data } = payload
    const response = await Container.get(CompanyService).updateBlacklist(company_uuid, blacklist_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async saveBlacklist (payload: any) {
    const { company_uuid, data } = payload
    const response = await Container.get(CompanyService).saveBlacklist(company_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async createUserGroup (payload: any) {
    const { data } = payload
    const response = await Container.get(CompanyService).createUserGroup(data)
    await this.context.dispatch('fetchUserGroups')
    return response.data
  }

  @Action({ rawError: true })
  async editUserGroup (payload: any) {
    const { uuid, data } = payload
    const response = await Container.get(CompanyService).editUserGroup(uuid, data)
    await this.context.dispatch('fetchUserGroups')
    return response.data
  }

  @Action({ rawError: true })
  async saveUserGroupPermissions (payload: any) {
    const { uuid, permissions } = payload
    const response = await Container.get(CompanyService).saveUserGroupPermissions(uuid, permissions)
    await this.context.dispatch('fetchUserGroup', uuid)
    return response.data
  }

  @Action({ rawError: true })
  async saveAttendeeGlobalSearch (payload: any) {
    const response = await Container.get(CompanyService).saveAttendeeGlobalSearch(payload)
    const uuid = response.data.uuid
    await this.context.dispatch('fetchAttendeeGlobalSearch', uuid)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeeGlobalSearch (uuid: string) {
    const response = await Container.get(CompanyService).getAttendeeGlobalSearch(uuid)
    if (response.data.status !== 'FINISHED') {
      setTimeout(() => {
        this.context.dispatch('fetchAttendeeGlobalSearch', uuid)
      }, 2000)
    }
    if (response.data.status === 'FINISHED') {
      this.context.commit('SET_ATTENDEE_GLOBAL_SEARCH', response.data.results)
    }
    return response.data
  }

  @Action({ rawError: true })
  async saveEventGlobalSearch (payload: any) {
    const response = await Container.get(CompanyService).saveEventGlobalSearch(payload)
    this.context.commit('SET_EVENT_GLOBAL_SEARCH', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchCompanyLeaderboards (payload: string) {
    const response = await Container.get(CompanyService).getCompanyLeaderboards(payload)
    this.context.commit('SET_COMPANY_LEADERBOARDS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchCompanyPerformanceStats (payload: any) {
    const { uuid, daysBack } = payload
    const response = await Container.get(CompanyService).getCompanyPerformanceStats(uuid, daysBack)
    this.context.commit('SET_COMPANY_PERFORMANCE_STATS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getCompanyPerformanceChartData (payload: { uuid: string; daysBack: number; items: CompanyReportsChartDataTypesEnum[] }) {
    const { uuid, daysBack, items } = payload
    const response = await Container.get(CompanyService).getCompanyPerformanceChartData(uuid, daysBack, items)
    this.context.commit('SET_COMPANY_PERFORMANCE_CHART_DATA', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async exportCompanyPerformanceLeaderboard (payload: { uuid: string; type: string; limit: number|string }) {
    const { uuid, type, limit = 10 } = payload
    const response: any = await Container.get(CompanyService).exportCompanyPerformanceLeaderboard(uuid, type, limit)
    location.href = response.data
  }

  @Action({ rawError: true })
  async saveCompanyAttendeeSearch (payload: { companyUuid: string; criteria: any }) {
    const companyUuid = payload.companyUuid
    delete payload.companyUuid
    const response = await Container.get(CompanyService).saveCompanyAttendeeSearch(companyUuid, payload)
    const uuid = response.data.uuid
    await this.context.dispatch('fetchCompanyAttendeeSearch', { companyUuid: companyUuid, uuid: uuid })
    return response.data
  }

  @Action({ rawError: true })
  async fetchCompanyAttendeeSearch (payload: { companyUuid: string; uuid: string }) {
    const { companyUuid, uuid } = payload
    const response = await Container.get(CompanyService).getCompanyAttendeeSearch(companyUuid, uuid)
    if (response.data.status !== 'FINISHED') {
      setTimeout(() => {
        this.context.dispatch('fetchCompanyAttendeeSearch', payload)
      }, 2000)
    }
    if (response.data.status === 'FINISHED') {
      this.context.commit('SET_COMPANY_ATTENDEE_SEARCH', response.data.results)
    }
    return response.data
  }
}
