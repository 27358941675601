import { Bar } from 'vue-chartjs'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class GtrBarChart extends Mixins(Bar) {
    @Prop({ required: true, type: Object, default: null })
    data

    @Prop({ required: true, type: Object, default: null })
    options

    mounted () {
      this.renderChart(this.data, this.options)
    }
}
