import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'
import IHttpClientPutParameters from '@/modules/common/contracts/http-client/http-client-put-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'

@Service()
export default class LeadsService {
  public async fetchLeads (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/get-lead-counts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchAttendeeLeads (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/get-attendee-lead-counts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addLead (event_uuid: string, participant_uuid: string, payload: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/lead`,
      requireToken: true,
      payload
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async getLeaderboard (event_uuid: string, file_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-leaderboard?file_type=${file_type}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async fetchLeadsStats (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/stats`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchCheckinCheckoutData (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checkin-checkout/data`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchExhibitorStats (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/exhibitor-stats`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getLead (event_uuid: string, lead_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/${lead_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportAttendeeBoothScans (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-booth-scans`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addBulkLeadsActivationCode (event_uuid: string, participant_uuids: string[], data: any) {
    const { cap } = data
    const payload: any = {
      application: 'Leads',
      cap,
      participant_uuids
    }
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/bulk-app-codes`,
      requireToken: true,
      payload
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async getExportLeadsForExhibitor (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/leads/export`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async exportActivationCodesByExhibitor (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/app-codes/export`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportAllLeadsSpreadSheets (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-all-leads-one-spreadsheet`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportAllLeadsCounts (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-lead-counts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportLeadsAsExcelFile (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-all-leads-one-spreadsheet`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportAllLeadsCountsByAttendee (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/export-reverse-lead-counts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getScansByParticipant (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/leads`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportScansByParticipant (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/leads/export`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getQualifiersByParticipant (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/qualifiers`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response?.data
  }

  public async fetchSettings (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/setup/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchDesignSettings (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/lr_design/default`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchSettingsMetadata () {
    const httpParams: IHttpClientGetParameters = {
      url: '/orders_settings',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateSettings (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/orders_settings/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async resolveLead (event_uuid: string, unknown_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/leads/unknown/${unknown_uuid}`,
      requireToken: true,
      payload: data
    }
    return await Container.get(HttpClient).patch(httpParams)
  }

  public async updateDesignSettings (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/lr_design/default`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getLeadsAttendee (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/reverse-leads`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getLeadScans (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/stats`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchCheckoutDevices (event_uuid: string, device_status: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants?filter[onsite_device_status]=${device_status}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchCheckinCheckoutSettings (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checkin-checkout/settings`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateCheckinCheckoutSettings (event_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/checkin-checkout/settings`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateDeviceStatus (event_uuid: string, participant_uuid: string, checkout_uuid: string, data: any) {
    const httpParams: IHttpClientPutParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/checkout/${checkout_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).put(httpParams)
    return response
  }

  public async fetchAllParticipants (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deviceCheckout (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/checkout`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getExportDevicesByEvent (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checkin-report`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportAll (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/exhibitor-stats/export`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getExportDevice (event_uuid: string, device: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/leads/exhibitor-stats/export?device=${device}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async sendSMSCheckingReminder (event_uuid: string, message: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/checkin-reminders`,
      requireToken: true,
      payload: {
        message: message
      }
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getChangelog (event_uuid: any, type: any, sub_type: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type !== null ? sub_type : ''}/all?no_data=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getUUID (event_uuid: any, type: any, sub_type: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSettingsByUuid (event_uuid: string, settings_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/uuid/${settings_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
