import { Line } from 'vue-chartjs'
import { Prop, Component, Mixins } from 'vue-property-decorator'

@Component
export default class GtrLineChart extends Mixins(Line) {
    @Prop({ required: false, type: Object, default: null })
    data

    @Prop({ required: false, type: Object, default: null })
    options

    mounted () {
      if (this.data !== null && this.options !== null) {
        this.renderChart(this.data, this.options)
      }
    }
}
