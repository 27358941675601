import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import Container, { Service } from 'typedi'
import IHttpClientGetParameters from '../contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '../contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '../contracts/http-client/http-client-post-parameters.interface'
import IHttpClientPutParameters from '../contracts/http-client/http-client-put-parameters.interface'
import IHttpClient from '../contracts/http-client/http-client.interface'
import SecurityContext from './security-context.service'
// import { cacheAdapterEnhancer } from 'axios-extensions'

@Service()
export default class HttpClient implements IHttpClient {
  public async get<T> (params: IHttpClientGetParameters): Promise<AxiosResponse<T>> {
    const { url, config, requireToken } = params
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        url: window.location.href
      }
    }
    if (requireToken) {
      const token: string | undefined = this.getToken()
      if (token) {
        this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
      }
    } else {
      delete this.getInstance().defaults.headers.common.Authorization
    }
    if (config) {
      Object.assign(axiosConfig, config)
    }
    return this.httpInstance().get<T>(url, axiosConfig)
  }

  public async post<T> (params: IHttpClientPostParameters): Promise<AxiosResponse<T>> {
    const { url, config, payload, requireToken } = params
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        url: window.location.href
      }
    }
    if (requireToken) {
      const token: string | undefined = this.getToken()
      if (token) {
        this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
      }
    } else {
      delete this.getInstance().defaults.headers.common.Authorization
    }
    if (config) {
      Object.assign(axiosConfig, config)
    }
    return this.httpInstance().post(url, payload, axiosConfig)
  }

  public async put<T> (params: IHttpClientPutParameters): Promise<AxiosResponse<T>> {
    const { url, config, payload, requireToken } = params
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        url: window.location.href
      }
    }
    if (requireToken) {
      const token: string | undefined = this.getToken()
      if (token) {
        this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
      }
    } else {
      delete this.getInstance().defaults.headers.common.Authorization
    }
    if (config) {
      Object.assign(axiosConfig, config)
    }
    return this.httpInstance().put<T>(url, payload, axiosConfig)
  }

  public async patch<T> (params: IHttpClientPatchParameters): Promise<AxiosResponse<T>> {
    const { url, config, payload, requireToken } = params
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        url: window.location.href
      }
    }
    if (requireToken) {
      const token: string | undefined = this.getToken()
      if (token) {
        this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
      }
    } else {
      delete this.getInstance().defaults.headers.common.Authorization
    }
    if (config) {
      Object.assign(axiosConfig, config)
    }
    return this.httpInstance().patch<T>(url, payload, axiosConfig)
  }

  public async delete<T> (params: IHttpClientPostParameters): Promise<AxiosResponse<T>> {
    const { url, config, requireToken } = params
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        url: window.location.href
      }
    }
    if (requireToken) {
      const token: string | undefined = this.getToken()
      if (token) {
        this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
      }
    } else {
      delete this.getInstance().defaults.headers.common.Authorization
    }
    if (config) {
      Object.assign(axiosConfig, config)
    }
    return this.httpInstance().delete<T>(url, axiosConfig)
  }

  public async request<T> (url: string): Promise<AxiosResponse<T>> {
    const token: string | undefined = this.getToken()
    if (token) {
      this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`
    }
    return this.httpInstance().request({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
  }

  private static http: AxiosInstance;

  private httpInstance (): AxiosInstance {
    if (!HttpClient.http) {
      HttpClient.http = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          environment: 'dev'
        }
        // adapter: cacheAdapterEnhancer(axios.defaults.adapter!)
      })
    }
    return HttpClient.http
  }

  public getInstance (): AxiosInstance {
    return this.httpInstance()
  }

  private getToken () {
    return Container.get(SecurityContext).token()
  }
}
