import Container from 'typedi'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import Loader from '../models/loader.model'
import ChangeLogService from '../services/changelog.service'
import SecurityService from '../services/security.service'

@Module({
  namespaced: true
})
export default class CommonStore extends VuexModule {
  loader: Loader | undefined = {
    size: 64,
    index: 10,
    value: false
  };

  /**
   * flag in the common store to change position of components.
   */
  noticeDisplayed = false;
  noticeData: Record<string, any> = {};

  version = '0.0.0'
  latestChangelog = ''

  get getLoader (): Loader | undefined {
    return this.loader
  }

  @Mutation
  SET_LOADER (payload: Loader) {
    this.loader = payload
  }

  @Mutation
  SET_LATEST_CHANGE_LOG (payload: any) {
    this.latestChangelog = payload.body
    this.version = payload.tag_name
  }

  /**
   * Store mutation to turn off the notice.
   */
  @Mutation
  TURN_OFF_NOTICE (): void {
    this.noticeDisplayed = false
  }

  @Mutation
  SET_NOTICE_DATA (payload: any): void {
    this.noticeData = payload
  }

  @Mutation
  SET_NOTICE_VISIBILITY (payload: boolean): void {
    this.noticeDisplayed = payload
  }

  /**
   * Closes the notice at the top of the page.
   */
  @Action
  closeNotice (): void {
    this.context.commit('TURN_OFF_NOTICE')
  }

  @Action
  showLoader (payload: Loader) {
    this.context.commit('SET_LOADER', payload)
  }

  @Action
  hideLoader () {
    this.context.commit('SET_LOADER', {
      size: 64,
      index: 10,
      value: false
    })
  }

  @Action({ rawError: true })
  async getLatestChangelog () {
    const response = await Container.get(ChangeLogService).getLatestChangeLog()
    this.context.commit('SET_LATEST_CHANGE_LOG', response.data)
    return response
  }

  /**
   * Gets the notice data.
   *
   * @return     {Promise<void>}  The notice data.
   */
  @Action({ rawError: true })
  async getSystemAlert (): Promise<void> {
    const response = await Container.get(SecurityService).getSystemAlert()
    if (response.data?.length) {
      const activeNotice = response.data.find(notice => notice.active)
      if (activeNotice) {
        this.context.commit('SET_NOTICE_DATA', activeNotice)
        this.context.commit('SET_NOTICE_VISIBILITY', true)
      } else {
        this.context.commit('SET_NOTICE_DATA', {})
        this.context.commit('SET_NOTICE_VISIBILITY', false)
      }
    }
  }
}
