import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Container from 'typedi'
import SpeakerService from '../services/speaker.service'

@Module({
  namespaced: true
})
export default class SpeakerStore extends VuexModule {
  Speakers: any[] = []
  Speaker: any = null
  deleted_Speakers: any[] = [];

  get getSpeakers () {
    return this.Speakers
  }

  @Mutation
  SET_SPEAKERS (payload: any) {
    this.Speakers = payload
  }

  @Mutation
  SET_SPEAKER (payload: any) {
    this.Speaker = payload
  }

  @Mutation
  SET_DELETED_SPEAKER (payload: any) {
    this.deleted_Speakers = payload
  }

  // @Action({ rawError: true })
  // async fetchSpeakers (event_uuid: string) {
  //   const response = await Container.get(SpeakerService).getSpeakers(event_uuid)
  //   this.context.commit('SET_SpeakerS', response.data)
  //   return response.data
  // }

  @Action({ rawError: true })
  async addSpeaker (payload: any) {
    const { event_uuid, session_uuid, data } = payload
    const response = await Container.get(SpeakerService).addSpeaker(event_uuid, session_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async deleteSpeaker (payload: any) {
    const { event_uuid, session_uuid, speaker_uuid } = payload
    const response = await Container.get(SpeakerService).deleteSpeaker(event_uuid, session_uuid, speaker_uuid)
    return response.data
  }

  // @Action({ rawError: true })
  // async fetchSpeaker (payload: any) {
  //   const response: any = await Container.get(SpeakerService).getSpeaker(payload)
  //   this.context.commit('SET_Speaker', response.data)
  //   return response
  // }

  // @Action({ rawError: true })
  // async fetchDeletedSpeakers (payload: any) {
  //   const response: any = await Container.get(SpeakerService).getDeletedSpeakers(payload)
  //   this.context.commit('SET_DELETED_SpeakerS', response.data)
  //   return response.data
  // }

  // @Action({ rawError: true })
  // async deleteSpeakers (payload: any) {
  //   const response = await Container.get(SpeakerService).deleteSpeakers(payload)
  //   return response.data
  // }

  // @Action({ rawError: true })
  // async searchSpeakers (payload: any) {
  //   const response: any = await Container.get(SpeakerService).searchSpeakers(payload)
  //   return response.data
  // }
}
