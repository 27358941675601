import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrCardInner'
})
export default class GtrCardInner extends Vue {
    @Prop({ required: false, type: Number })
    height: number|undefined

    @Prop({ required: false, type: Boolean, default: false })
    title: boolean|undefined

    @Prop({ required: false, type: Boolean, default: false })
    actions: boolean|undefined
}
