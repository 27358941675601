import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from 'vee-validate'
import {
  required,
  email,
  regex,
  alpha_dash,
  min,
  max,
  integer,
  min_value,
  max_value,
  confirmed
} from 'vee-validate/dist/rules'
import moment from 'moment'

extend('is_not_negative_number', {
  validate (value) {
    return value >= 0
  },
  message: 'This field must not be a negative number'
})

extend('is_positive_float', {
  validate (value) {
    return value > 0
  },
  message: 'This field must be greater than 0'
})

extend('is_positive_number', {
  validate (value) {
    return value >= 1
  },
  message: 'This field must be a positive number'
})

extend('contains_uppercase', {
  validate (value) {
    return /[A-Z]/.test(value)
  },
  message: 'Password must contain 1 uppercase character'
})

extend('contains_lowercase', {
  validate (value) {
    return /[a-z]/.test(value)
  },
  message: 'Password must contain 1 lowercase character'
})

extend('contains_number', {
  validate (value) {
    return /[0-9]/.test(value)
  },
  message: 'Password must contain 1 number'
})

extend('date_greater_than_or_equals_now', {
  validate (value) {
    return moment(value).isSameOrAfter(moment().utc().format('YYYY-MM-DD'))
  },
  message: 'This date must be equals or after today'
})

extend('date_greater_before_or_equals_now', {
  validate (value) {
    return moment(value).isSameOrBefore(moment().utc().format('YYYY-MM-DD'))
  },
  message: 'This date must be equals or before today'
})

extend('datetime_greater_than_or_equals_now', {
  validate (value) {
    return moment(value).isSameOrAfter(moment().utc())
  },
  message: 'This date time must be equals or after today'
})

extend('isBeforeDate', {
  params: ['date'],
  validate (value, { date }: Record<string, any>) {
    if (date) {
      return moment(value).isBefore(moment(date).utc())
    } else {
      return true
    }
  },
  message: 'This date must be less than {_field_}'
})

extend('isSameOrBeforeDate', {
  params: ['date'],
  validate (value, { date }: Record<string, any>) {
    if (date) {
      return moment(value).isSameOrBefore(moment(date).utc())
    } else {
      return true
    }
  },
  message: (fieldName, placeholders) => {
    return `${fieldName} must be less than or equal to ${moment(placeholders._date_).format('MM-DD-YYYY')}`
  }
})

extend('isAfterDate', {
  params: ['date'],
  validate (value, { date }: Record<string, any>) {
    if (date) {
      return moment(value).isAfter(moment(date).utc())
    } else {
      return true
    }
  },
  message: 'This date must be greater than {_field_}'
})

extend('isSameOrAfterDate', {
  params: ['date'],
  validate (value, { date }: Record<string, any>) {
    if (date) {
      return moment(value).isSameOrAfter(moment(date).utc())
    } else {
      return true
    }
  },
  message: (fieldName, placeholders) => {
    return `${fieldName} must be greater than or equal to ${moment(placeholders._date_).format('MM-DD-YYYY')}`
  }
})

extend('required', {
  ...required,
  message: '{_field_} is required'
})

extend('integer', {
  ...integer,
  message: 'Please enter an integer'
})

extend('email', {
  ...email,
  message: 'Please enter a valid email'
})

extend('regex', regex)

extend('min', {
  ...min,
  message: 'Please enter at least {length} characters'
})

extend('alpha_dash', {
  ...alpha_dash,
  message:
    'Please enter only alphabetic characters, numbers, dashes or underscores'
})

extend('confirmed', {
  ...confirmed,
  message: 'These fields do not match'
})

extend('max', {
  ...max,
  message: 'Please do not enter more than {length} characters'
})

extend('min_digits', {
  ...min,
  message: 'Please enter at least {length} digits'
})

extend('max_digits', {
  ...max,
  message: 'Please do not enter more than {length} digits'
})

extend('url', {
  validate (value) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(value)
  },
  message: 'Please enter a valid url.'
})

extend('phone_number', {
  validate (value) {
    const pattern = new RegExp(/^\d*$/, 'i')
    return pattern.test(value)
  },
  message: 'Please enter only numbers.'
})

extend('image', {
  validate (value, params) {
    return params.includes(value.type)
  },
  message: 'Please select a valid image.'
})

extend('image_size_2mg', {
  validate (value) {
    return value.size < 2000000
  },
  message: 'Image size should be less than 2 MB.'
})

setInteractionMode('eagerTextCustomRule', () => {
  return {
    on: ['change', 'blur', 'input']
  }
})

extend('isValidColorFormat', {
  message: 'The colour must have a right format. example "#000" or "#000000" or "#00000000"',
  validate: value => {
    const colourFormatRegExp = new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3}|[a-fA-F0-9]{8})$')
    return colourFormatRegExp.test(value + '')
  }
})

extend('min_value', {
  ...min_value,
  message: 'Value must be a number greater than or equal to {min}'
})

extend('max_value', {
  ...max_value,
  message: 'Value must be a number less than or equal to {max}'
})

extend('isLowerThan', {
  validate (value1: any, value2: any) {
    let validationResult = false
    value1 > parseInt(value2[0]) ? validationResult = false : validationResult = true
    return validationResult
  },
  message: 'The value must be lower than highest'
})

extend('isGreaterThan', {
  validate (value1: any, value2: any) {
    let validationResult = false
    value1 < parseInt(value2[0]) ? validationResult = false : validationResult = true
    return validationResult
  },
  message: 'The value must be greater than the lowest'
})

extend('stripe', {
  validate: value => value,
  message: 'The {_field_} field is incomplete or invalid.'
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
