import UserService from '@/modules/common/services/company/user.service'
import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export default class UserStore extends VuexModule {
    users: any[] = []
    users_inactive: any[] = []
    user: any = null

    @Mutation
    SET_USERS (payload: any) {
      this.users = payload
    }

    @Mutation
    SET_USER (payload: any) {
      this.user = payload
    }

    @Mutation
    SET_USERS_INACTIVE (payload: any) {
      this.users_inactive = payload
    }

    @Action({ rawError: true })
    async fetchAdminUsers () {
      const response = await Container.get(UserService).fetchUsers()
      this.context.commit('SET_USERS', response.data.data)
      return response.data.data
    }

    @Action({ rawError: true })
    async fetchAdminUser (user_uuid) {
      const response = await Container.get(UserService).fetchUser(user_uuid)
      this.context.commit('SET_USER', response.data)
      return response.data.data
    }

    @Action({ rawError: true })
    async fetchAdminUsersInactive () {
      const response = await Container.get(UserService).fetchUsersInactive()
      this.context.commit('SET_USERS_INACTIVE', response.data.data)
      return response.data.data
    }

    @Action({ rawError: true })
    async createAdminResellerUser (payload: any) {
      const { data } = payload
      const response = await Container.get(UserService).createAdminResellerUser(data)
      this.context.dispatch('fetchAdminUsers')
      return response
    }

    @Action({ rawError: true })
    async updateUser (payload: any) {
      const { user_uuid, data } = payload
      const response = await Container.get(UserService).updateAdminUser(user_uuid, data)
      this.context.dispatch('fetchAdminUsers')
      return response
    }

    @Action({ rawError: true })
    async updateUserStatus (payload: any) {
      const { user_uuid, active } = payload
      const response = await Container.get(UserService).updateUserStatus(user_uuid, active)
      this.context.dispatch('fetchAdminUsers')
      return response
    }

    @Action({ rawError: true })
    async removeUser (payload: any) {
      const { user_uuid_param } = payload
      const response = await Container.get(UserService).removeAdminUser(user_uuid_param)
      this.context.dispatch('fetchAdminUsers')
      return response
    }
}
