import Container, { Service } from 'typedi'
import IHttpClientGetParameters from '../contracts/http-client/http-client-get-parameters.interface'
import HttpClient from './http-client.service'

@Service()
export default class ChangeLogService {
  public async getLatestChangeLog () {
    const httParams: IHttpClientGetParameters = {
      url: '/gtr-changelog',
      requireToken: false
    }
    const response: any = await Container.get(HttpClient).get(httParams)
    return response
  }
}
