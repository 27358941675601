import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrAdminLayout',
  computed: {
    ...mapState('common', ['noticeDisplayed'])
  }
})
export default class GtrAdminLayout extends GtrSuper {
  async mount () {
    try {
      await this.$store.dispatch('common/getSystemAlert')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
