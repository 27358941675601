import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrGlobalSolutionLayoutWithOutSideBar',
  computed: mapState('company', ['company'])
})
export default class GtrGlobalSolutionLayoutWithOutSideBar extends GtrSuper {
  async created () {
    if (this.$store.state.company.company === null) {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      this.$store.dispatch('common/hideLoader')
    }
  }

  async mounted () {
    try {
      if (!this.$store.state.security.currentUser) {
        await this.$store.dispatch('security/getCurrentUser')
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  navigateTo (route: any) {
    this.$router.push(route)
  }

  private async fetchCompany (uuid: string) {
    this.$store.dispatch('global/findCompany', uuid)
  }
}
