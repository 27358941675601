import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'GtrPasswordField'
})
export default class GtrPasswordField extends Vue {
    @Prop({ required: false, type: String, default: '' })
    value: string|undefined

    @Prop({ required: false, type: String, default: 'Password' })
    label: string|undefined

    data () {
      return {
        password: null,
        password_length: 0,
        typed: false,
        contains_lowercase: false,
        contains_number: false,
        contains_uppercase: false,
        valid_password_length: false,
        valid_password: false
	    }
    }

    get localValue () {
      return this.value
    }

    set localValue (localValue) {
      this.$emit('input', localValue)
    }

    p_len (password) {
      this.$data.password_length = password.length
      if (this.$data.password_length > 11) {
        this.$data.valid_password_length = true
      } else {
        this.$data.valid_password_length = false
      }

      if (this.$data.password_length > 0) {
        this.$data.typed = true
      } else {
        this.$data.typed = false
      }

      this.$data.contains_lowercase = /[a-z]/.test(password)
      this.$data.contains_number = /\d/.test(password)
      this.$data.contains_uppercase = /[A-Z]/.test(password)

      // Check if the password is valid
      if (this.$data.contains_lowercase === true && this.$data.contains_number === true) {
        this.$data.valid_password = false
        if (this.$data.contains_uppercase === true && this.$data.valid_password_length === true) {
	  	  this.$data.valid_password = true
        }
      } else {
        this.$data.valid_password = false
      }
    }
}
