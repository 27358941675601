import { RouteConfig } from 'vue-router'
import IBaseModule from '../common/contracts/base-module.interface'
import levelOneRoutes from './routes/level-one.router'
import adminUser from './store/admin-user.store'
export default class LevelOneModule implements IBaseModule {
  store (): object {
    return {
      adminUser
    }
  }

  routes (): RouteConfig[] {
    return [
      ...levelOneRoutes
    ]
  }
}
