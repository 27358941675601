import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import SyncsService from '../services/syncs.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'

@Module({
  namespaced: true
})
export default class SyncsStore extends VuexModule {
  syncs: any[] = []
  all_syncs: any[] = []
  active_syncs: any[] = []
  sample_sync_data = {}
  sync_history: any[] = []
  start_sync: any[] = []
  start_export: any[] = []

  get totalSyncs () {
    return this.syncs.length
  }

  @Mutation
  SET_SYNCS (payload: any) {
    this.syncs = payload
  }

  @Mutation
  SET_ALL_SYNCS (payload: any) {
    this.all_syncs = payload
  }

  @Mutation
  SET_ACTIVE_SYNCS (payload: any) {
    this.active_syncs = payload
  }

  @Mutation
  SET_SYNC_HISTORY (payload: any) {
    this.sync_history = payload
  }

  @Mutation
  SET_START_SYNC (payload: any) {
    this.start_sync = payload
  }

  @Mutation
  SET_START_EXPORT (payload: any) {
    this.start_export = payload
  }

  @Mutation
  SET_SAMPLE_SYNC_DATA (payload: any) {
    this.sample_sync_data = payload
  }

  @Action({ rawError: true })
  async getSyncs () {
    const response = await Container.get(SyncsService).getSyncs()
    this.context.commit('SET_SYNCS', response.data)
    return response
  }

  @Action({ rawError: true })
  public async getAllSyncs () {
    const response: any = await Container.get(SyncsService).getAllSyncs()
    this.context.commit('SET_ALL_SYNCS', response.data)
  }

  @Action({ rawError: true })
  async getActiveSyncs (event_uuid: string) {
    const response = await Container.get(SyncsService).getActiveSyncs(event_uuid)
    this.context.commit('SET_ACTIVE_SYNCS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getSyncHistory (payload: any) {
    const { event_uuid, sync_uuid } = payload
    const response = await Container.get(SyncsService).getSyncHistory(event_uuid, sync_uuid)
    this.context.commit('SET_SYNC_HISTORY', response.data)
    return response
  }

  @Action({ rawError: true })
  async startSync (payload: any) {
    const { event_uuid, sync_uuid } = payload
    const response = await Container.get(SyncsService).startSync(event_uuid, sync_uuid)
    this.context.commit('SET_START_SYNC', response.data)
    return response
  }

  @Action({ rawError: true })
  async startExport (payload: any) {
    const { event_uuid, sync_uuid } = payload
    const response = await Container.get(SyncsService).startExport(event_uuid, sync_uuid)
    this.context.commit('SET_START_EXPORT', response.data)
    return response
  }

  @Action({ rawError: true })
  async createSync (payload: any) {
    const { data, event_uuid } = payload
    const response = await Container.get(SyncsService).createSync(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getSampleSyncData (payload: any) {
    const { event_uuid, sync_uuid } = payload
    const response = await Container.get(SyncsService).getSampleSyncData(event_uuid, sync_uuid)
    this.context.commit('SET_SAMPLE_SYNC_DATA', response.data.data)
    return response
  }

  @Action({ rawError: true })
  async updateSync (payload: any) {
    const { data, event_uuid, sync_uuid } = payload
    const response = await Container.get(SyncsService).updateSync(event_uuid, sync_uuid, data)
    return response
  }
}
