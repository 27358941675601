import { Service } from 'typedi'

@Service()
export default class GtrStorage {
  public setItem (key: string, item: any): void {
    if (this.storageSupported()) {
      localStorage.setItem(key, item)
    } else {
      throw new Error('LocalStorage not supported.')
    }
  }

  public getItem (key: string): any {
    if (this.storageSupported()) {
      return localStorage.getItem(key)
    } else {
      throw new Error('LocalStorage not supported.')
    }
  }

  public removeItem (key: string): void {
    if (this.storageSupported()) {
      localStorage.removeItem(key)
    } else {
      throw new Error('LocalStorage not supported.')
    }
  }

  public clear (): void {
    if (this.storageSupported()) {
      localStorage.clear()
    } else {
      throw new Error('LocalStorage not supported.')
    }
  }

  private storageSupported () {
    return window.localStorage !== null && window.localStorage !== undefined
  }
}
