import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Container from 'typedi'
import ContentPagesService from '../services/contentpages.service'
import { AxiosResponse } from 'axios'

@Module({
  namespaced: true
})
export default class ContentPagesStore extends VuexModule {
    currentlyDeployedLiveUUID: any = {}
    currentDevUUID: Record<string, any> = {}
    contentpages: any[] = []
    changelog: any = {}

    @Mutation
    SET_CONTENT_PAGES (payload: Array<Record<string, any>>) {
      if (payload) {
        this.contentpages = payload
      }
    }

    @Mutation
    SET_CHANGELOG (payload: any) {
      this.changelog = payload
    }

    @Mutation
    UPDATE_CURRENT_LIVE_UUID ({ live }: { dev: Record<string, any>; live: Record<string, any> }) {
      this.currentDevUUID = {
        ...this.currentDevUUID,
        live
      }
    }

    @Mutation
    SET_CURRENT_DEV_UUID (payload: any) {
      this.currentDevUUID = payload
    }

    @Mutation
    UPDATE_CURRENT_DEV_UUID (dev: Record<string, any>) {
      this.currentDevUUID = {
        ...this.currentDevUUID,
        dev
      }
    }

    @Action({ rawError: true })
    async getContentPages ({ event_uuid, content_uuid }: {
      event_uuid: string;
      content_uuid?: string;
    }) {
      // first fetch the content pages; store them after.
      const contentpages = await Container.get(ContentPagesService).getContentPages(event_uuid)
      // store them.
      this.context.commit('SET_CONTENT_PAGES', contentpages)
      // if content uuid gets passed in, retrieve the the current dev info.
      if (content_uuid) {
        // get name of current page.
        const sub_type = Object.keys(contentpages).filter(key => contentpages[key].uuid === content_uuid)
        // fetch the associated changelog.
        await this.context.dispatch('getCurrentDevUUID', {
          type: 'page',
          sub_type,
          event_uuid
        })
      }

      return contentpages
    }

    @Action({ rawError: true })
    async createContentPage (payload: any) {
      const { data, event_uuid } = payload
      const response = await Container.get(ContentPagesService).addContentPage(data, event_uuid)
      this.context.dispatch('getContentPages', { event_uuid })
      const changelogData = { type: 'page', sub_type: data.name.toLowerCase(), event_uuid }
      this.context.dispatch('getCurrentDevUUID', changelogData)
      return response.data
    }

    @Action({ rawError: true })
    async editContentPage (payload: any) {
      const { data, event_uuid, name } = payload
      const response = await Container.get(ContentPagesService).updateContentPage(data, event_uuid, name)
      this.context.dispatch('getContentPages', { event_uuid })
      const changelogData = { type: 'page', sub_type: name, event_uuid }
      this.context.dispatch('getCurrentDevUUID', changelogData)
      return response.data
    }

    @Action({ rawError: true })
    async deleteContentPage (payload: any) {
      const { event_uuid, name } = payload
      const response: any = await Container.get(ContentPagesService).deleteContentPage(event_uuid, name)
      this.context.dispatch('getContentPages', { event_uuid })
      return response
    }

    @Action({ rawError: true })
    async getChangelog (payload: any) {
      const { event_uuid, type, sub_type } = payload
      const response = await Container.get(ContentPagesService).getChangelog(event_uuid, type, sub_type)
      this.context.commit('SET_CHANGELOG', response.data)
      return response
    }

    @Action({ rawError: true })
    async getContentpageByRevisionUuid (payload: any) {
      const { event_uuid, revision_uuid } = payload
      const response = await Container.get(ContentPagesService).getContentpageByRevisionUuid(event_uuid, revision_uuid)
      this.context.commit('UPDATE_CURRENT_DEV_UUID', response)
      return response.data
    }

    @Action({ rawError: true })
    async getCurrentlyDeployedLiveUUID (payload: any) {
      const { event_uuid, type, sub_type } = payload
      if (event_uuid) {
        const response = await Container.get(ContentPagesService).getUUID(event_uuid, type, sub_type)
        this.context.commit('UPDATE_CURRENT_LIVE_UUID', response)
        return response
      }
    }

    /**
     * get the current content uuid.
     * @param {any} payload
     */
    @Action({ rawError: true })
    async getCurrentDevUUID (payload: any) {
      const { event_uuid, type, sub_type } = payload
      if (event_uuid) {
        const response = await Container.get(ContentPagesService).getUUID(event_uuid, type, sub_type)
        this.context.commit('SET_CURRENT_DEV_UUID', response)
        await this.context.dispatch('getChangelog', payload)
      }
    }
}
