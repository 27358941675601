import IHttpClientPostParameters from '../contracts/http-client/http-client-post-parameters.interface'
import HttpClient from './http-client.service'
import Container, { Service } from 'typedi'
import IHttpClientPutParameters from '../contracts/http-client/http-client-put-parameters.interface'

@Service()
export default class FileUploadService {
  public async uploadFile (fileData: any): Promise<any> {
    const vaporData: any = {
      bucket: '',
      content_type: fileData.type,
      expires: '',
      file_name: fileData.name
    }
    let signedResponse: any = {}
    let headers: any = {}

    // #region  Vapor Request
    const httpParamsVapor: IHttpClientPostParameters = {
      url: '/vapor/signed-storage-url',
      requireToken: true,
      payload: vaporData
    }
    const responseVapor: any = await Container.get(HttpClient).post(httpParamsVapor)
    signedResponse = responseVapor.data
    signedResponse.file_name = fileData.name
    headers = responseVapor.data.headers
    delete headers.Host
    // #endregion

    // #region File Request
    const httpParamsFile: IHttpClientPutParameters = {
      url: signedResponse.url,
      requireToken: false,
      payload: fileData,
      config: {
        headers
      }
    }
    await Container.get(HttpClient).put(httpParamsFile)
    return signedResponse
    // #endregion
  }
}
