import { format, parse } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const DEFAULT_AMPM = 'AM'
const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'
const DEFAULT_TIME_FORMAT_SHORT = 'HH:mm'
const DEFAULT_HOUR = '12'
const DEFAULT_MINUTE = '00'
const DEFAULT_SECOND = '00'

@Component({
  name: 'GtrDateTimePicker'
})

export default class GtrDatetimePicker extends Vue {
   DEFAULT_AMPM = 'AM'
   DEFAULT_DATE = ''
   DEFAULT_TIME = '00:00:00'
   DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
   DEFAULT_TIME_FORMAT = 'HH:mm:ss'
   DEFAULT_DIALOG_WIDTH = 340
   DEFAULT_CLEAR_TEXT = 'CLEAR'
   DEFAULT_OK_TEXT = 'OK'
   DEFAULT_TIME_FORMAT_SHORT = 'HH:mm'

  @Prop({ type: Date })
  datetime: string|undefined

  @Prop({ required: true, type: Boolean })
  disabled: false|undefined

  @Prop({ type: Boolean })
  loading: false|undefined

  @Prop({ required: true, type: [Date, String], default: '' })
  label: string|undefined

  @Prop({ type: Number, default: DEFAULT_DIALOG_WIDTH })
  dialogWidth: number|undefined

  @Prop({ type: String, default: DEFAULT_DATE_FORMAT })
  dateFormat: string|undefined

  @Prop({ type: String, default: DEFAULT_TIME_FORMAT_SHORT })
  timeFormat: string|undefined

  @Prop({ type: String, default: DEFAULT_CLEAR_TEXT })
  clearText: string|undefined

  @Prop({ type: String, default: DEFAULT_OK_TEXT })
  okText: string|undefined

  @Prop({ required: true, type: Object })
  textFieldProps: object|undefined

  @Prop({ type: Object })
  datePickerProps: object|undefined

  @Prop({ type: Object })
  timePickerProps: object|undefined

  data () {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      hour: DEFAULT_HOUR,
      minute: DEFAULT_MINUTE,
      second: DEFAULT_SECOND,
      ampm: DEFAULT_AMPM // AM by default
    }
  }

  get time () {
    let hour = parseInt(this.$data.hour)
    if (this.$data.ampm === 'PM' && hour < 12) {
      hour += 12
    } else if (this.$data.ampm === 'AM' && hour === 12) {
      hour = 0
    }
    return `${hour < 10 ? '0' + hour : hour}:${this.$data.minute}:${this.$data.second}`
  }

  get dateTimeFormat () {
    return this.dateFormat + ' ' + this.timeFormat
  }

  get defaultDateTimeFormat () {
    return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
  }

  get formattedDatetime () {
    return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
  }

  get selectedDatetime () {
    if (this.$data.date && this.time) {
      const datetimeString = this.$data.date + ' ' + this.time
      return parse(datetimeString, this.defaultDateTimeFormat, new Date())
    } else {
      return null
    }
  }

  get dateSelected () {
    return !this.$data.date
  }

  async mounted () {
    await this.init()
  }

  // METHODS
  init () {
    // if not datetime or invalid, return.
    if (!this.datetime || this.datetime === 'Invalid date') return
    let initDateTime
    if (this.$data.datetime instanceof Date) {
      initDateTime = this.$data.datetime
    } else if (typeof this.$data.datetime === 'string' || this.$data.datetime instanceof String) {
      initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
    }
    this.$data.date = format(initDateTime, DEFAULT_DATE_FORMAT)
    this.$data.time = format(initDateTime, DEFAULT_TIME_FORMAT)
  }

  okHandler () {
    this.resetPicker()
    this.$emit('input', this.selectedDatetime)
  }

  clearHandler () {
    this.resetPicker()
    this.$data.date = DEFAULT_DATE
    this.$data.time = DEFAULT_TIME
    this.$emit('input', null)
  }

  resetPicker () {
    this.$data.display = false
    this.$data.activeTab = 0
    if (this.$refs.timer) {
      (this.$refs.timer as any).selectingHour = true
    }
  }

  limitHour ($event) {
    const input = parseInt($event.target.value, 10)
    if (isNaN(input) || input < 1 || input > 12) {
      this.$data.hour = '00'
    } else {
      this.$data.hour = input < 10 ? `0${input}` : `${input}`
    }
    // Update AM/PM based on the hour
    this.$data.ampm = input >= 12 ? 'PM' : 'AM'
  }

  limitMinute ($event) {
    const input = parseInt($event.target.value, 10)
    if (isNaN(input) || input < 0 || input > 59) {
      this.$data.minute = '00'
    } else {
      this.$data.minute = input < 10 ? `0${input}` : `${input}`
    }
  }

  toggleAmPm (amOrPm: string) {
    this.$data.ampm = amOrPm
  }

  showTimePicker () {
    this.$data.activeTab = 1
  }

  onClickOutside (event) {
    if (event.target.className === 'v-overlay__scrim') {
      this.clearHandler()
    }
  }

  // WATCHERS
  @Watch('datetime')
  refreshDate () {
    this.init()
  }
}
