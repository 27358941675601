import Container from 'typedi'
import { Vue, Component, Watch } from 'vue-property-decorator'
import ErrorHandlerService from '../../services/error-handler.service'
import Notification from '../../services/notification.service'
import { mapState } from 'vuex'

type Option = {
  text: string;
  value: string;
}
@Component({
  name: 'GtrEventCorrelator',
  computed: {
    ...mapState('event', ['events']),
    ...mapState('security', ['user'])
  }
})
export default class GtrEventCorrelator extends Vue {
  events!: Array<Record<string, any>>
  user!: Record<string, any>

  // Component state variables.
  allEventsAccess: boolean | null = null;
  loading = false
  deleting = false
  eventsToAddAccessTo: Array<Record<string, any>> = []
  eventsToRemoveAccessFrom: Array<Record<string, any>> = []
  headers = [
    { text: 'Event', align: 'start', sortable: false, value: 'text' }
  ]

  // Computed properties
  get disableRemoveAccessButton () {
    return this.loading || this.eventsToRemoveAccessFrom.length < 1
  }

  get disableAddAccessButton () {
    return this.loading || this.eventsToAddAccessTo.length < 1
  }

  get uuidsOfEventsWithAccess () {
    return this.user.admin_events.filter(({ event }) => event !== null && event.status === 'ACTIVE').map(({ event: { uuid } }) => uuid)
  }

  get userCompany () {
    return this.user.company ? this.events.find((company) => (company.uuid === this.user.company.uuid)) : null
  }

  get availableCompanyEventsWithoutAccess () {
    return this.userCompany?.events?.filter(({ uuid }) => !this.uuidsOfEventsWithAccess.includes(uuid)).map(({ name: text, uuid: value }) => ({ text, value })) ?? []
  }

  get noUserCompany (): boolean {
    return this.user.company === null
  }

  get eventsWithAccess (): Option[] {
    return this.user.admin_events.filter(({ event }) => event !== null && event.status === 'ACTIVE').map(({ event }: Record<string, any>) => ({ text: event.name, value: event.uuid })) ?? []
  }

  // lifecycle methods.
  async mounted () {
    // after first mounted, these will handle the state when the
    this.$bus.$on('close-event-access', this.resetCorrelator)
  }

  @Watch('user', { immediate: true })
  onUserChange (user: Record<string, any>) {
    if (user) {
      this.allEventsAccess = user.access_to_all_future_events
    }
  }

  // class methods.
  async handleAllEventAccess () {
    try {
      this.loading = true
      const { uuid: user_uuid } = this.user
      const data = { access_to_all_future_events: this.allEventsAccess }
      await this.$store.dispatch('security/updateUser', { user_uuid, data })
      Container.get(Notification).success('Access to all events successfully added')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * Handler for adding event access for the user.
   */
  async handleAddEventsAccess () {
    try {
      this.loading = true
      const { uuid: user_uuid } = this.user
      await this.$store.dispatch('security/addEventAccessBulk', {
        user_uuid,
        add_events: this.eventsToAddAccessTo
      })
      this.eventsToAddAccessTo = []
      Container.get(Notification).success('Access to events successfully added')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * handler for removing events from access.
   * @param payload
   * @returns
   */
  async handleRemoveEventsAccess () {
    try {
      if (!this.eventsToRemoveAccessFrom.length) return
      this.loading = true
      this.deleting = true
      const remove_events = this.eventsToRemoveAccessFrom.map((event: any) => event.value)
      await this.$store.dispatch('security/removeEventAccessBulk', { user_uuid: this.user.uuid, remove_events })
      Container.get(Notification).success('Access to events successfully removed')
      this.eventsToRemoveAccessFrom = []
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
      this.deleting = false
    }
  }

  /**
   * utility function that will reset state.
   */
  private resetCorrelator (): void {
    this.allEventsAccess = null
    this.loading = false
    this.deleting = false
    this.eventsToAddAccessTo = []
    this.eventsToRemoveAccessFrom = []
  }
}
