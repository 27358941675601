import Container, { Service } from 'typedi'
import HttpClient from './http-client.service'

@Service()
export default class FileDownloaderService {
  public async downloadFile (url: string) {
    const response = await Container.get(HttpClient).request(url)
    return response
  }
}
