import { DirectiveOptions } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { Vue } from 'vue-property-decorator'
import Container from 'typedi'
import SecurityContext from '@/modules/common/services/security-context.service'

const directive: DirectiveOptions = {
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      let roles: any[] = []
      if (typeof binding.value === 'string') {
        roles = binding.value.split(',')
      } else {
        roles = binding.value
      }

      const userLoggedInLevels: any [] = Container.get(SecurityContext).accessLevel()
      const requiredLevels: any [] = Array.isArray(roles) ? roles : [roles]
      const haveAnyLevel = requiredLevels.includes(userLoggedInLevels)
      if (!haveAnyLevel) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
    }
  }
}

Vue.directive('role', directive)
