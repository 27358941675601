import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import router from '@/bootstrap/router/router'
import store from '@/bootstrap/store/store'
import SecurityContext from '@/modules/common/services/security-context.service'
@Component({
  name: 'GtrTextEditor'
})
export default class GtrTextEditor extends Vue {
    @Prop({ required: false, type: String, default: '' })
    label: string|undefined

    @Prop({ required: false, type: Object, default: null })
    setting: object | undefined

    @Prop({ required: false, type: String, default: '' })
    value: string|undefined

    @Prop({ required: true, type: String, default: '' })
    event_uuid: string|undefined

    @Prop({ required: false, type: Boolean, default: false })
    readonly: boolean|undefined

    data () {
      return {
        loading: true,
        editor: null,
        config: {
          height: 200,
          key: 'yDC5hG4I4A10D6B5F6gKTRe1CD1PGb1DESAb1Kd1EBH1Pd1TKoD6C5G5G4G2C3H3B4A5B5',
          model: '',
          imageManagerLoadURL: `${process.env.VUE_APP_API_URL}/event/${this.event_uuid}/media?formatForFroala=1`,
          imageManagerLoadMethod: 'GET',
          fileUploadURL: `${process.env.VUE_APP_API_URL}/event/${this.event_uuid}/media?formatForFroala=1`,
          requestHeaders: {
            authorization: `Bearer ${this.getToken()}`
          },
          events: {
            initialized: this.makeReadonly(),
            'image.beforeUpload': this.uploadImage,
            'file.beforeUpload': this.uploadFile
          }
        }
      }
    }

    makeReadonly () {
      if (this.readonly) {
        return function (this: any) {
          this.edit.off()
        }
      }
      return null
    }

    private getToken () {
      return Container.get(SecurityContext).token()
    }

    get localValue () {
      return this.value
    }

    set localValue (localValue) {
      this.$emit('input', localValue)
    }

    mounted () {
      this.$data.loading = false
    }

    get mergedConfig () {
      return {
        ...this.$data.config,
        ...this.setting
      }
    }

    uploadImage (images: any) {
      this.$data.loading = true
      this.$data.editor = (this.$refs.textEditor as any)
      const editor = this.$data.editor.getEditor()
      images.forEach(async file => {
        const response = await store.dispatch('media/uploadMedia', { event_uuid: router.currentRoute.params.event_uuid, fileData: file, fetch: false })
        const url = response.responseEventMedia.data.url.replaceAll(' ', '%20')
        editor.image.insert(url, false, null, editor.image.get(), null)
      })
      this.$data.loading = false
      return false
    }

    uploadFile (files: any) {
      this.$data.loading = true
      this.$data.editor = (this.$refs.textEditor as any)
      const editor = this.$data.editor.getEditor()
      files.forEach(async file => {
        const response = await store.dispatch('media/uploadMedia', { event_uuid: router.currentRoute.params.event_uuid, fileData: file, fetch: false })
        const url = response.responseEventMedia.data.url.replaceAll(' ', '%20')
        const filename = response.responseEventMedia.data.url.substring(url.lastIndexOf('/') + 1) || 'file'
        editor.file.insert(url, filename)
      })
      this.$data.loading = false
      return false
    }
}
