import SecurityContext from '@/modules/common/services/security-context.service'
import { Container } from 'typedi'

const hasRoles = (roles: any[]|any) => {
  const userRoles: any [] = Container.get(SecurityContext).accessLevel()
  const requiredRoles: any [] = Array.isArray(roles) ? roles : [roles]
  const hasRole = requiredRoles.includes(userRoles)
  return hasRole
}

export default hasRoles
