import Vue from 'vue'
import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import FormBuilderService from '../services/formbuilder.service'
import router from '@/bootstrap/router/router'

@Module({
  namespaced: true
})
export default class FormBuilderStore extends VuexModule {
    pages: any[] = []
    unusedFields: any[] = []
    form: any[] = []
    eventFields: any[] = []
    emailerFields: any[] = []
    reportableEventFields: any[] = []
    importEventFields: any[] = []
    form_exists: any

    @Mutation
    SET_EVENT_FIELDS (payload: any) {
      this.eventFields = payload
    }

    @Mutation
    SET_EMAILER_FIELDS (payload: any) {
      this.emailerFields = payload
    }

    @Mutation
    SET_REPORTABLE_EVENT_FIELDS (payload: any) {
      this.reportableEventFields = payload
    }

    @Mutation
    SET_IMPORT_EVENT_FIELDS (payload: any) {
      this.importEventFields = payload
    }

    @Mutation
    SET_FORM_STATE (payload: any) {
      this.form = payload
    }

    @Mutation
    SET_FORM_EXISTS (payload: any) {
      this.form_exists = payload
    }

    @Action({ rawError: true })
    async getEventFields (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(FormBuilderService).getEventFields(event_uuid)
      this.context.commit('SET_EVENT_FIELDS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getEmailerFields (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(FormBuilderService).getEmailerFields(event_uuid)
      this.context.commit('SET_EMAILER_FIELDS', response.data)
      return response
    }

    @Action({ rawError: true })
    async getReportableEventFields (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(FormBuilderService).getReportableEventFields(event_uuid)
      this.context.commit('SET_REPORTABLE_EVENT_FIELDS', response.data)
      return response.data
    }

    @Action({ rawError: true })
    async getImportEventFields (payload: any) {
      const { event_uuid, table } = payload
      const response = await Container.get(FormBuilderService).getImportEventFields(event_uuid, table)
      this.context.commit('SET_IMPORT_EVENT_FIELDS', response.data)
      return response.data
    }

    @Action
    async getForm (payload: any) {
      const { event_uuid } = payload
      let defaultForm: any = {}
      let response: any = {}
      try {
        response = await Container.get(FormBuilderService).getForm(event_uuid)
        if (response.data.length === 0) {
          const defaultPageData = [
            {
              isActive: true,
              page_type: 'STANDARD',
              uuid: Vue.prototype.$uuid.v4(),
              prefix: '',
              suffix: '',
              settings: {
                name: 'Page 1',
                show_if: {
                  visible: false,
                  global_type: '',
                  type: '',
                  field: '',
                  operator: '',
                  value: '',
                  group_operator: '',
                  group_items: []
                }
              },
              fields: [
                {
                  field: 'first_name',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'First Name'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'last_name',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Last Name'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'email',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Email'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'company',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Company'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'title',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Title'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                }
              ]
            },
            {
              isActive: false,
              page_type: 'RECEIPT',
              uuid: Vue.prototype.$uuid.v4(),
              prefix: '',
              suffix: '',
              settings: {
                name: 'Receipt',
                show_if: {
                  visible: false,
                  global_type: '',
                  type: '',
                  field: '',
                  operator: '',
                  value: '',
                  group_operator: '',
                  group_items: []
                }
              },
              fields: [
                {
                  field: Vue.prototype.$uuid.v4(),
                  type: 'html',
                  required: '0',
                  label: {
                    en: 'Confirmation Verbiage'
                  },
                  html: {
                    en: 'Thank you for registering!'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                }
              ]
            }
          ]
          defaultForm = {
            page_data: defaultPageData
          }
        } else {
          defaultForm = response.data
        }
        for (let i = 0; i < defaultForm.page_data.length; i++) {
          if (i === 0) {
            defaultForm.page_data[i].isActive = true
          } else {
            defaultForm.page_data[i].isActive = false
          }
        }
        this.context.commit('SET_FORM_EXISTS', true)
      } catch (error) {
        if (error.data.error_code === 'CONTENT_NOT_FOUND') {
          this.context.commit('SET_FORM_EXISTS', false)
          const defaultPageData = [
            {
              isActive: true,
              page_type: 'STANDARD',
              uuid: Vue.prototype.$uuid.v4(),
              prefix: '',
              suffix: '',
              settings: {
                name: 'Page 1',
                show_if: {
                  visible: false,
                  global_type: '',
                  type: '',
                  field: '',
                  operator: '',
                  value: '',
                  group_operator: '',
                  group_items: []
                }
              },
              fields: [
                {
                  field: 'first_name',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'First Name'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'last_name',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Last Name'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                },
                {
                  field: 'email',
                  type: 'text',
                  required: '1',
                  label: {
                    en: 'Email'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                }
              ]
            },
            {
              isActive: false,
              page_type: 'RECEIPT',
              uuid: Vue.prototype.$uuid.v4(),
              prefix: '',
              suffix: '',
              settings: {
                name: 'Receipt',
                show_if: {
                  visible: false,
                  global_type: '',
                  type: '',
                  field: '',
                  operator: '',
                  value: '',
                  group_operator: '',
                  group_items: []
                }
              },
              fields: [
                {
                  field: Vue.prototype.$uuid.v4(),
                  type: 'html',
                  required: '0',
                  label: {
                    en: 'Confirmation Verbiage'
                  },
                  html: {
                    en: 'Thank you for registering!'
                  },
                  storage_type: 'text',
                  show_if: {
                    visible: false,
                    global_type: '',
                    type: '',
                    field: '',
                    operator: '',
                    value: '',
                    group_operator: '',
                    group_items: []
                  },
                  showMenu: false,
                  visible: true
                }
              ]
            }
          ]
          defaultForm = {
            page_data: defaultPageData
          }
          for (let i = 0; i < defaultForm.page_data.length; i++) {
            if (i === 0) {
              defaultForm.page_data[i].isActive = true
            } else {
              defaultForm.page_data[i].isActive = false
            }
          }
        }
      }
      this.context.commit('SET_FORM_STATE', defaultForm)
      return response
    }

    @Action({ rawError: true })
    async compareFormVersionsDuringPolling (payload: any) {
      const { event_uuid, type, subtype, currentDevUUID } = payload
      if (event_uuid) {
        const response = await Container.get(FormBuilderService).compareFormVersionsDuringPolling(event_uuid, type, subtype)
        if (response.data.dev && response.data.dev.uuid !== currentDevUUID && currentDevUUID !== null) {
          if (router.currentRoute.name === 'level-two.modules.registration.form') {
            alert('Please refresh your browser to pull in the latest form changes.')
          }
        }
        return response
      }
    }

    @Action({ rawError: true })
    async saveForm (payload: any) {
      let areThereAnyOtherFormChangesBeforeThis = false
      const { event_uuid, devUUIDFromFormbuilder, data } = payload
      if (event_uuid) {
        const response = await Container.get(FormBuilderService).getDefaultForm(event_uuid)
        if (response.data.dev && response.data.dev.uuid !== devUUIDFromFormbuilder) {
          areThereAnyOtherFormChangesBeforeThis = true
        }

        if (!areThereAnyOtherFormChangesBeforeThis) {
          await Container.get(FormBuilderService).saveForm(event_uuid, data)

          this.context.dispatch('getEventFields', { event_uuid })

          const changelogData = {
            event_uuid,
            type: 'form',
            subtype: 'default/all'
          }

          const deployData = {
            event_uuid,
            type: 'form',
            sub_type: 'default'
          }
          this.context.dispatch('event/getChangelog', changelogData, { root: true })
          this.context.dispatch('event/getCurrentDevUUID', deployData, { root: true })
        }
      }
    }
}
