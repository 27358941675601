import Container, { Service } from 'typedi'
import IHttpClientDeleteParameters from '../../contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '../../contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '../../contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '../../contracts/http-client/http-client-post-parameters.interface'
import CompanyUser from '../../models/company-user.model'
import HttpClient from '../http-client.service'

@Service()
export default class UserService {
  public async fetchUsers () {
    const httpParams: IHttpClientGetParameters = {
      url: 'users?active=1',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchUser (user_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `user/${user_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchUsersInactive () {
    const httpParams: IHttpClientGetParameters = {
      url: 'users?active=0',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addUser (payload: CompanyUser) {
    const httpParams: IHttpClientPostParameters = {
      url: `/company/${payload.company_uuid}/user`,
      requireToken: true,
      payload
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createAdminResellerUser (data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: '/auth/user',
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateAdminUser (user_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateUser (payload: CompanyUser) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user/${payload.uuid}`,
      requireToken: true,
      payload
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateUserStatus (user_uuid: string, active: boolean) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true,
      payload: {
        active
      }
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async removeAdminUser (user_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async removeUser (user_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
