import FileDownloaderService from '@/modules/common/services/file-download.service'
import Container from 'typedi'
import { Action, Module, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export default class InvoiceStore extends VuexModule {
  @Action({ rawError: true })
  async downloadInvoice (payload: any) {
    const { url } = payload
    const response = await Container.get(FileDownloaderService).downloadFile(url)
    return response
  }
}
