import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrHelperInstructional'
})
export default class GtrHelperInstructional extends Vue {
  @Prop({ required: true, type: Array })
  items

  data () {
    return {
      dialog: true,
      step: 1
    }
  }

  go (key: number) {
    this.$data.step = key + 1
  }
}
