import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'

@Service()
export default class PaymentService {
  public async savePaymentMethod (payment_id: string) {
    const httpParams: IHttpClientPostParameters = {
      url: '/user/me/payments/payment-method',
      requireToken: true,
      payload: {
        payment_id
      }
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async removePaymentMethod (id: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `user/me/payments/payment-method/${id}`,
      requireToken: true,
      config: {
        data: {
          id
        }
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async loadIntent () {
    const httpParams: IHttpClientGetParameters = {
      url: '/user/me/payments/setup-intent',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async voidNonWidgetTransaction (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/Freeform/void`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async postRefund ({ event_uuid, participant_uuid, data }: {
    event_uuid: string;
    participant_uuid: string;
    data: Record<string, any>;
  }): Promise<Record<string, any>> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/Freeform/refund`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getPaymentProcessor (event_uuid: string, form: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/transaction/processors?form=${form}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPaymentProcessorLR (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/transaction/processors?form=lr_orders`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPaymentProperties (event_uuid: string, widget: string, participant_uuid: string, form: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget}/properties?form=${form}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getStripeToken (event_uuid: string, widget: string, participant_uuid: string, form: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget}/token?form=${form}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getPaymentSettings (event_uuid: string, widget: string, form: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/transaction/processor/${widget}/properties` + (form ? '?form=' + form : ''),
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPaymentSettingsLR (event_uuid: string, widget: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/transaction/processor/${widget}/properties?form=lr_orders`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createNonWidgetTransaction (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/Freeform/charge`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async savePaymentSettings (event_uuid: string, widget: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/transaction/processor/${widget}/properties`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async savePaymentSettingsLR (event_uuid: string, widget: string, data: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/transaction/processor/${widget}/properties?form=lr_orders`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async voidPaymentProcessorTransaction (event_uuid: string, participant_uuid: string, widget_id: string, data: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget_id}/void`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createStripeTransaction (event_uuid: string, widget_id: string, participant_uuid: string, payment_type: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget_id}/${payment_type}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createAuthnetTransaction (event_uuid: string, widget_id: string, participant_uuid: string, payment_type: string, data: any) {
    let response: any = null
    if (payment_type === 'capture') {
      const httpParams: IHttpClientPostParameters = {
        url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget_id}/${payment_type}`,
        requireToken: true,
        payload: { transaction_id: data.transaction_id }
      }
      response = await Container.get(HttpClient).post(httpParams)
      return response
    } else {
      const httpParams: IHttpClientPostParameters = {
        url: `/event/${event_uuid}/participant/${participant_uuid}/transaction/processor/${widget_id}/${payment_type}`,
        requireToken: true,
        payload: data
      }
      response = await Container.get(HttpClient).post(httpParams)
      return response
    }
  }

  public async loadPaymentMethods () {
    const httpParams: IHttpClientGetParameters = {
      url: '/user/me/payments/payment-methods',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async setAsDefault (payment_id: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/user/me/payments/payment-method/${payment_id}`,
      requireToken: true,
      payload: {
        default: true
      }
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }
}
