import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'

@Service()
export default class PricingService {
  public async getPricingTiers (event_uuid: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/tiers`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getLrPricingTiers (event_uuid: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/tiers?type=LRORDERS`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async addPricingTier (data: any, event_uuid: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/tier`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updatePricingTier (data: any, event_uuid: string, tier_uuid: string): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/tier/${tier_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updatePricingTierBulk (data: any, event_uuid: string, type = ''): Promise<any> {
    let typeAddOn = ''
    if (type) {
      typeAddOn = `?type=${type}`
    }
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/tiers${typeAddOn}`,
      requireToken: true,
      payload: data
    }
    return await Container.get(HttpClient).patch(httpParams)
  }

  public async deletePricingTier (event_uuid: string, tier_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/tier/${tier_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
