import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import LeadService from '../services/leads.service'

@Module({
  namespaced: true
})
export default class LeadsStore extends VuexModule {
  leads: any [] = []
  attendee_lead_counts: any[] = []
  leaderboard: any [] = []
  leadsStats: any [] = []
  exhibitorStats: any [] = []
  scansByParticipant: any [] = []
  checkinCheckoutSettings: any = {}
  checkinCheckoutData: any [] = []
  ordersDesignSettings: any = {}
  ordersSettings: any = {}
  ordersSettingsMetadata: any = {}
  changelog: any = {}
  currentlyDeployedLiveUUID: any = {}
  currentDevUUID: any = {}
  designChangelog: any = {}
  currentlyDeployedLiveDesignUUID: any = {}
  currentDevDesignUUID: any = {}

  @Mutation
  SET_LEADS (payload: any) {
    this.leads = payload
  }

  @Mutation
  SET_ATTENDEE_LEADS (payload: any) {
    this.attendee_lead_counts = payload
  }

  @Mutation
  SET_LEADERBOARD (payload: any) {
    this.leaderboard = payload
  }

  @Mutation
  SET_LEADS_STATS (payload: any) {
    this.leadsStats = payload
  }

  @Mutation
  SET_EXHIBITOR_STATS (payload: any) {
    this.exhibitorStats = payload
  }

  @Mutation
  SET_SCANS_BY_PARTICIPANT (payload: any) {
    this.scansByParticipant = payload
  }

  @Mutation
  SET_ORDERS_SETTINGS (payload: any) {
    this.ordersSettings = payload
  }

  @Mutation
  SET_ORDERS_DESIGN_SETTINGS (payload: any) {
    this.ordersDesignSettings = payload
  }

  @Mutation
  SET_ORDERS_SETTINGS_METADATA (payload: any) {
    this.ordersSettingsMetadata = payload
  }

  @Mutation
  SET_CHECKIN_CHECKOUT_SETTINGS (payload: any) {
    this.checkinCheckoutSettings = payload
  }

  @Mutation
  SET_CHECKIN_CHECKOUT_DATA (payload: any) {
    this.checkinCheckoutData = payload
  }

  @Mutation
  SET_CHANGELOG (payload: any) {
    this.changelog = payload
  }

  @Mutation
  SET_CURRENT_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlyDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_DEV_UUID (payload: any) {
    this.currentDevUUID = payload
  }

  @Mutation
  SET_DESIGN_CHANGELOG (payload: any) {
    this.designChangelog = payload
  }

  @Mutation
  SET_CURRENT_DEPLOYED_LIVE_DESIGN_UUID (payload: any) {
    this.currentlyDeployedLiveDesignUUID = payload
  }

  @Mutation
  SET_CURRENT_DEV_DESIGN_UUID (payload: any) {
    this.currentDevDesignUUID = payload
  }

  @Action({ rawError: true })
  async fetchLeads (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(LeadService).fetchLeads(event_uuid)
    this.context.commit('SET_LEADS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchAttendeeLeads (payload: any): Promise<any> {
    const { event_uuid } = payload
    const response = await Container.get(LeadService).fetchAttendeeLeads(event_uuid)
    this.context.commit('SET_ATTENDEE_LEADS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getLeaderboard (payload: any) {
    const { event_uuid, file_type, download } = payload
    const response = await Container.get(LeadService).getLeaderboard(event_uuid, file_type)
    if (!download) {
      this.context.commit('SET_LEADERBOARD', response.data)
    }
    return response
  }

  @Action({ rawError: true })
  async fetchLeadsStats (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(LeadService).fetchLeadsStats(event_uuid)
    this.context.commit('SET_LEADS_STATS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchExhibitorStats (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(LeadService).fetchExhibitorStats(event_uuid)
    this.context.commit('SET_EXHIBITOR_STATS', response.data)
    return response
  }

  @Action({ rawError: true })
  async addBulkLeadsActivationCode (payload: any) {
    const { event_uuid, participant_uuids, data } = payload
    return await Container.get(LeadService).addBulkLeadsActivationCode(event_uuid, participant_uuids, data)
  }

  @Action({ rawError: true })
  async addLead (payload: { event_uuid: string; participant_uuid: string; data: any }) {
    const { event_uuid, participant_uuid, data } = payload
    return await Container.get(LeadService).addLead(event_uuid, participant_uuid, data)
  }

  @Action({ rawError: true })
  async resolveLead (payload: any) {
    const { event_uuid, participant_uuid, unknown_uuid } = payload
    return await Container.get(LeadService).resolveLead(event_uuid, unknown_uuid, { participant_uuid })
  }

  @Action({ rawError: true })
  async getLead (payload: any) {
    const { event_uuid, lead_uuid } = payload
    return await Container.get(LeadService).getLead(event_uuid, lead_uuid)
  }

  @Action({ rawError: true })
  async getChangelog (payload: any) {
    const { event_uuid, type, subtype } = payload
    const response = await Container.get(LeadService).getChangelog(event_uuid, type, subtype)
    this.context.commit('SET_CHANGELOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async getCurrentlyDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(LeadService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(LeadService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getDesignChangelog (payload: any) {
    const { event_uuid, type, subtype } = payload
    const response = await Container.get(LeadService).getChangelog(event_uuid, type, subtype)
    this.context.commit('SET_DESIGN_CHANGELOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async getCurrentlyDeployedLiveDesignUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(LeadService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEPLOYED_LIVE_DESIGN_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentDevDesignUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(LeadService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_DEV_DESIGN_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getExportAttendeeBoothScans (event_uuid: any) {
    return await Container.get(LeadService).getExportAttendeeBoothScans(event_uuid)
  }

  @Action({ rawError: true })
  async getExportAllLeadsSpreadSheets (event_uuid: any) {
    return await Container.get(LeadService).getExportAllLeadsSpreadSheets(event_uuid)
  }

  @Action({ rawError: true })
  async exportActivationCodesByExhibitor (event_uuid: any) {
    return await Container.get(LeadService).exportActivationCodesByExhibitor(event_uuid)
  }

  @Action({ rawError: true })
  async getExportAllLeadsCounts (event_uuid: any) {
    return await Container.get(LeadService).getExportAllLeadsCounts(event_uuid)
  }

  @Action({ rawError: true })
  async getExportLeadsForExhibitor (payload) {
    return await Container.get(LeadService).getExportLeadsForExhibitor(payload.event_uuid, payload.participant_uuid)
  }

  @Action({ rawError: true })
  async getExportLeadsAsExcelFile (event_uuid: any) {
    return await Container.get(LeadService).getExportLeadsAsExcelFile(event_uuid)
  }

  @Action({ rawError: true })
  async getExportAllLeadsCountsByAttendee (event_uuid: any) {
    return await Container.get(LeadService).getExportAllLeadsCountsByAttendee(event_uuid)
  }

  @Action({ rawError: true })
  async fetchScansByParticipant (payload: any) {
    const response = await Container.get(LeadService).getScansByParticipant(payload.event_uuid, payload.participant_uuid)
    this.context.commit('SET_SCANS_BY_PARTICIPANT', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getExportScansByParticipant (payload: any) {
    return await Container.get(LeadService).getExportScansByParticipant(payload.event_uuid, payload.participant_uuid)
  }

  @Action({ rawError: true })
  async getQualifiersByParticipant (payload: any) {
    return await Container.get(LeadService).getQualifiersByParticipant(payload.event_uuid, payload.participant_uuid)
  }

  @Action({ rawError: true })
  async fetchLeadsAttendee (payload: any) {
    const response = await Container.get(LeadService).getLeadsAttendee(payload.event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async fetchLeadScans (payload: any) {
    const response = await Container.get(LeadService).getLeadScans(payload.event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async fetchSettings (event_uuid: string) {
    const response = await Container.get(LeadService).fetchSettings(event_uuid)
    this.context.commit('SET_ORDERS_SETTINGS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchDesignSettings (event_uuid: string) {
    const response = await Container.get(LeadService).fetchDesignSettings(event_uuid)
    this.context.commit('SET_ORDERS_DESIGN_SETTINGS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchSettingsByUuid (payload: any) {
    const response: any = await Container.get(LeadService).getSettingsByUuid(payload.event_uuid, payload.settings_uuid)
    const settings = response.data.page_data
    this.context.commit('SET_ORDERS_SETTINGS', settings)
    return response
  }

  @Action({ rawError: true })
  async fetchSettingsMetadata () {
    const response = await Container.get(LeadService).fetchSettingsMetadata()
    this.context.commit('SET_ORDERS_SETTINGS_METADATA', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async updateSettings (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(LeadService).updateSettings(event_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async updateDesignSettings (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(LeadService).updateDesignSettings(event_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchCheckoutDevices (payload: any) {
    const response = await Container.get(LeadService).fetchCheckoutDevices(payload.event_uuid, payload.device_status)
    return response.data.data
  }

  @Action({ rawError: true })
  async fetchCheckinCheckoutSettings (payload: any) {
    const response = await Container.get(LeadService).fetchCheckinCheckoutSettings(payload.event_uuid)
    this.context.commit('SET_CHECKIN_CHECKOUT_SETTINGS', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchCheckinCheckoutData (payload: any) {
    const response = await Container.get(LeadService).fetchCheckinCheckoutData(payload.event_uuid)
    this.context.commit('SET_CHECKIN_CHECKOUT_DATA', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async updateCheckinCheckoutSettings (payload: any) {
    const response = await Container.get(LeadService).updateCheckinCheckoutSettings(payload.event_uuid, payload.data)
    return response
  }

  @Action({ rawError: true })
  async updateDeviceStatus (payload: any) {
    const response = await Container.get(LeadService).updateDeviceStatus(payload.event_uuid, payload.participant_uuid, payload.checkout_uuid, payload.data)
    return response.data.data
  }

  @Action({ rawError: true })
  async fetchAllParticipants (payload: any) {
    const response = await Container.get(LeadService).fetchAllParticipants(payload.event_uuid)
    return response.data.data
  }

  @Action({ rawError: true })
  async deviceCheckout (payload: any) {
    const response = await Container.get(LeadService).deviceCheckout(payload.event_uuid, payload.participant_uuid, payload.data)
    return response.data.data
  }

  @Action({ rawError: true })
  async getExportDevicesByEvent (event_uuid: any) {
    return await Container.get(LeadService).getExportDevicesByEvent(event_uuid)
  }

  @Action({ rawError: true })
  async getExportAll (event_uuid: any) {
    return await Container.get(LeadService).getExportAll(event_uuid)
  }

  @Action({ rawError: true })
  async getExportDevice (payload: any) {
    const { event_uuid, device } = payload
    return await Container.get(LeadService).getExportDevice(event_uuid, device)
  }

  @Action({ rawError: true })
  async sendSMSCheckingReminder (payload: any) {
    const response = await Container.get(LeadService).sendSMSCheckingReminder(payload.event_uuid, payload.message)
    return response.data.data
  }
}
