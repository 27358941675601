import Container, { Service } from 'typedi'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'

@Service()
export default class SpeakerService {
  public async addSpeaker (event_uuid: string, session_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/sessions/${session_uuid}/speaker`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async deleteSpeaker (event_uuid: string, session_uuid: string, speaker_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/sessions/${session_uuid}/speaker/${speaker_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
