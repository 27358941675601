import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrEmptyState'
})
export default class GtrEmptyState extends Vue {
  @Prop({ required: false, type: String, default: 'empty-state.svg' })
  icon!: string

  @Prop({ required: false, type: String, default: 'Nothing to show.' })
  message!: string

  @Prop({ required: false, type: String, default: '100' })
  iconWidth!: string

  get iconSrc () {
    return require(`@/assets/img/empty-state/${this.icon}`)
  }
}
