import { RouteConfig } from 'vue-router'
import IBaseModule from './contracts/base-module.interface'
import commonRoutes from './routes/common.router'
import { Service } from 'typedi'
import common from './store/common.store'
import security from './store/security.store'
import company from './store/company.store'
import module from './store/module.store'
import scans from './store/scans.store'

@Service()
export default class CommonModule implements IBaseModule {
  store (): object {
    return {
      common,
      security,
      company,
      module,
      scans
    }
  }

  routes (): RouteConfig[] {
    return [
      ...commonRoutes
    ]
  }
}
