import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'

@Service()
export default class ContentPagesService {
  /**
   * retrieves the changelog for the content page.
   * @param event_uuid {string} the event uuid.
   * @param type {string} the type of content (i.e. page)
   * @param sub_type {string} the name of the page the changelog belongs to.
   * @returns
   */
  public async getChangelog (event_uuid: any, type: any, sub_type: any) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type !== null ? sub_type : ''}/all?no_data=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  /**
   * similar functionality to getUUID, although pulls from a different route.
   * @param event_uuid {string} the event uuid
   * @param revision_uuid {string} the revision of the content to grab.
   */
  public async getContentpageByRevisionUuid (event_uuid: string, revision_uuid: string): Promise<Record<string, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/uuid/${revision_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<Record<string, any>>(httpParams)
    return response?.data
  }

  /**
   * gets the dev and live version of the content page.
   * @param {string} event_uuid - the event uuid.
   * @param {string} type  - the type of content.
   * @param {string} sub_type - the name of the specific page.
   * @returns {Object} the response data.
   */
  public async getUUID (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response?.data
  }

  /**
   * gets all content pages for the event.
   * @param {string} event_uuid - the event uuid
   * @returns AxiosResponse.data
   */
  public async getContentPages (event_uuid: string): Promise<Record<string, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/content/page`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<Record<string, any>>(httpParams)
    return response?.data
  }

  /**
   * create a new content page.
   * @param {Object} data - the page data.
   * @param {string} event_uuid - the event uuid
   * @returns {Promise<any>} - add content response.
   */
  public async addContentPage (data: any, event_uuid: string): Promise<AxiosResponse> {
    const name = data.name.replace(/ /g, '_')
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/content/page/${name}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  /**
   * update a given content page.
   * @param {Object} data - the update page
   * @param {string} event_uuid - event uuid
   * @param {string} name - content name
   * @returns {AxiosResponse}
   */
  public async updateContentPage (data: any, event_uuid: string, name: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/content/page/${name}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async deleteContentPage (event_uuid: string, name: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/content/page/${name}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
