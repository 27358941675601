import { render, staticRenderFns } from "./gtr-module-menu-item.vue?vue&type=template&id=aa71ff40&scoped=true&"
import script from "./gtr-module-menu-item.ts?vue&type=script&lang=ts&"
export * from "./gtr-module-menu-item.ts?vue&type=script&lang=ts&"
import style0 from "./gtr-module-menu-item.vue?vue&type=style&index=0&id=aa71ff40&prod&scoped=true&lang=css&"
import style1 from "./gtr-module-menu-item.vue?vue&type=style&index=1&id=aa71ff40&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa71ff40",
  null
  
)

export default component.exports