import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'

@Service()
export default class OptionService {
  public async getOptionGroup (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-groups`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getOptionGroupNames (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-groups/names`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getOptionGroupSingle (event_uuid: string, option_group_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getOptionGroupByName (event_uuid: string, option_group_name: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-group/name/${option_group_name}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async exportOptionGroup (event_uuid: string, option_group_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportOption (event_uuid: string, option_group_uuid: string, option_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/option/${option_uuid}/participants/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async createOption (event_uuid: string, option_group_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/option`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async duplicateOptionGroup (event_uuid: string, option_group_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/duplicate`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async editOption (event_uuid: string, option_group_uuid: string, option_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/option/${option_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteOption (event_uuid: string, option_group_uuid: string, option_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}/option/${option_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async createOptionGroup (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/option-group`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async saveOptionGroupSettings (event_uuid: string, option_group_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/option-group/${option_group_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteOptionGroup (event_uuid: string, option_group_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/option-group/${option_group_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
