import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container from 'typedi'

export default class SyncsService {
  public async getSyncs () {
    const httpParams: IHttpClientGetParameters = {
      url: '/sync-types',
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getAllSyncs () {
    const httpParams: IHttpClientGetParameters = {
      url: '/all-syncs',
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getActiveSyncs (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/syncs`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSyncHistory (event_uuid: string, sync_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sync/${sync_uuid}/runs`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async startSync (event_uuid: string, sync_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sync/${sync_uuid}/start`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async startExport (event_uuid: string, sync_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sync/${sync_uuid}/data?file_type=csv&binary_response=true&export=1`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', 'export.csv')
    document.body.appendChild(link)
    link.click()
    link.remove()
    return response
  }

  public async createSync (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/sync`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getSampleSyncData (event_uuid: string, sync_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/sync/${sync_uuid}/data?file_type=json&limit=3`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateSync (event_uuid: string, sync_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/sync/${sync_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }
}
