import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'gtr-card'
})
export default class GtrCard extends Vue {
  @Prop({ required: false, type: String })
  title: string | undefined;

  @Prop({ required: false, type: Number, default: 500 })
  width: number | undefined;
}
