import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ScansService from '../services/scans/scans.service'

@Module({
  namespaced: true
})
export default class ScansStore extends VuexModule {
  scan_data: any [] = []
  event_scan_data: any [] = []
  original_scan_data: any [] = []
  scan: any [] = []

  @Mutation
  SET_SCAN_DATA (payload: any) {
    this.scan_data = payload
  }

  @Mutation
  SET_ORIGINAL_SCAN_DATA (payload: any) {
    this.original_scan_data = payload
  }

  @Mutation
  SET_EVENT_SCAN_DATA (payload: any) {
    this.event_scan_data = payload
  }

  @Mutation
  SET_SCAN (payload: any) {
    this.scan = payload
  }

  @Action({ rawError: true })
  async fetchScansByFileNameAndDeviceIdForEvent (payload: any) {
    const { file_name, deviceid, event_uuid } = payload
    const response: any = await Container.get(ScansService).getScansByFileNameAndDeviceIdForEvent(file_name, deviceid, event_uuid)
    this.context.commit('SET_SCAN_DATA', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchOriginalScansForEvent (payload: any) {
    const { file_name, deviceid, event_uuid } = payload
    const response: any = await Container.get(ScansService).getOriginalScansForEvent(file_name, deviceid, event_uuid)
    this.context.commit('SET_ORIGINAL_SCAN_DATA', response.data)
    return response
  }

  @Action({ rawError: true })
  async processScansByFileNameAndDeviceIdForEvent (payload: any) {
    const { file_name, deviceid, event_uuid } = payload
    const response: any = await Container.get(ScansService).processScansByFileNameAndDeviceIdForEvent(file_name, deviceid, event_uuid)
    return response
  }

  @Action({ rawError: true })
  async fetchScansByFileNameAndDeviceId (payload: any) {
    const { file_name, deviceid } = payload
    const response: any = await Container.get(ScansService).getScansByFileNameAndDeviceId(file_name, deviceid)
    this.context.commit('SET_SCAN_DATA', response.data)
    return response
  }

  @Action({ rawError: true })
  async changeEvent (payload: any) {
    const { file_name, deviceid, event_uuid } = payload
    const response: any = await Container.get(ScansService).changeEvent(file_name, deviceid, event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getAllEventScanData (payload: any) {
    const { event_uuid } = payload
    const response: any = await Container.get(ScansService).getAllEventScanData(event_uuid)
    this.context.commit('SET_EVENT_SCAN_DATA', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchScanByUUID (payload: any) {
    const { file_name, deviceid, event_uuid, row_uuid } = payload
    const response: any = await Container.get(ScansService).getScanByUUID(file_name, deviceid, event_uuid, row_uuid)
    this.context.commit('SET_SCAN', response.data)
    return response
  }

  @Action({ rawError: true })
  async deleteScanByUUID (payload: any) {
    const { file_name, deviceid, event_uuid, row_uuid } = payload
    const response: any = await Container.get(ScansService).deleteScanByUUID(event_uuid, row_uuid)
    this.context.dispatch('fetchScansByFileNameAndDeviceIdForEvent', { file_name, deviceid, event_uuid })
    return response
  }

  @Action({ rawError: true })
  async saveScanByUUID (payload: any) {
    const { file_name, deviceid, event_uuid, row_uuid, barcode_num, line } = payload
    const response: any = await Container.get(ScansService).saveScanByUUID(file_name, deviceid, event_uuid, row_uuid, barcode_num, line)
    this.context.dispatch('fetchScanByUUID', { file_name, deviceid, event_uuid, row_uuid })
    return response
  }

  @Action({ rawError: true })
  async addScan (payload: any) {
    const { file_name, deviceid, event_uuid, line, barcode_num, date_time } = payload
    const response: any = await Container.get(ScansService).saveScan(file_name, deviceid, event_uuid, line, barcode_num, date_time)
    this.context.dispatch('fetchScansByFileNameAndDeviceIdForEvent', { file_name, deviceid, event_uuid })
    return response
  }
}
