import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrGlossary'
})

export default class GtrGlossary extends Vue {
    @Prop({ required: true, type: Array, default: '' })
    glossaryItems: Array<object> | undefined

    @Prop()
    tooltip: any

    data () {
      return {
        tabNames: ['General', 'Advanced'],
        tab: null,
        generalItems: [],
        advancedItems: [],
        search: ''
      }
    }

    @Watch('glossaryItems', { immediate: true })
    onGlossaryItemsChange (payload: any) {
      const excludedFromGeneral = ['language', 'profile_photo', 'a2z_id', 'can_export_certificates', 'exported_certificate']
      const excludedFromAdvanced = ['payment_vault_id', 'sync_id', 'update_from_sync', 'sync_updated', 'sync_updated_id', 'jot_id', 'jot_modified_date', 'jot_push_date', 'event_a2z_data', 'event_database', 'event_created_by', 'event_deleted_at', 'event_deletion_reason', 'event_last_jot_sync', 'event_migrations_md5']
      if (payload.length) {
        for (let i = 0; i < payload.length; i++) {
          if (payload[i].advanced === 1) {
            const uuid = (payload[i].field_details && payload[i].field_details.hasOwnProperty('option_group_uuid')) ? payload[i].field_details.option_group_uuid : payload[i].field
            if (this.$data.advancedItems.every(item => item.uuid !== uuid) && excludedFromAdvanced.every(item => item !== uuid)) {
              this.$data.advancedItems.push({
                label: payload[i].label,
                uuid
              })
            }
            this.$data.advancedItems.sort((a, b) => a.label >= b.label ? 1 : -1)
          } else {
            const uuid = (payload[i].field_details && payload[i].field_details.hasOwnProperty('option_group_uuid')) ? payload[i].field_details.option_group_uuid : payload[i].field
            if (this.$data.generalItems.every(item => item.uuid !== uuid) && excludedFromGeneral.every(item => item !== uuid)) {
              this.$data.generalItems.push({
                label: payload[i].label,
                uuid
              })
            }
            this.$data.generalItems.sort((a, b) => a.label >= b.label ? 1 : -1)
          }
        }
        this.$data.advancedItems.push({
          label: 'Send a Receipt',
          uuid: 'receipt_button'
        })
      }
    }

    onCopy () {
      Container.get(Notification).success('Successfully copied uuid.')
    }

    onError () {
      Container.get(Notification).error('There was an error copying the uuid. Please refresh and try again.')
    }

    get filterGeneralFieldsWithSearch () {
      return this.filterFields(this.$data.generalItems)
    }

    get filterAdvancedFieldsWithSearch () {
      return this.filterFields(this.$data.advancedItems)
    }

    private filterFields (fields: any) {
      const returnFields: any = []
      for (const fieldIndex in fields) {
        const field = fields[fieldIndex]
        if (this.$data.search === '' || field.label.toLowerCase().includes(this.$data.search.toLowerCase())) {
          returnFields.push(field)
        }
      }
      return returnFields
    }
}
