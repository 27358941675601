import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'

@Service()
export default class BadgesService {
  public async fetchBadges (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/badges`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getBadgeStats (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/badges-stats`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getBadgesPrintByHour (event_uuid: string, date: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/badges-print-by-hour?date=${date}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getBadge (event_uuid: string, badge_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/badges/${badge_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createBadge (event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/badges`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateBadge (event_uuid: string, badge_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/badges/${badge_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteBadge (event_uuid: string, badge_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/badges/${badge_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getSettings (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/kiosk-settings`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateSettings (event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/kiosk-settings`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createAveryBadgesExport (event_uuid: string, data: any): Promise<AxiosResponse> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/badge-export`,
      requireToken: true,
      payload: data
    }
    const response: AxiosResponse = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getAveryBadgesExport (event_uuid: string, badge_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/badge-export/${badge_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
