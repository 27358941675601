import Vue from 'vue'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'

import moment from 'moment'
import 'moment-timezone'

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
})

Vue.filter('formatDate', function (value: string, format: string) {
  if (value) {
    return moment.utc(value).format(format !== '' ? format : 'MM/DD/YYYY hh:mm:ss A')
  }
})

Vue.filter('formatDateLocal', function (value: string, format: string) {
  if (value) {
    return moment(value).format(format !== '' ? format : 'MM/DD/YYYY hh:mm:ss A')
  }
})

Vue.filter('formatDateAndTime', function (value: string, timezone: string, format: string) {
  if (value) {
    const date = moment(value)
    const formatToUse = format !== '' ? format : 'MM/DD/YYYY hh:mm:ss A'
    if (timezone) {
      return date.tz(timezone).format(formatToUse)
    } else {
      return date.format(formatToUse)
    }
  }
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> Aug 22, 2022
*/
Vue.filter('formatDateShortNumeric', function (value: string) {
  if (value) {
    return new Date(value).toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
  }
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> 02-09-2023, 10:29:25 PM
*/
Vue.filter('formatReportDateTime', function (value: string) {
  if (value) {
    return new Date(value).toLocaleTimeString('en-us', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).replaceAll('/', '-')
  }
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> 02-09-2023, 02:29:25 PM EST
 */
Vue.filter('formatReportDateTimeLocal12Hr', function (value: string, timezone: string) {
  if (value) {
    let dateTimeStr: string
    value.toString().indexOf('Z') === -1 ? dateTimeStr = value + 'Z' : dateTimeStr = value
    return new Date(dateTimeStr).toLocaleTimeString('en-us', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timezone,
      timeZoneName: 'short'
    }).replaceAll('/', '-')
  }
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> 02-09-2023, 14:29:25 PM EST
*/
Vue.filter('formatReportDateTimeLocal24Hr', function (value: string, timezone: string) {
  if (value) {
    let dateTimeStr: string
    !value.includes('Z') ? dateTimeStr = value + 'Z' : dateTimeStr = value
    return new Date(dateTimeStr).toLocaleTimeString('en-us', {
      hour12: false,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timezone,
      timeZoneName: 'short'
    }).replaceAll('/', '-')
  }
})

/**
 * @param {string} value - date to be formatted
 * returns formatted date - Example -> 02-09-2023, 10:29:25 PM EST
*/
Vue.filter('formatBadgePrintDateTime', function (value: string, timezone: string) {
  if (value) {
    let dateTimeStr: string
    !value.includes('Z') ? dateTimeStr = value + 'Z' : dateTimeStr = value
    return new Date(dateTimeStr).toLocaleTimeString('en-us', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timezone,
      timeZoneName: 'short'
    }).replaceAll('/', '-')
  }
})

Vue.filter('formatCanceledDate', function (value: string, timezone: string) {
  if (value) {
    let dateTimeStr: string
    !value.includes('Z') ? dateTimeStr = value + 'Z' : dateTimeStr = value
    const date = new Date(dateTimeStr).toLocaleTimeString('en-us', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timezone,
      timeZoneName: 'short'
    }).replaceAll('/', '-')
    return moment(date).format('YYYY-MM-DD HH:mm')
  }
})

Vue.filter('simpleFormatDate', function (value: string) {
  if (value) {
    const dateComponents = value.split('-')
    const formattedDate = `${dateComponents[1]}-${dateComponents[2]}-${dateComponents[0]}`
    return formattedDate
  }
})

Vue.filter('numberWithCommas', function (value: any) {
  if (value || value === 0) {
    const parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }
})

Vue.filter('formatNumbers', function (value: any) {
  if (value) {
    const val = (value / 1).toFixed(2).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
})

Vue.filter('toCurrency', function (value, currency = 'usd') {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(value) + (currency === 'cad' ? 'CAD' : '')
})
Vue.filter('capitalizeAndRemoveUnderscores', function (value) {
  if (typeof value !== 'string') {
    return value
  }
  const arrayOfWords = value.split('_')
  if (!arrayOfWords.length) {
    return value
  }
  return arrayOfWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
})
