import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'

@Service()
export default class PromoCodeService {
  public async getPromoCodes (event_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/promo-codes`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getPromoCode (event_uuid: string, promo_code_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/promo-code/${promo_code_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async addPromoCode (data: any, event_uuid: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/promo-code`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async exportAllPromoCodes (event_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/promo-codes/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportSinglePromoCode (event_uuid: string, promo_code_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/promo-code/${promo_code_uuid}/participants/export`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async generateSinglePromoCode (event_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/promo-code/random-code`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async updatePromoCode (data: any, event_uuid: string, promocode_uuid: string): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/promo-code/${promocode_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deletePromoCode (event_uuid: string, promocode_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/promo-code/${promocode_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
