import Container, { Service } from 'typedi'
import GtrStorage from './storage.service'

@Service()
export default class SecurityContext {
  public context () {
    return Container.get(GtrStorage).getItem('security_context')
  }

  public accessLevel () {
    const context = Container.get(GtrStorage).getItem('security_context')
    if (context) {
      return JSON.parse(context).access_level || []
    }
    return []
  }

  public token () {
    const context = Container.get(GtrStorage).getItem('security_context')
    if (context) {
      return JSON.parse(context).access_token || null
    }
    return null
  }

  public permissions () {
    const context = Container.get(GtrStorage).getItem('security_context')
    if (context) {
      return JSON.parse(context).user.user_group.permissions || null
    }
    return null
  }
}
