import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import BadgesService from '../services/badges.service'
import OptionService from '../services/option.service'
import { RegistrationType } from '@/modules/level-two/models/badges.model'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Module({
  namespaced: true
})
export default class BadgesStore extends VuexModule {
  badges: any[] = []

  registrationTypes: RegistrationType[] = []

  settings: any[] = []

  averyBadgesExport: any = {}

  badgeStats: any = {}

  badgeStatsByHour: any = {}

  participantBadge: any = {}

  @Mutation
  SET_BADGES (payload: any) {
    this.badges = payload
  }

  @Mutation
  SET_REGISTRATION_TYPES (payload: any) {
    this.registrationTypes = payload
  }

  @Mutation
  SET_SETTINGS (payload: any) {
    this.settings = payload
  }

  @Mutation
  SET_AVERY_BADGES_EXPORT_RESPONSE (payload: any) {
    this.averyBadgesExport = payload
  }

  @Mutation
  SET_BADGE_STATS (payload: any) {
    this.badgeStats = payload
  }

  @Mutation
  SET_BADGE_STATS_BY_HOUR (payload: any) {
    this.badgeStatsByHour = payload
  }

  @Mutation
  SET_PARTICIPANT_BADGE (payload: any) {
    this.participantBadge = payload
  }

  @Action({ rawError: true })
  async fetchBadges (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(BadgesService).fetchBadges(event_uuid)
    this.context.commit('SET_BADGES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getBadge (payload: any) {
    const { event_uuid, badge_uuid } = payload
    const response = await Container.get(BadgesService).getBadge(event_uuid, badge_uuid)
    return response
  }

  @Action({ rawError: true })
  async createBadge (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(BadgesService).createBadge(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async updateBadge (payload: any) {
    const { event_uuid, badge_uuid, data } = payload
    const response = await Container.get(BadgesService).updateBadge(event_uuid, badge_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async deleteBadge (payload: any) {
    const { event_uuid, badge_uuid } = payload
    const response = await Container.get(BadgesService).deleteBadge(event_uuid, badge_uuid)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationTypes (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(OptionService).getOptionGroupByName(event_uuid, 'Registration Types')
    response.data.options.push({ uuid: '_default', name: '_default', visible: 1 })
    this.context.commit('SET_REGISTRATION_TYPES', response.data.options)
    return response
  }

  @Action({ rawError: true })
  async getSettings (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(BadgesService).getSettings(event_uuid)
    this.context.commit('SET_SETTINGS', response.data)
    return response
  }

  @Action({ rawError: true })
  async updateSettings (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(BadgesService).updateSettings(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async createAveryBadgesExport (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(BadgesService).createAveryBadgesExport(event_uuid, data)
    const badge_uuid = response.data.uuid
    this.context.dispatch('getAveryBadgesExport', { event_uuid, badge_uuid })
    return response
  }

  @Action({ rawError: true })
  async getAveryBadgesExport (payload: any) {
    const { event_uuid, badge_uuid } = payload
    const response = await Container.get(BadgesService).getAveryBadgesExport(event_uuid, badge_uuid)
    if (response.data.status !== 'FINISHED' && response.data.status !== 'FAILED') {
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.context.dispatch('getAveryBadgesExport', payload)
    } else {
      if (response.data.status === 'FAILED') {
        Container.get(Notification).error('There was an error loading the Badges PDF. Please contact support.')
      } else if (response.data.status === 'FINISHED') {
        window.open(response.data.result_data.url, '_blank')
        this.context.commit('SET_AVERY_BADGES_EXPORT_RESPONSE', response.data)
        return response
      }
    }
  }

  @Action({ rawError: true })
  async getBadgesPrintByHour (payload: any) {
    const { event_uuid, dates = [] } = payload
    const badgeStatsByHour = {}
    for (const date of dates) {
      const result = await Container.get(BadgesService).getBadgesPrintByHour(event_uuid, date)
      badgeStatsByHour[date] = result.data
    }
    this.context.commit('SET_BADGE_STATS_BY_HOUR', badgeStatsByHour)
  }

  @Action({ rawError: true })
  async getBadgeStats (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(BadgesService).getBadgeStats(event_uuid)
    this.context.commit('SET_BADGE_STATS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeeBadgePNG (payload: any): Promise<any> {
    try {
      const { event_uuid, data } = payload
      const response = await Container.get(BadgesService).createAveryBadgesExport(event_uuid, data)
      const badge_uuid = response.data.uuid
      this.context.dispatch('getBadgePNGExport', { event_uuid, badge_uuid })
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Action({ rawError: true })
  async getBadgePNGExport (payload: any) {
    const { event_uuid, badge_uuid } = payload
    const response = await Container.get(BadgesService).getAveryBadgesExport(event_uuid, badge_uuid)
    if (response.data.status !== 'FINISHED' && response.data.status !== 'FAILED') {
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.context.dispatch('getBadgePNGExport', payload)
    } else {
      if (response.data.status === 'FAILED') {
        Container.get(Notification).error('There was an error loading the Badges PNG. Please contact support.')
      } else if (response.data.status === 'FINISHED') {
        this.context.commit('SET_PARTICIPANT_BADGE', response.data)
        return response
      }
    }
  }
}
