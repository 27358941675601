import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PromoCodesService from '../services/promocode.service'

@Module({
  namespaced: true
})
export default class PromoCodesStore extends VuexModule {
    promocodes: any[] = []
    promocode: any = {}

    @Mutation
    SET_PROMO_CODES (payload: any) {
      this.promocodes = payload
    }

    @Mutation
    SET_PROMO_CODE (payload: any) {
      this.promocode = payload
    }

    @Action({ rawError: true })
    async getPromoCodes (payload: any) {
      const { event_uuid } = payload
      const response = await Container.get(PromoCodesService).getPromoCodes(event_uuid)
      this.context.commit('SET_PROMO_CODES', response.data)
      return response.data
    }

    @Action({ rawError: true })
    async getPromoCode (payload: any) {
      const { event_uuid, promo_code_uuid } = payload
      const response = await Container.get(PromoCodesService).getPromoCode(event_uuid, promo_code_uuid)
      this.context.commit('SET_PROMO_CODE', response.data)
      return response.data
    }

    @Action({ rawError: true })
    async exportAllPromoCodes (event_uuid: string) {
      return await Container.get(PromoCodesService).exportAllPromoCodes(event_uuid)
    }

    @Action({ rawError: true })
    async exportSinglePromoCode (payload: any) {
      const { event_uuid, promo_code_uuid } = payload
      return await Container.get(PromoCodesService).exportSinglePromoCode(event_uuid, promo_code_uuid)
    }

    @Action({ rawError: true })
    async generateSinglePromoCode (event_uuid: string) {
      const result = await Container.get(PromoCodesService).generateSinglePromoCode(event_uuid)
      return result?.data?.code ?? ''
    }

    @Action({ rawError: true })
    async createPromoCode (payload: any) {
      const { data, event_uuid } = payload
      const response = await Container.get(PromoCodesService).addPromoCode(data, event_uuid)
      this.context.dispatch('getPromoCodes', payload)
      return response.data
    }

    @Action({ rawError: true })
    async editPromoCode (payload: any) {
      const { data, event_uuid, promocode_uuid } = payload
      const response = await Container.get(PromoCodesService).updatePromoCode(data, event_uuid, promocode_uuid)
      this.context.dispatch('getPromoCodes', payload)
      return response.data
    }

    @Action({ rawError: true })
    async deletePromoCode (payload: any) {
      const { event_uuid, promocode_uuid } = payload
      const response: any = await Container.get(PromoCodesService).deletePromoCode(event_uuid, promocode_uuid)
      this.context.dispatch('getPromoCodes', payload)
      return response
    }
}
