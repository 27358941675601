import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientPutParameters from '@/modules/common/contracts/http-client/http-client-put-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'
import FileUploadService from '@/modules/common/services/file-upload.service'
import type { GtrNewFontPackage, GtrFontPackage } from 'gtr-types'

@Service()
export default class MediaService {
  // START FONT OPERATIONS //

  /**
   * Retrieve all font packages for a company.
   * @param {string} companyUuid - The company uuid
   * @returns {AxiosResponse} - The response from axios
   */
  public async getCompanyFontStore (companyUuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/company/${companyUuid}/fonts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  // TODO: create types.
  // private async createFontUrls (fonts: [File, File]): Promise<any> {

  // }

  // TODO: create a type for font package.
  public async uploadFontToFontStore (companyUuid: string, fontPackage: GtrNewFontPackage) {
    // unpack font files from fontPackage
    const { ttf, woff } = fontPackage

    // upload files to platform to get urls
    if (ttf !== null && woff !== null) {
      const woffUrl = await Container.get(FileUploadService).uploadFile(woff)
      fontPackage.woff = woffUrl
      const ttfUrl = await Container.get(FileUploadService).uploadFile(ttf)
      fontPackage.ttf = ttfUrl
    }

    // build the http paramerters
    const httpParamsEventFont: IHttpClientPostParameters = {
      url: `/company/${companyUuid}/font`,
      requireToken: true,
      payload: fontPackage
    }

    // send payload.
    // TODO: return the result instead of unnessary response variable.
    const response = await Container.get(HttpClient).post(httpParamsEventFont)
    return response
  }

  // public async updateFontInFontStore (
  //   companyUuid: string,
  //   fontPackage: GtrFontPackage,
  //   fontPackageUuid: string
  // ) {
  //   const httpParamsEventFont: IHttpClientPostParameters = {
  //     url: `/company/${companyUuid}/font/${fontPackageUuid}`,
  //     requireToken: true,
  //     payload: fontPackage
  //   }
  //   const response = await Container.get(HttpClient).post(httpParamsEventFont)
  //   return response
  // }

  public async deleteFontStore (fontPackageUuid: string, companyUuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/company/${companyUuid}/font/${fontPackageUuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  // END FONT OPERATIONS //

  public async getMedia (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/media`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async uploadMedia (event_uuid: string, fileData: any, participant_uuid?: string) {
    const vaporData: any = {
      bucket: '',
      content_type: fileData.type,
      expires: '',
      file_name: fileData.name
    }
    let signedResponse: any = {}
    let headers: any = {}

    const httpParamsVapor: IHttpClientPostParameters = {
      url: participant_uuid ? `/event/${event_uuid}/participant/${participant_uuid}/vapor/signed-storage-url` : '/vapor/signed-storage-url',
      requireToken: true,
      payload: vaporData
    }
    const responseVapor: any = await Container.get(HttpClient).post(httpParamsVapor)
    signedResponse = responseVapor.data
    signedResponse.file_name = fileData.name
    headers = responseVapor.data.headers
    delete headers.Host

    const httpParamsFile: IHttpClientPutParameters = {
      url: signedResponse.url,
      requireToken: false,
      payload: fileData,
      config: {
        headers
      }
    }
    const responseFile = await Container.get(HttpClient).put(httpParamsFile)

    const httpParamsEventMedia: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/media`,
      requireToken: true,
      payload: {
        media: signedResponse,
        responseVapor
      }
    }
    const responseEventMedia = await Container.get(HttpClient).post(httpParamsEventMedia)
    return {
      signedResponse,
      responseVapor,
      responseFile,
      responseEventMedia
    }
  }

  public async deleteMedia (event_uuid: string, file_identifier: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/media`,
      requireToken: true,
      config: {
        data: {
          filename: file_identifier
        }
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }
}
